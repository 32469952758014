import { Eye, EyeSlash } from "@phosphor-icons/react";
import { TextField, Button } from "@radix-ui/themes";
import { useState } from "react";

interface PasswordInputProps
  extends React.ComponentProps<typeof TextField.Root> {
  label?: string;
  error?: boolean;
}

export function PasswordInput({ label, error, ...props }: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField.Root
      {...props}
      type={showPassword ? "text" : "password"}
      color={error ? "red" : undefined}
    >
      <TextField.Slot side="right">
        <Button
          type="button"
          variant="ghost"
          className="cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <EyeSlash className="text-gray-500 size-4" weight="bold" />
          ) : (
            <Eye className="text-gray-500 size-4" weight="bold" />
          )}
        </Button>
      </TextField.Slot>
    </TextField.Root>
  );
}
