import {
  TicketCommentDisposition,
  TicketCommentBoard,
} from "@mg/schemas/src/commons/ticket";
import { File, Files } from "@phosphor-icons/react";
import { Flex, Select, Text } from "@radix-ui/themes";
import cx from "classnames";
import { useState, useEffect, useMemo } from "react";

import { useAnalytics } from "../../../../../utils/analytics";
import {
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from "../../../../../utils/constants";
import { isNil } from "../../../../../utils/fp";
import { useAppSelector } from "../../../../../utils/hooks";
import { ticketRoute } from "../route";

export default function VersionSelect() {
  // Router methods
  const navigate = ticketRoute.useNavigate();

  // Slices
  const versions = useAppSelector((state) => state.ticket.revisions);
  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  const { length } = versions;

  // Count comments by boardId
  const { comments } = useAppSelector((state) => state.ticket);
  const activeComments = comments.filter((c) => {
    return (
      c.boardType === TicketCommentBoard.REVISION &&
      c.disposition !== TicketCommentDisposition.DISMISSED &&
      c.disposition !== TicketCommentDisposition.RESOLVED &&
      !c.isPending
    );
  });
  const commentsByBoardId = activeComments.reduce((acc, c) => {
    acc[c.boardId!] = (acc[c.boardId!] || 0) + 1;
    return acc;
  }, Object.create(null));

  // Analytics
  const analytics = useAnalytics("Revision"); // Keeping the old name from before the rewrite

  // Local state
  const [isChangingVersions, setIsChangingVersions] = useState(false);
  const [changingVersion, setChangingVersion] = useState(reversedVersionIndex);

  const versionThumbnails = useMemo(
    () =>
      versions.reduce((acc, v) => {
        const thumbExt = v.thumbnails[0]?.split(".").pop()?.toLowerCase() ?? "";
        if (IMAGE_EXTENSIONS.includes(thumbExt)) acc[v._id] = v.thumbnails[0];
        return acc;
      }, Object.create(null)),
    [versions],
  );
  const hasVersionThumbnails = useMemo(
    () => Object.keys(versionThumbnails).length > 0,
    [versionThumbnails],
  );

  useEffect(() => {
    if (isNil(changingVersion)) return;

    // Since `navigate` resolves its promise when navigation has completed (not
    // when `loader` has resolved), we can't guarantee the slice has updated its
    // selected index in time. Remember, we rely on `loader` to update the
    // selected index. We can use the local value to ensure a desync to disable
    // the dropdown until the states sync back up again
    if (isChangingVersions) {
      const computedReversedIndex = versions.length - 1 - changingVersion;

      if (computedReversedIndex === reversedVersionIndex) {
        setIsChangingVersions(false);
      }
    }
  }, [reversedVersionIndex, isChangingVersions, versions, changingVersion]);

  if (!length || isNil(reversedVersionIndex)) return;

  /////
  // No hooks beyond this point
  /////

  function handleVersionChange(value: string) {
    setIsChangingVersions(true);

    const valueAsNum = +value;
    const newReversedIndex = versions.length - 1 - valueAsNum;

    // Since `navigate` resolves its promise when navigation has completed (not
    // when `loader` has resolved), we can't guarantee the slice has updated its
    // selected index in time. Remember, we rely on `loader` to provide the
    // selected index. We can set this value to local state to ensure a desync
    // to disable the dropdown until the states sync back up again (see
    // useEffect above)
    setChangingVersion(newReversedIndex);
    analytics.setContext({
      revisionBoard: newReversedIndex,
    });

    navigate({
      search() {
        return {
          version: newReversedIndex,
        };
      },
      replace: true,
    });
  }

  return (
    <Flex gap="2" align="center" data-testid="version-select-container">
      <Select.Root
        size="1"
        value={reversedVersionIndex.toString()}
        onValueChange={handleVersionChange}
        disabled={isChangingVersions}
        data-testid="version-select"
      >
        <Select.Trigger
          color="gray"
          className="version-select-trigger"
          data-testid="version-select-trigger"
        />
        <Select.Content
          position="popper"
          data-testid="version-select-content"
          style={{
            ["--select-item-height" as string]: "2.5rem",
          }}
        >
          {versions.map((v, i) => (
            <Select.Item
              key={v._id}
              value={i.toString()}
              data-testid={`version-select-item_${v._id}`}
            >
              <Flex gap="2" className="cursor-pointer" align="center">
                {hasVersionThumbnails && (
                  <div className="size-9 p-1 [.version-select-trigger_&]:hidden">
                    {versionThumbnails[v._id] ? (
                      <img
                        src={versionThumbnails[v._id]}
                        alt="Version thumbnail"
                        className="block size-7 border border-puntt-neutral-gray-4 object-cover"
                      />
                    ) : (
                      <Flex
                        align="center"
                        justify="center"
                        className="size-7 bg-puntt-neutral-gray-4"
                      >
                        {v.reviewFiles && v.reviewFiles?.length > 1 ? (
                          <Files
                            className="text-puntt-neutral-gray-9"
                            size={21}
                          />
                        ) : (
                          <File
                            className="text-puntt-neutral-gray-9"
                            size={21}
                          />
                        )}
                      </Flex>
                    )}
                  </div>
                )}
                <Flex direction="column">
                  <span className="text-sm font-bold [.version-select-trigger_&]:text-xs [.version-select-trigger_&]:font-normal">
                    V{length - i}
                  </span>
                  <span
                    className={cx(
                      "text-puntt-neutral-gray-11 [.version-select-trigger_&]:hidden [[data-highlighted]_&]:text-puntt-neutral-gray-6",
                      {
                        hidden: !v.reviewFiles,
                      },
                    )}
                  >
                    {v.reviewFiles?.length.toLocaleString()}{" "}
                    {getReviewFileType(v.reviewFiles ?? [])}
                    {v.reviewFiles?.length === 1 ? "" : "s"},{" "}
                    {commentsByBoardId[v._id]?.toLocaleString() ?? 0} comment
                    {commentsByBoardId[v._id] === 1 ? "" : "s"}
                  </span>
                </Flex>
              </Flex>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Text size="2" data-testid="version-count">
        of {length}
      </Text>
    </Flex>
  );
}

/**
 * Returns a friendly description of a version's file type(s).
 *
 * - If every reviewFile is an image, return "image"
 * - If every reviewFile is a video, return "video"
 * - Otherwise if every reviewFile has the same extension, return that extension
 * - Otherwise return "file"
 */
function getReviewFileType(reviewFiles: { source: string }[]) {
  let allImages = true;
  let allVideos = true;
  const extsSoFar = new Set<string>();
  for (const { source } of reviewFiles) {
    const ext = source.split(".").pop()?.toLowerCase() ?? "";
    if (allImages && !IMAGE_EXTENSIONS.includes(ext)) {
      if (extsSoFar.size > 0) return "file";
      allImages = false;
    }
    if (allVideos && !VIDEO_EXTENSIONS.includes(ext)) {
      if (extsSoFar.size > 0) return "file";
      allVideos = false;
    }
    extsSoFar.add(ext);
  }

  if (allImages) return "image";
  if (allVideos) return "video";
  if (extsSoFar.size === 1) return Array.from(extsSoFar)[0].toUpperCase();
  return "file";
}
