import { CaretRight, DotsThree } from "@phosphor-icons/react";
import { DropdownMenu, Flex, IconButton, Link } from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { Link as NavLink } from "@tanstack/react-router";
import { Fragment, type ReactNode } from "react";

import { TicketActionsDropdown } from "./TicketActionsDropdown";

import { isPunttGuest } from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { ticketsRoute } from "../route";

type BreadcrumbsProps = {
  onDeleteFolder(): void;
  onRenameFolder(): void;
  onShareFolder(): void;
  onDownloadFolder(): void;
  onManageVersions?(): void;
  ticketTitle?: ReactNode;
};

export function Breadcrumbs(props: BreadcrumbsProps) {
  const {
    onRenameFolder,
    onShareFolder,
    onDeleteFolder,
    onDownloadFolder,
    onManageVersions,
    ticketTitle,
  } = props;
  const breadcrumbs = useAppSelector(
    (state) => state.punttProjects.breadcrumbs,
  );

  if (!breadcrumbs?.length) {
    if (!ticketTitle) {
      return null;
    }
    return (
      <>
        {ticketTitle}
        {renderDropdownActions()}
      </>
    );
  }

  function renderDropdownActions() {
    if (isPunttGuest()) {
      return null;
    }

    return (
      <TicketActionsDropdown
        onShare={onShareFolder}
        onDeleteTicket={onDeleteFolder}
        onRename={onRenameFolder}
        onDownload={onDownloadFolder}
        onManageVersions={onManageVersions}
      />
    );
  }

  // if we have several breadcrumbs, we just want to show the top-level folder,
  // the folder you're currently at, and a "..." menu in between as a popover.
  if (breadcrumbs.length > 2) {
    const init = breadcrumbs.slice(0, -1);
    const last = breadcrumbs[breadcrumbs.length - 1];

    return (
      <>
        <CaretRight className="shrink-0" />

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton variant="ghost" color="gray">
              <DotsThree />
            </IconButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content>
            {init.map((el) => (
              <DropdownMenu.Item key={el._id}>
                <NavLink
                  to={ticketsRoute.to}
                  search={(prev) => ({ ...prev, folderId: el._id })}
                  style={{
                    lineHeight: "var(--base-menu-item-height)",
                    width: "100%",
                  }}
                >
                  {el.name}
                </NavLink>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <CaretRight className="shrink-0" />

        <Link asChild color="gray">
          <NavLink
            to={ticketsRoute.to}
            search={(prev) => ({ ...prev, folderId: last._id })}
          >
            {last.name}
          </NavLink>
        </Link>
        {ticketTitle}
        {renderDropdownActions()}
      </>
    );
  }

  return (
    <Flex gap="2" align="center">
      {breadcrumbs.map((b) => (
        <Fragment key={b._id}>
          <CaretRight className="shrink-0" />

          <Link asChild color="gray">
            <NavLink
              to={ticketsRoute.to}
              search={(prev) => ({ ...prev, folderId: b._id })}
            >
              {b.name}
            </NavLink>
          </Link>
        </Fragment>
      ))}
      {ticketTitle}
      {renderDropdownActions()}
    </Flex>
  );
}
