import { Flex, Link, Text } from "@radix-ui/themes";
import {
  type ErrorComponentProps,
  type ErrorRouteComponent,
  // eslint-disable-next-line import/named
  Link as NavLink,
} from "@tanstack/react-router";
import { type FC, type PropsWithChildren } from "react";

import { loginRoute } from "../../../../login/route";
import { ticketsRoute } from "../../../route";

type ErrorProps = ErrorComponentProps & {
  error: Error | Response;
};

const ErrorComponent: ErrorRouteComponent = (props: ErrorProps) => {
  const { error } = props;

  function renderError() {
    if ("status" in error) {
      switch (error.status) {
        case 404:
        case 500:
          return <NotFound />;
        case 401:
          return <Unauthorized />;
      }
    }
  }

  return renderError();
};

export default ErrorComponent;

function NotFound() {
  return (
    <ErrorContainer>
      <Text weight="medium">
        The access provided by the link you followed does not match the current
        ticket URL.
      </Text>
      <Text>Perhaps the link was accidentally changed.</Text>
      <Text>
        Please{" "}
        <Link asChild>
          <NavLink to={loginRoute.to}>log in</NavLink>
        </Link>{" "}
        to continue.
      </Text>
    </ErrorContainer>
  );
}

function Unauthorized() {
  return (
    <ErrorContainer>
      <Text weight="medium">This ticket was not found today!</Text>
      <Text>Perhaps it was deleted.</Text>
      <Text>
        Please return to the{" "}
        <Link asChild>
          <NavLink to={ticketsRoute.to}>tickets page</NavLink>
        </Link>{" "}
        to get your task completed.
      </Text>
    </ErrorContainer>
  );
}

/**
 * A simple container component used to wrap error text. Positions the text to
 * the very center of the page.
 */
const ErrorContainer: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    height="100vh"
    width="100vw"
    position="fixed"
  >
    {children}
  </Flex>
);
