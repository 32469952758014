import {
  Button,
  Dialog,
  Flex,
  Grid,
  Text,
  TextField,
  VisuallyHidden,
} from "@radix-ui/themes";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";

import {
  uploadImageAsset,
  uploadToS3,
} from "../../../../../../services/upload";
import { isNil } from "../../../../../../utils/fp";

export type Asset = {
  source: string;
  type: "image" | "video" | "file";
};

type CreateVersionDialogProps = {
  isPending: boolean;
  open: boolean;
  onOpenChange(open: boolean): void;
  onSubmitAssets(assets: Asset[]): void;
};

const dataTestIdScope = "create-version-dialog" as const;

export default function CreateVersionDialog(props: CreateVersionDialogProps) {
  const { isPending, open, onOpenChange, onSubmitAssets } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);

  async function handleUpload(files?: FileList | null) {
    if (files != null && files.length > 0) {
      setIsUploading(true); // sets uploading indicator to disable submit CTA

      const payloads = (await Promise.all(
        Array.from(files).map((file) => uploadImageAsset(file)),
      )) as FormData[];

      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload?.get("url") as string;
          const key = payload?.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );

      return assets;
    }

    return [];
  }

  useEffect(() => {
    if (!open) {
      setAssets([]);
      setIsUploading(false);
      // Unfortunately, we can't also abort in-flight uploads without a decent
      // refactor to support AbortControllers. Soon(TM). But we can clear the
      // field.
      if (!isNil(fileInputRef.current)) {
        fileInputRef.current.value = "";
      }
    }
  }, [open]);

  return (
    <Dialog.Root
      open={open}
      onOpenChange={onOpenChange}
      data-testid={dataTestIdScope}
    >
      <Dialog.Content
        maxWidth="450px"
        data-testid={`${dataTestIdScope}_content`}
      >
        <Dialog.Title data-testid={`${dataTestIdScope})_title`}>
          Add Design Version
        </Dialog.Title>
        <VisuallyHidden>
          <Dialog.Description data-testid={`${dataTestIdScope}_description`}>
            Upload a new version of your design for review.
          </Dialog.Description>
        </VisuallyHidden>

        <Grid gap="4">
          <Grid gap="2">
            <Text weight="medium" size="1">
              Files for review
            </Text>

            <TextField.Root
              // @ts-expect-error TS2322: might not look pretty, but this works :]
              type="file"
              ref={fileInputRef}
              multiple
              onChange={async ({ target }) => {
                setIsUploading(true);

                const { files } = target;
                const assets = await handleUpload(files);

                setIsUploading(false);
                return setAssets(assets);
              }}
              data-testid={`${dataTestIdScope}_file-input`}
              className="[&>input]:truncate [&>input]:pr-1 [&>input]:pt-[1.5px]"
            />
          </Grid>

          <Flex gap="2" align="center" justify="end">
            <Text
              size="1"
              className={cx({ hidden: !isUploading })}
              data-testid={`${dataTestIdScope}_file-upload-indicator`}
            >
              File upload in progress. Please wait
            </Text>

            <Dialog.Close data-testid={`${dataTestIdScope}_close`}>
              <Button variant="soft">Cancel</Button>
            </Dialog.Close>

            <Button
              disabled={isPending || isUploading || !assets.length}
              loading={isPending || isUploading}
              onClick={() => onSubmitAssets(assets)}
              data-testid={`${dataTestIdScope}_submit-upload-cta`}
            >
              Upload
            </Button>
          </Flex>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}
