import { Input } from "@mg/dali/src";
import { ShareTicketBody } from "@mg/schemas/src/christo/catalyst";
import { Button, Dialog, Grid, Heading } from "@radix-ui/themes";
import {
  useNavigate,
  useRouter,
  useSearch,
  useRouterState,
} from "@tanstack/react-router";
import cx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DeleteFolderDialog } from "./components/DeleteFolderDialog";
import { DeleteTicketDialog } from "./components/DeleteTicketDialog";
import {
  ShareDialog,
  type ShareDialogProps,
} from "./components/dialogs/ShareDialog";
import { DragAndDropFileUpload } from "./components/DragAndDropUpload";
import { EmptyTicketsView } from "./components/EmptyTicketsView";
import { InfiniteScrollTrigger } from "./components/InfiniteScrollTrigger";
import { NoTicketsFoundView } from "./components/NoTicketsFoundView";
import { TicketGrid } from "./components/TicketGrid";
import { TicketsHeader } from "./components/TicketsHeader";
import { TicketTable } from "./components/TicketTable";
import { RenameDialog } from "./components/UpdateNameDialog";
import { ticketsRoute } from "./route";
import { VersionManagerDialog } from "./routes/components/dialogs/VersionManagerDialog";
import { ticketRoute } from "./routes/ticket/route";

import { useUI } from "../../contexts/ui";
import { uploadImageAsset, uploadToS3 } from "../../services/upload";
import { errorAnalyticsPayload } from "../../utils/analytics";
import { isPunttGuest } from "../../utils/auth";
import { batch } from "../../utils/batch";
import { DISALLOWED_FILES } from "../../utils/constants";
import { directoryNameToFolderStub, fileToTicketStub } from "../../utils/files";
import { isNil } from "../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { downloadAsset, errorMessage } from "../../utils/http";
import {
  useDownloadFolderLink,
  useShareFolder,
  useShareFolderLink,
} from "../../utils/queries/folders";
import {
  useCombineTickets,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useDeleteTicketMutation,
  useDownloadTicketLink,
  useProjectMutation,
  useEditProjectMutation,
  useRevisionMutation,
  useShareTicket,
  useShareTicketLink,
  useUpdateFolderMutation,
  useDeleteRevisionMutation,
  useGetTicketMutation,
} from "../../utils/queries/projects";
import { queryClient } from "../../utils/queryClient";
import {
  addTemporaryFolders,
  addTemporaryTickets,
  replaceTemporaryFolder,
  replaceTemporaryTicket,
} from "../../utils/slices/punttProjects";
import { resetTicket, setTicket } from "../../utils/slices/ticket";

export function Tickets() {
  const dispatch = useAppDispatch();

  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const more = useAppSelector((state) => state.punttProjects.more);
  const versions = useAppSelector((state) => state.ticket.revisions);

  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const router = useRouter();
  const navigate = useNavigate({ from: ticketsRoute.to });
  const { folderId, view, participants, ticketTitle } = useSearch({
    from: ticketsRoute.id,
  });
  const posthog = usePostHog();
  const { notify } = useUI();

  const mutation = useDeleteTicketMutation();
  const ticketMutation = useProjectMutation();
  const revisionMutation = useRevisionMutation();
  const createFolderMutation = useCreateFolderMutation();
  const deleteFolderMutation = useDeleteFolderMutation();
  const updateFolderMutation = useUpdateFolderMutation();
  const folderShareLinkMutation = useShareFolderLink();
  const shareFolderMutation = useShareFolder();
  const ticketShareLinkMutation = useShareTicketLink();
  const shareTicketMutation = useShareTicket();
  const folderDownloadMutation = useDownloadFolderLink();
  const ticketDownloadMutation = useDownloadTicketLink();
  const combineTicketsMutation = useCombineTickets();
  const editProjectMutation = useEditProjectMutation();
  const [ticketId, setTicketId] = useState<string>();
  const deleteRevisionMutation = useDeleteRevisionMutation(ticketId!); // ticketId will be set any time this gets used
  const getTicketMutation = useGetTicketMutation();

  const [deletingTicketId, setDeletingTicketId] = useState<string>();
  const [deletingFolderId, setDeletingFolderId] = useState<string>();
  const [shareItem, setShareItem] = useState<ShareDialogProps["item"]>();
  const [shareLink, setShareLink] = useState<string>();
  const [renamingId, setRenamingId] = useState<string>();
  const filesUploadRef = useRef<HTMLInputElement>(null);
  const foldersUploadRef = useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [didSearch, setDidSearch] = useState(() =>
    Boolean(ticketTitle?.length || participants?.length),
  );
  const [versionManagerDialogOpen, setVersionManagerDialogOpen] =
    useState(false);
  const { drawerOpen } = useAppSelector((state) => state.ui);

  const routerState = useRouterState();
  const isLoading = routerState.status === "pending";

  const handleDragLeave = (event: DragEvent) => {
    // If event.relatedTarget is null, it means the drag has left the window
    if (!event.relatedTarget) {
      setIsDragging(false);
      posthog.capture("drag_leave", {
        message: "Drag left the window",
        draggedItems: event.dataTransfer?.items?.length ?? 0,
      });
    }
  };

  const handleUploadFiles = useCallback(
    async (
      files: FileList | File[] | null,
      ticketId?: string,
      revisionName?: string,
      isFolder?: boolean,
    ) => {
      const startTime = performance.now();
      if (files == null || !files.length) {
        console.warn("Attempted to upload files, but none were provided.");
        return;
      }

      const fileList = Array.from(files);

      files = fileList.filter((file) => !DISALLOWED_FILES.includes(file.name));

      const initialFile = fileList[0];
      const ticketStubs = fileList.map(fileToTicketStub);

      if (!ticketId) {
        dispatch(addTemporaryTickets(ticketStubs));
      }

      const payloadsStartTime = performance.now();
      const payloads = (await Promise.all(
        fileList.map((file) => uploadImageAsset(file)),
      )) as FormData[];
      const payloadsDuration = performance.now() - payloadsStartTime;

      if (files.length !== payloads.length) {
        throw new Error("Error getting presigned URL");
      }

      const assetsStartTime = performance.now();
      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload.get("url") as string;
          const key = payload.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );
      const assetsDuration = performance.now() - assetsStartTime;

      /////
      // CREATE TICKET
      /////
      const createTicketStartTime = performance.now();
      if (ticketId == null || (isFolder && ticketId)) {
        await Promise.all(
          fileList.map(async (f, i) => {
            const payload: { title: string; folder?: string } = {
              title: f.name,
            };
            if (isFolder && ticketId) {
              payload["folder"] = ticketId;
            } else if (folderId != null) {
              payload["folder"] = folderId;
            }

            // Create the ticket
            const ticket = await ticketMutation.mutateAsync(payload);

            dispatch(
              replaceTemporaryTicket({
                temporaryId: ticketStubs[i]._id,
                ticket,
              }),
            );

            // Create the ticket's first revision
            await revisionMutation.mutateAsync({
              ticketId: ticket._id,
              payload: {
                name: "Version 1",
                documentImportType: f.name
                  .slice(f.name.lastIndexOf(".") + 1)
                  .toUpperCase(),
                reviewFiles: [assets[i]],
              },
            });
          }),
        );
      } else {
        // Just add a new revision since we're adding to an existing ticket
        await revisionMutation.mutateAsync({
          ticketId: ticketId,
          payload: {
            name: revisionName ?? "Version 1",
            documentImportType: initialFile.name
              .slice(initialFile.name.lastIndexOf(".") + 1)
              .toLocaleUpperCase(),
            reviewFiles: assets,
          },
        });
      }

      /////
      // CREATE REVISION
      /////
      const createTicketDuration = performance.now() - createTicketStartTime;

      if (filesUploadRef.current) filesUploadRef.current.files = null;

      router.invalidate();

      const totalDuration = performance.now() - startTime;
      posthog.capture("add_file_as_ticket", {
        file_count: files.length,
        ticket_id: ticketId,
        is_folder: isFolder,
        revision_name: revisionName ?? "Version 1",
        file_names: fileList.map((file) => file.name),
        unique_file_types: Array.from(
          new Set(fileList.map((file) => file.type)),
        ),
        first_file_type: files[0]?.type,
        total_duration: totalDuration,
        payloads_duration: payloadsDuration,
        assets_duration: assetsDuration,
        create_ticket_duration: createTicketDuration,
      });
    },
    [router, folderId],
  );

  const handleUploadFolder = useCallback(
    async ({
      directoryName,
      files,
      parentFolderId = folderId,
    }: {
      directoryName: string;
      files: FileList | File[];
      parentFolderId?: string;
    }) => {
      const startTime = performance.now();

      // Create the folder stub
      const folderStub = directoryNameToFolderStub(
        directoryName,
        parentFolderId,
      );

      // Only create temporary folder if this is a root-level folder (no parent)
      if (!parentFolderId || parentFolderId === folderId) {
        dispatch(addTemporaryFolders([folderStub]));
      }

      const payloadStartTime = performance.now();
      const payloads = (await Promise.all(
        Array.from(files)
          .filter((f) => !f.name.startsWith("."))
          .map((file) => uploadImageAsset(file)),
      )) as FormData[];
      const payloadsDuration = performance.now() - payloadStartTime;

      const assetsStartTime = performance.now();
      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload?.get("url") as string;
          const key = payload?.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );
      const assetsDuration = performance.now() - assetsStartTime;

      const createFolderStartTime = performance.now();
      const createdFolder = await createFolderMutation.mutateAsync({
        folder: {
          folderId: parentFolderId,
          name: directoryName,
        },
        tickets: [],
      });

      // Only dispatch folder replacement if this was a root-level folder
      if (!parentFolderId || parentFolderId === folderId) {
        dispatch(
          replaceTemporaryFolder({
            temporaryId: folderStub._id,
            // @ts-expect-error TS2322: Date objects will be automatically
            folder: createdFolder.folder,
          }),
        );
      }

      await Promise.all(
        Array.from(files)
          .filter((f) => !f.name.startsWith("."))
          .map(async (f, i) => {
            const ticket = await ticketMutation.mutateAsync({
              title: f.name,
              folder: createdFolder.folder._id,
            });
            const ticketId = ticket._id;

            await revisionMutation.mutateAsync({
              ticketId: ticketId,
              payload: {
                name: "Version 1",
                documentImportType: f.name
                  .slice(f.name.lastIndexOf(".") + 1)
                  .toUpperCase(),
                reviewFiles: [assets[i]],
              },
            });
          }),
      );
      const createFolderDuration = performance.now() - createFolderStartTime;

      if (filesUploadRef.current) filesUploadRef.current.files = null;

      router.invalidate();

      const totalDuration = performance.now() - startTime;
      posthog.capture("folder_upload_attempt", {
        directory_name: directoryName,
        file_count: files.length,
        file_names: Array.from(files).map((file) => file.name),
        unique_file_types: Array.from(
          new Set(Array.from(files).map((file) => file.type)),
        ),
        first_file_type: files[0]?.type,
        total_duration: totalDuration,
        payloads_duration: payloadsDuration,
        assets_duration: assetsDuration,
        create_folder_and_tickets_duration: createFolderDuration,
      });

      return createdFolder;
    },
    [router, folderId],
  );

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();

      if (event.dataTransfer?.items?.length) {
        setIsDragging(true);
      }
    };

    const handleDrop = async (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);

      posthog.capture("file_drop", {
        message: "File dropped into the window",
        dragged_items: event.dataTransfer?.items?.length ?? 0,
      });

      const items = event.dataTransfer?.items;
      if (!items) return;

      // Track all files and directories being processed
      const directFiles: File[] = [];
      const directories = new Map<
        string,
        {
          name: string;
          fullPath: string;
          parentPath: string | null;
          files: File[];
        }
      >();

      // Process all items first to separate files and directories
      for (const item of Array.from(items)) {
        const entry = item.webkitGetAsEntry();

        if (!entry) {
          const file = item.getAsFile();
          if (file) directFiles.push(file);
          continue;
        }

        if (entry.isFile) {
          const file = item.getAsFile();
          if (file) directFiles.push(file);
        } else if (entry.isDirectory) {
          await readEntry(entry, "", directories);
        }
      }

      // Handle direct files first if there are any
      if (directFiles.length > 0) {
        try {
          await handleUploadFiles(directFiles);
        } catch (error) {
          notify({
            title: "Error uploading files",
            message: `An error occurred while uploading the files: ${await errorMessage(error)}`,
            timeout: 10_000,
            variant: "error",
          });
        }
      }

      // Sort directories by path depth
      const sortedDirs = Array.from(directories.values()).sort((a, b) => {
        return (
          (a.fullPath.match(/\//g) || []).length -
          (b.fullPath.match(/\//g) || []).length
        );
      });

      // Track created folders by their full path
      const folderMap = new Map<string, string>();

      // Process folders in hierarchical order
      for (const dir of sortedDirs) {
        const parentFolderId = dir.parentPath
          ? folderMap.get(dir.parentPath)
          : folderId;

        try {
          const result = await handleUploadFolder({
            directoryName: dir.name,
            files: dir.files,
            parentFolderId,
          });

          if (result?.folder?._id) {
            folderMap.set(dir.fullPath, result.folder._id);
          }
        } catch (error) {
          notify({
            title: "Error uploading folder",
            message: `An error occurred uploading folder ${dir.name}: ${await errorMessage(error)}`,
            timeout: 10_000,
            variant: "error",
          });
        }
      }
    };

    // Helper function to read directory entries recursively
    const readEntry = async (
      entry: FileSystemEntry,
      parentPath = "",
      directories: Map<
        string,
        {
          name: string;
          fullPath: string;
          parentPath: string | null;
          files: File[];
        }
      >,
    ): Promise<void> => {
      if (!entry) return;

      return new Promise<void>((resolve) => {
        if (entry.isFile) {
          (entry as FileSystemFileEntry).file((file: File) => {
            if (!file.name.startsWith(".") && parentPath) {
              const dir = directories.get(parentPath);
              if (dir) {
                dir.files.push(file);
              }
            }
            resolve();
          });
        } else if (entry.isDirectory) {
          const reader = (entry as FileSystemDirectoryEntry).createReader();
          const dirName = entry.name;
          const currentPath = parentPath ? `${parentPath}/${dirName}` : dirName;

          // Create directory entry
          directories.set(currentPath, {
            name: dirName,
            fullPath: currentPath,
            parentPath: parentPath || null,
            files: [],
          });

          const readNextBatch = () => {
            reader.readEntries(async (entries: FileSystemEntry[]) => {
              if (!entries.length) {
                resolve();
                return;
              }

              try {
                await Promise.all(
                  entries.map((entry) =>
                    readEntry(entry, currentPath, directories),
                  ),
                );
                readNextBatch(); // Continue reading if there are more entries
              } catch (error) {
                console.error("Error reading entries:", error);
                resolve();
              }
            });
          };

          readNextBatch();
        } else {
          resolve();
        }
      });
    };

    // Attach drag events globally
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      // Cleanup event listeners when the component is unmounted
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, [handleUploadFiles]);

  function handleOpenTicket(ticketId: string) {
    navigate({
      to: ticketRoute.to,
      params: { ticketId },
    });
  }

  function handleOpenFolder(folderId: string) {
    navigate({
      search(prev) {
        return {
          ...prev,
          folderId,
        };
      },
    });
  }

  function handleShareTicket(
    ticketId: string,
    emails: string[],
    shareVersion: ShareTicketBody["version"],
  ) {
    shareTicketMutation.mutate(
      { ticketId, emails, version: shareVersion },
      {
        onSuccess() {
          setShareLink(undefined);
          setShareItem(undefined);
          notify({
            title: "Ticket shared",
            message: `Email has been sent to ${emails.length} recipient(s)`,
          });
        },
      },
    );
  }

  function handleShareFolder(folderId: string, emails: string[]) {
    const startTime = performance.now();
    shareFolderMutation.mutate(
      { folderId, emails },
      {
        onSuccess() {
          const folder = folders.find((f) => f._id === folderId);

          posthog.capture("emailed_folder_share_link", {
            folder_id: folderId,
            folder_name: folder?.name,
            emails,
            num_shares: emails.length,
            duration: performance.now() - startTime,
          });

          setShareLink(undefined);
          setShareItem(undefined);
          notify({
            title: "Folder shared",
            message: `Email has been sent to ${emails.length} recipient(s)`,
          });
        },
      },
    );
  }

  function handleDownloadFolder(folderId: string) {
    const startTime = performance.now();
    folderDownloadMutation.mutate(folderId, {
      onSuccess(data) {
        const folder = folders.find((f) => f._id === folderId);

        posthog.capture("download_folder", {
          folder_id: folderId,
          folder_name: folder?.name,
          data_length: data?.url.length,
          request_duration: performance.now() - startTime,
        });
        if (data) {
          downloadAsset(data.url);
        }
      },
    });
  }

  function handleDownloadTicket(ticketId: string) {
    const startTime = performance.now();
    ticketDownloadMutation.mutate(ticketId, {
      onSuccess(data) {
        const ticket = tickets.find((t) => t._id === ticketId);

        posthog.capture("download_ticket", {
          ticket_id: ticketId,
          request_duration: performance.now() - startTime,
          created_hours_ago: ticket?.createdAt
            ? (Date.now() - new Date(ticket.createdAt).getTime()) /
              (1000 * 60 * 60)
            : undefined,
          num_revisions: ticket?.totalRevisions,
          data_length: data?.url.length,
        });
        if (data) {
          downloadAsset(data.url);
        }
      },
    });
  }

  async function handleDropCards(draggedIds: string[], dropTargetId: string) {
    const dropTarget = mergedFoldersAndTickets.find(
      (t) => t._id === dropTargetId,
    );

    // Move selected tickets/folders to target folder
    if (dropTarget?.isFolder) {
      if (isPunttGuest()) {
        notify({
          title: "Changing file location requires logging in",
          message: "Please log in to move items into a folder.",
        });
        return;
      }

      const startTime = performance.now();
      try {
        await batch(
          draggedIds,
          1,
          async ([dragTargetId]) => {
            const dragTarget = mergedFoldersAndTickets.find(
              (t) => t._id === dragTargetId,
            );
            if (dragTarget?.isFolder) {
              await updateFolderMutation.mutateAsync({
                _id: dragTargetId,
                parentFolder: dropTargetId,
              });
            } else {
              await editProjectMutation.mutateAsync({
                _id: dragTargetId,
                folder: dropTargetId,
              });
            }
          },
          5,
        );
        router.invalidate();
        posthog.capture("tickets_dragged_to_folder", {
          folder_id: dropTargetId,
          num_dropped: draggedIds.length,
          dropped_ids: draggedIds,
          duration_ms: performance.now() - startTime,
        });
      } catch (error) {
        notify({
          title: "Error dragging to folder",
          message: error instanceof Error ? error.message : String(error),
        });
        posthog.capture("tickets_dragged_to_folder_error", {
          folder_id: dropTargetId,
          num_dropped: draggedIds.length,
          dropped_ids: draggedIds,
          duration_ms: performance.now() - startTime,
          ...errorAnalyticsPayload(error),
        });
      }
    }
    // If selectedIds contains exactly one ticket and dropTargetId is also a ticket, combine the selected ticket with the target ticket
    else if (draggedIds.length === 1) {
      const dragTargetId = draggedIds[0];
      const dragTarget = mergedFoldersAndTickets.find(
        (t) => t._id === dragTargetId,
      );
      if (!dragTarget?.isFolder) {
        if (isPunttGuest()) {
          notify({
            title: "Adding a new version requires logging in",
            message: "Please log in to add a new version to a ticket.",
          });
          return;
        }

        const startTime = performance.now();
        await combineTicketsMutation.mutateAsync(
          {
            payload: {
              receivingTicketId: dropTargetId,
              addingTicketId: dragTargetId,
            },
          },
          {
            onSuccess() {
              router.invalidate();
              posthog.capture("combined_tickets", {
                drop_target_id: dropTargetId,
                drag_target_id: dragTargetId,
                duration_ms: performance.now() - startTime,
              });
            },
            onError(error) {
              notify({
                title: "Error adding version to ticket",
                message: error.message,
              });
              posthog.capture("combined_tickets_error", {
                drop_target_id: dropTargetId,
                drag_target_id: dragTargetId,
                duration_ms: performance.now() - startTime,
                ...errorAnalyticsPayload(error),
              });
            },
          },
        );
      }
    }
  }

  function handleManageTicketVersions(ticketId: string) {
    setTicketId(ticketId);
    setVersionManagerDialogOpen(true);

    getTicketMutation.mutate(ticketId, {
      onSuccess(data) {
        if (isNil(data)) return;

        dispatch(setTicket(data));
      },
    });
  }

  function renderTickets() {
    if (mergedFoldersAndTickets.length === 0 && didSearch) {
      return <NoTicketsFoundView />;
    } else if (mergedFoldersAndTickets.length === 0) {
      return (
        <EmptyTicketsView
          onFilesUpload={() => filesUploadRef.current?.click()}
        />
      );
    }

    switch (view) {
      case "list":
        return (
          <TicketTable
            onDeleteTicket={setDeletingTicketId}
            onTicketClick={handleOpenTicket}
            onFolderClick={handleOpenFolder}
            onDeleteFolder={setDeletingFolderId}
            onRenameFolder={setRenamingId}
            onShareTicket={(ticketId: string) => {
              setShareLink(undefined);
              setShareItem({ id: ticketId, type: "ticket" });
              ticketShareLinkMutation.mutate(
                { ticketId, version: "all" },
                {
                  onSuccess(data) {
                    if (isNil(data)) {
                      throw new Error("An unknown error occurred");
                    }

                    setShareLink(data.url);
                  },
                },
              );
            }}
            onShareFolder={(folderId: string) => {
              setShareLink(undefined);
              setShareItem({ id: folderId, type: "folder" });
              folderShareLinkMutation.mutate(folderId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onDownloadFolder={handleDownloadFolder}
            onDownloadTicket={handleDownloadTicket}
          />
        );
      case "grid":
        return (
          <TicketGrid
            onDeleteTicket={setDeletingTicketId}
            onTicketCardClick={handleOpenTicket}
            onFolderCardClick={handleOpenFolder}
            revisionMutation={revisionMutation}
            onDeleteFolder={setDeletingFolderId}
            onRenameFolder={setRenamingId}
            onShareTicket={(ticketId: string) => {
              setShareLink(undefined);
              setShareItem({ id: ticketId, type: "ticket" });
              ticketShareLinkMutation.mutate(
                { ticketId, version: "all" },
                {
                  onSuccess(data) {
                    if (data == null) {
                      throw new Error("An unknown error occurred");
                    }

                    setShareLink(data.url);
                  },
                },
              );
            }}
            onShareFolder={(folderId: string) => {
              setShareLink(undefined);
              setShareItem({ id: folderId, type: "folder" });
              folderShareLinkMutation.mutate(folderId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onDownloadFolder={handleDownloadFolder}
            onDownloadTicket={handleDownloadTicket}
            onDropCards={handleDropCards}
            onManageTicketVersions={handleManageTicketVersions}
          />
        );
      default:
        throw new Error("Unsupported view type");
    }
  }

  function handleSearchInput(hasUserSearched: boolean) {
    setDidSearch(hasUserSearched);
  }

  useEffect(() => {
    if (!versionManagerDialogOpen) {
      dispatch(resetTicket());
      router.invalidate();
    }
  }, [versionManagerDialogOpen]);

  const versionManagerList =
    versions.map((board, i) => ({
      _id: board._id,
      title: board.name ?? `Version ${i + 1}`,
      thumbnail: board.thumbnails[0] ?? board.screenshotUrl,
    })) ?? [];

  return (
    <>
      <Dialog.Root open={isDialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>New Folder</Dialog.Title>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const form = e.target;
              const formData = new FormData(form as HTMLFormElement);
              const folderName = formData.get("folder-name") as string;

              const stubFolder = directoryNameToFolderStub(
                folderName,
                folderId,
              );

              dispatch(addTemporaryFolders([stubFolder]));

              createFolderMutation.mutate(
                {
                  folder: {
                    folderId,
                    name: folderName,
                  },
                  tickets: [],
                },
                {
                  onSuccess({ folder }) {
                    dispatch(
                      replaceTemporaryFolder({
                        temporaryId: stubFolder._id,
                        folder: Object.assign(
                          {
                            _id: folder._id!,
                            createdAt: folder.createdAt as unknown as string,
                            updatedAt: folder.updatedAt as unknown as string,
                            // These will get updated shortly due to the folders and tree queries being refetched
                            // but they must be present here or the types will not match
                            thumbnails: [],
                            totalFiles: 0,
                            participants: [],
                          },
                          folder,
                        ),
                      }),
                    );

                    queryClient.invalidateQueries({
                      queryKey: ["folders", "tree"],
                    });
                    router.invalidate();

                    posthog.capture("folder_created", {
                      folder_id: folderId,
                      folder_name: folderName,
                    });
                  },
                },
              );
              setDialogOpen(false);
            }}
            className="grid gap-4"
          >
            <Input size="sm" placeholder="Folder Name" name="folder-name" />

            <div className="flex items-center justify-end gap-4">
              <Dialog.Close>
                <Button variant="soft">Cancel</Button>
              </Dialog.Close>
              <Button type="submit">Create Folder</Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Root>
      <ShareDialog
        item={shareItem}
        latestVersionNumber={
          shareItem?.type === "ticket"
            ? tickets.find((t) => t._id === shareItem?.id)?.totalRevisions
            : undefined
        }
        shareLink={shareLink}
        shareLinkPending={
          folderShareLinkMutation.isPending || ticketShareLinkMutation.isPending
        }
        isPending={
          shareFolderMutation.isPending || shareTicketMutation.isPending
        }
        onCancel={() => setShareItem(undefined)}
        onShare={(
          emails: string[],
          shareVersion: ShareTicketBody["version"],
        ) => {
          if (shareItem == null) {
            throw new Error("No item to share");
          }

          switch (shareItem.type) {
            case "folder":
              return handleShareFolder(shareItem.id, emails);
            case "ticket":
              return handleShareTicket(shareItem.id, emails, shareVersion);
            default:
              throw new Error("Unknown share item type");
          }
        }}
        onVersionChange={(version) =>
          shareTicketMutation.mutate(
            { ticketId: shareItem!.id, version },
            {
              onSuccess(data) {
                if (data == null) {
                  throw new Error("An unknown error occurred");
                }

                setShareLink(data.url);
              },
            },
          )
        }
      />
      <RenameDialog
        isPending={updateFolderMutation.isPending}
        _id={renamingId}
        onCancel={() => setRenamingId(undefined)}
        onSave={(_id, folderName) => {
          const isFolder =
            mergedFoldersAndTickets.find((t) => t._id === _id)?.isFolder ||
            _id === folderId;

          if (isFolder) {
            return updateFolderMutation.mutate(
              { _id, name: folderName },
              {
                onSuccess() {
                  setRenamingId(undefined);
                  posthog.capture("folder_renamed", {
                    folder_id: _id,
                    folder_name: folderName,
                  });
                  return router.invalidate();
                },
              },
            );
          }
          editProjectMutation.mutate(
            { _id, title: folderName },
            {
              onSuccess() {
                setRenamingId(undefined);
                posthog.capture("folder_renamed", {
                  folder_id: _id,
                  folder_name: folderName,
                });
                return router.invalidate();
              },
            },
          );
        }}
      />
      <DeleteTicketDialog
        isPending={mutation.isPending}
        ticketId={deletingTicketId}
        onCancel={() => setDeletingTicketId(undefined)}
        onDelete={() => {
          if (deletingTicketId == null) {
            throw new Error(
              "The ticket we want to delete cannot have a null or undefined ID",
            );
          }

          mutation.mutate(
            { ticketId: deletingTicketId },
            {
              onSuccess() {
                setDeletingTicketId(undefined);
                posthog.capture("ticket_deleted", {
                  ticket_id: deletingTicketId,
                });
                return router.invalidate();
              },
              onError() {
                window.alert("Unable to delete ticket. Please try again.");
              },
            },
          );
        }}
      />
      <DeleteFolderDialog
        isPending={deleteFolderMutation.isPending}
        folderId={deletingFolderId}
        onCancel={() => setDeletingFolderId(undefined)}
        onDelete={() => {
          if (deletingFolderId == null) {
            throw new Error(
              "The folder we want to delete cannot have a null or undefined ID",
            );
          }

          deleteFolderMutation.mutate(deletingFolderId, {
            onSuccess() {
              if (folderId === deletingFolderId) {
                navigate({
                  search: ({ folderId: _folderId, ...rest }) => rest,
                });
              }
              setDeletingFolderId(undefined);
              posthog.capture("folder_deleted", {
                folder_id: deletingFolderId,
              });
              return router.invalidate();
            },
            onError() {
              window.alert("Unable to delete folder. Please try again.");
            },
          });
        }}
      />
      <article
        className={cx(
          "grid min-h-[calc(100vh-57px)] content-start gap-6 py-6",
          {
            "ml-80": drawerOpen,
          },
        )}
      >
        {/* This is used in both the no tickets view and the "File Upload" option in the "New" dropdown */}
        <input
          type="file"
          ref={filesUploadRef}
          className="hidden"
          multiple
          onChange={({ target }) => handleUploadFiles(target.files)}
        />
        <input
          type="file"
          ref={foldersUploadRef}
          className="hidden"
          // @ts-expect-error TS(2322): We have this type
          webkitdirectory="true"
          // eslint-disable-next-line react/no-unknown-property
          mozdirectory="true"
          // eslint-disable-next-line react/no-unknown-property
          directory="true"
          onChange={async (event) => {
            const files = Array.from(event.target.files || []);
            if (!files.length) return;

            // Group files by their full directory path
            const directories = new Map<
              string,
              {
                name: string;
                fullPath: string;
                parentPath: string | null;
                files: File[];
              }
            >();

            files.forEach((file) => {
              const fullPath = file.webkitRelativePath;
              const parts = fullPath.split("/");
              // Remove the file name
              parts.pop();

              // Process each directory level in the path
              parts.reduce((parentPath, dirName, index) => {
                const currentPath = parentPath
                  ? `${parentPath}/${dirName}`
                  : dirName;

                if (!directories.has(currentPath)) {
                  directories.set(currentPath, {
                    name: dirName,
                    fullPath: currentPath,
                    parentPath: index === 0 ? null : parentPath,
                    files: [],
                  });
                }

                // If this is the immediate parent directory of the file, add the file
                if (index === parts.length - 1) {
                  const dir = directories.get(currentPath);
                  if (dir) {
                    dir.files.push(file);
                  }
                }

                return currentPath;
              }, "");
            });

            // Sort by path depth to ensure parents are created before children
            const sortedDirs = Array.from(directories.values()).sort((a, b) => {
              return (
                (a.fullPath.match(/\//g) || []).length -
                (b.fullPath.match(/\//g) || []).length
              );
            });

            // Track created folders by their full path
            const folderMap = new Map<string, string>();

            for (const dir of sortedDirs) {
              const parentFolderId = dir.parentPath
                ? folderMap.get(dir.parentPath)
                : folderId;

              try {
                const result = await handleUploadFolder({
                  directoryName: dir.name,
                  files: dir.files,
                  parentFolderId,
                });

                if (result?.folder?._id) {
                  folderMap.set(dir.fullPath, result.folder._id);
                }
              } catch (error) {
                notify({
                  title: "Error uploading folder",
                  message: `An error occurred uploading folder ${dir.name}: ${await errorMessage(error)}`,
                  timeout: 10_000,
                  variant: "error",
                });
              }
            }
          }}
        />
        <VersionManagerDialog
          open={versionManagerDialogOpen}
          onOpenChange={setVersionManagerDialogOpen}
          onDeleteVersion={(revisionId) =>
            deleteRevisionMutation.mutate(revisionId, {
              onSuccess(data) {
                posthog.capture("delete_version", {
                  ticketId,
                  versionId: revisionId,
                });
                if (isNil(data)) return;

                const { boards } = data;

                if (!boards.length) {
                  setVersionManagerDialogOpen(false);
                }
              },
            })
          }
          revisions={versionManagerList}
          isPending={deleteRevisionMutation.isPending}
          deletingId={deleteRevisionMutation.variables}
          isLoadingTickets={getTicketMutation.isPending}
        />
        <TicketsHeader
          isPending={isLoading}
          onSearch={handleSearchInput}
          onFilesUpload={() => filesUploadRef.current?.click()}
          onFolderUpload={() => foldersUploadRef.current?.click()}
          onNewFolder={() => setDialogOpen(true)}
          onDeleteFolder={setDeletingFolderId}
          onRenameFolder={setRenamingId}
          onShareFolder={(folderId: string) => {
            setShareLink(undefined);
            setShareItem({ id: folderId, type: "folder" });
            folderShareLinkMutation.mutate(folderId, {
              onSuccess(data) {
                if (data == null) {
                  throw new Error("An unknown error occurred");
                }

                setShareLink(data.url);
              },
            });
          }}
          onDownloadFolder={handleDownloadFolder}
        />
        {isLoading ? (
          <Grid gap="4" className="mt-40 text-center">
            <Heading size="5">Loading files and folders...</Heading>
          </Grid>
        ) : (
          <>
            {renderTickets()}
            <InfiniteScrollTrigger
              lastItemIndex={
                mergedFoldersAndTickets[mergedFoldersAndTickets.length - 1]
                  ?.updatedIndex
              }
              hasMore={more}
            />
            <DragAndDropFileUpload isDragging={isDragging} />
          </>
        )}
      </article>
    </>
  );
}
