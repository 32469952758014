import { Tooltip } from "@radix-ui/themes";
import cx from "classnames";
import { type ReactNode } from "react";

import { useAppSelector } from "../utils/hooks";

export const AuthTooltip = ({
  classNames,
  children,
  trigger = "auth-tooltip",
}: {
  children: ReactNode;
  classNames?: string;
  trigger?: string;
}) => {
  const user = useAppSelector((state) => state.auth.value);

  if (user == null || user.referredBy == null) {
    return children;
  }

  return (
    <Tooltip content="Click to Log In or Sign Up">
      <div className={cx("relative", classNames)} data-auth-trigger={trigger}>
        {children}
        <div className="absolute inset-0 z-10 bg-transparent" />
      </div>
    </Tooltip>
  );
};
