import { type Asset as BaseAsset } from "@mg/schemas/src/commons/asset";
import {
  Spinner,
  Trash,
  Plus,
  File as FileIcon,
  TextAa as FontIcon,
  FileDoc,
  FilePdf,
  FileText,
  FileVideo,
  FilePpt,
  Link,
  X,
  Check,
  DownloadSimple,
} from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  Flex,
  Grid,
  Heading,
  Text,
  TextArea,
  TextField,
  Tooltip,
} from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import cx from "classnames";
import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { Preview } from "../../../components/Preview";
import { posthogClient } from "../../../config/posthog";
import { useUI } from "../../../contexts/ui";
import { getGuidelines } from "../../../services/brand-guidelines";
import { uploadImageAsset, uploadToS3 } from "../../../services/upload";
import { useAnalytics } from "../../../utils/analytics";
import { requiresAuth } from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { downloadAsset } from "../../../utils/http";
import { useUpdateGuidelinesMutation } from "../../../utils/queries/projects";
import { isValidUrl } from "../../../utils/validation";
import { authLayoutRoute } from "../../auth-layout/route";
import { ticketsRoute } from "../../tickets/route";
// import {
//   useEnterprises,
//   useUpdateEnterpriseSettings,
// } from "../../../utils/queries/enterprises";

interface AssetWithUrl extends BaseAsset {
  publicUrl?: string;
  name?: string;
}

export const knowledgeSettingRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "knowledge-settings",
  beforeLoad() {
    const canAccess = posthogClient.getFeatureFlag("manage-brand-guidelines");

    if (!canAccess) {
      throw redirect({
        to: ticketsRoute.to,
      });
    }

    requiresAuth();
  },
  component: KnowledgeSettings,
});

function KnowledgeSettings() {
  const { notify } = useUI();
  const query = useQuery({
    queryKey: ["brand-guidelines"],
    queryFn: getGuidelines,
  });
  const mutation = useUpdateGuidelinesMutation();
  // const updateEnterpriseSettingsMutation = useUpdateEnterpriseSettings();
  const posthog = useAnalytics();

  const [brandGuidelines, setBrandGuidelines] = useState("");
  const [files, setFiles] = useState<AssetWithUrl[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSaved, setIsSaved] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUploadingFont, setIsUploadingFont] = useState(false);
  // const fontFileInputRef = useRef<HTMLInputElement>(null);
  const [isTyping, setIsTyping] = useState(false);
  const saveTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [editingUrlForFile, setEditingUrlForFile] = useState<string | null>(
    null,
  );
  const { drawerOpen } = useAppSelector((state) => state.ui);
  // const user = useAppSelector((state) => state.auth.value);
  // const { data: enterprises } = useEnterprises();
  // const currentEnterprise = enterprises?.find(
  //   (e) => e._id === user?.enterpriseId,
  // );

  useEffect(() => {
    if (query.data) {
      setBrandGuidelines(query.data.description as string);
      setFiles(query.data.files as []);
    }
  }, [query.data]);

  const handleInput = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBrandGuidelines(e.target.value);
    setIsTyping(true);
    setIsSaved(false);

    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    saveTimeoutRef.current = setTimeout(async () => {
      try {
        setIsTyping(false);
        await mutation.mutateAsync(
          { description: e.target.value, files },
          {
            onSuccess() {
              setIsSaved(true);
              setTimeout(() => setIsSaved(false), 2000);
              posthog.capture("brand-guidelines-updated", {
                description: e.target.value,
                files: files,
              });
            },
          },
        );
      } catch (error) {
        notify({
          title: "Error saving guidelines",
          message: String(error),
          variant: "error",
        });
      }
    }, 2000);
  };

  function getFileIcon(fileName: string) {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "pdf":
        return <FilePdf size={20} className="min-w-5" />;
      case "doc":
      case "docx":
        return <FileDoc size={20} className="min-w-5" />;
      case "txt":
        return <FileText size={20} className="min-w-5" />;
      case "mp4":
      case "mov":
      case "avi":
        return <FileVideo size={20} className="min-w-5" />;
      case "ppt":
      case "pptx":
        return <FilePpt size={20} className="min-w-5" />;
      case "ttf":
      case "otf":
      case "woff":
      case "woff2":
        return <FontIcon size={20} className="min-w-5" />;
      default:
        return <FileIcon size={20} className="min-w-5" />;
    }
  }

  const handleBrandGuidelineUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileList = event?.target?.files;
    if (fileList?.[0]) {
      setIsUploading(true);
      const startTime = performance.now();

      try {
        const payloads = (await Promise.all(
          Array.from(fileList).map((file) => uploadImageAsset(file, false)),
        )) as FormData[];

        const assets = await Promise.all(
          payloads.map(async (payload, i) => {
            const url = payload.get("url") as string;
            const key = payload.get("key") as string;
            payload.delete("url");

            await uploadToS3({ url, payload });

            return {
              source: "https://static.puntt.ai/" + key,
              originalSource: fileList[i]?.name,
              type: (fileList[i]?.type?.includes("image")
                ? "image"
                : "file") as AssetWithUrl["type"],
            };
          }),
        );

        const newFiles = [...(Array.from(files) ?? []), ...assets];
        setFiles((prev) => [...(prev ?? []), ...assets]);
        posthog.capture("brand-guidelines-file-uploaded", {
          durationMs: performance.now() - startTime,
          files: assets,
        });

        mutation.mutate(
          { description: brandGuidelines, files: newFiles as AssetWithUrl[] },
          {
            onSuccess() {
              posthog.capture("brand-guidelines-updated", {
                description: brandGuidelines,
                files: files,
              });
              notify({
                title: "Brand Guidelines Updated",
                message: "",
                variant: "success",
              });
            },
          },
        );

        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        notify({
          title: "Error uploading file",
          message: String(error),
          variant: "error",
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  // const handleFontUpload = async (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   const files = event?.target?.files;
  //   if (files?.[0]) {
  //     setIsUploadingFont(true);
  //     const startTime = performance.now();

  //     try {
  //       const payloads = (await Promise.all(
  //         Array.from(files).map((file) => uploadImageAsset(file)),
  //       )) as FormData[];
  //       console.log("payloads", payloads, files);
  //       const assets = await Promise.all(
  //         payloads.map(async (payload) => {
  //           const url = payload.get("url") as string;
  //           const key = payload.get("key") as string;
  //           payload.delete("url");

  //           await uploadToS3({ url, payload });

  //           return `https://static.puntt.ai/${key}`;
  //         }),
  //       );
  //       updateEnterpriseSettingsMutation.mutate(
  //         {
  //           extraFonts: assets,
  //         },
  //         {
  //           onSuccess: () => {
  //             notify({
  //               title: "File(s) uploaded",
  //               variant: "success",
  //             });
  //             posthog.capture("fonts-file-uploaded", {
  //               durationMs: performance.now() - startTime,
  //               files: assets,
  //             });
  //           },
  //         },
  //       );

  //       if (fontFileInputRef?.current) {
  //         fontFileInputRef.current.value = "";
  //       }
  //     } catch (error) {
  //       notify({
  //         title: "Error uploading font",
  //         message: "Please try again",
  //         variant: "error",
  //       });
  //     } finally {
  //       setIsUploadingFont(false);
  //     }
  //   }
  // };

  const handleDelete = (file: { source: string }) => {
    const newFiles = files.filter((f) => f.source !== file.source);
    setFiles(newFiles);
    mutation.mutate(
      { description: brandGuidelines, files: newFiles },
      {
        onSuccess() {
          notify({
            title: "File removed",
            message: "",
            variant: "success",
          });
        },
        onError() {
          // Revert the state if the API call fails
          setFiles(files);
          notify({
            title: "Error removing file",
            message: "Please try again",
            variant: "error",
          });
        },
      },
    );
  };

  // Helper function to get file extension from either originalSource or source
  const getFileExtension = (file: AssetWithUrl) => {
    const fileName = file.originalSource || file.source;
    return fileName.split(".").pop()?.toLowerCase() || "";
  };

  interface AddUrlProps {
    file: AssetWithUrl;
    files: AssetWithUrl[];
    editingUrlForFile: string | null;
    setEditingUrlForFile: (url: string | null) => void;
    startingUrl: string;
    onSave: (files: AssetWithUrl[]) => void;
  }

  function AddUrlButton({
    file,
    files,
    editingUrlForFile,
    setEditingUrlForFile,
    startingUrl,
    onSave,
  }: AddUrlProps) {
    const [urlInput, setUrlInput] = useState(startingUrl);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          formRef.current &&
          !formRef.current.contains(event.target as Node)
        ) {
          setEditingUrlForFile(null);
        }
      }

      if (editingUrlForFile === file.source) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [editingUrlForFile, file.source, setEditingUrlForFile]);

    return (
      <Button
        variant="ghost"
        onClick={() => {
          if (editingUrlForFile !== file.source) {
            setEditingUrlForFile(file.source);
            setUrlInput(file.publicUrl || "");
          }
        }}
        className="ml-2 h-4 text-puntt-blue-12 hover:text-puntt-blue-11"
      >
        <Flex align="center" gap="1" className="h-full">
          {editingUrlForFile !== file.source && <Link size={16} />}
          <Text size="2" className="flex h-full items-center">
            {editingUrlForFile === file.source ? (
              <form
                ref={formRef}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  const input = e.currentTarget.querySelector("input");
                  if (input?.validity.valid) {
                    const newFiles = files.map((f) => {
                      if (f.source === file.source) {
                        return { ...f, publicUrl: urlInput };
                      }
                      return f;
                    });
                    onSave(newFiles);
                  }
                }}
                className="flex items-center"
              >
                <div className="relative flex items-center self-stretch bg-puntt-blue-1">
                  <Tooltip
                    className="bg-puntt-blue-dark-7"
                    side="bottom"
                    content={
                      !urlInput || urlInput.match(/^https?:\/\/.*$/)
                        ? "Press Enter or click Check to save the public URL where this file can be accessed, or click X or outside the input to cancel"
                        : "Please enter a valid URL starting with http:// or https://"
                    }
                  >
                    <TextField.Root
                      type="url"
                      value={urlInput}
                      onChange={(e) => setUrlInput(e.target.value)}
                      placeholder="Enter URL"
                      className="w-[400px]"
                      autoFocus
                      onClick={(e) => e.stopPropagation()}
                    >
                      <TextField.Slot side="right">
                        <X
                          size={16}
                          className="cursor-pointer hover:text-puntt-blue-5"
                          onClick={() => {
                            setEditingUrlForFile(null);
                            setUrlInput("");
                          }}
                        />
                        <Button
                          variant="ghost"
                          disabled={!!urlInput.length && !isValidUrl(urlInput)}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newFiles = files.map((f) => {
                              if (f.source === file.source) {
                                return { ...f, publicUrl: urlInput };
                              }
                              return f;
                            });
                            onSave(newFiles);
                            setEditingUrlForFile(null);
                            setUrlInput("");
                          }}
                          className="cursor-pointer hover:text-puntt-blue-5"
                        >
                          <Check size={16} />
                        </Button>
                      </TextField.Slot>
                    </TextField.Root>
                  </Tooltip>
                </div>
              </form>
            ) : (
              file.publicUrl || "Add URL"
            )}
          </Text>
        </Flex>
      </Button>
    );
  }

  return (
    <div
      className={cx("mb-4 px-8 py-2", {
        "ml-80": drawerOpen,
      })}
    >
      <Flex direction="column" className="px-2 py-4">
        <Heading size="8" mb="4" className="font-sans">
          Knowledge Settings
        </Heading>
        <Text size="3" color="gray">
          Guidelines are used by Puntt AI to generate feedback for AI Reviews.
        </Text>
      </Flex>

      <div className="h-4" />

      <Grid columns={{ initial: "1", md: "1" }} gap="4" className="mb-16 px-2">
        <Flex direction="column" gap="4">
          <Heading size="6" mb="2" className="font-sans">
            Brand Guidelines
          </Heading>
          <Text size="2" color="gray" mb="4">
            Add or edit the guidelines used by Puntt AI for feedback.
          </Text>

          <Flex direction="column" gap="4">
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              accept=".pdf,.doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .ods, .odp, .odg, .odf, .txt, .rtf"
              onChange={handleBrandGuidelineUpload}
              multiple
            />

            <Button
              variant="outline"
              onClick={() => fileInputRef.current?.click()}
              className={cx(
                "flex h-8 w-1/2 items-center justify-center gap-2 rounded-md border border-[rgba(0,50,255,0.47)] px-3 md:w-fit",
                {
                  "text-puntt-neutral-gray-11": isUploadingFont,
                },
                "hover:bg-puntt-neutral-gray-4",
                "active:text-puntt-neutral-gray-5",
              )}
            >
              {isUploading ? (
                <Spinner size={20} className="animate-spin" />
              ) : (
                <>
                  <Plus size={20} />
                  <Text size="2">
                    {fileInputRef.current?.files?.[0]?.name ?? "Select Files"}
                  </Text>
                </>
              )}
            </Button>

            <Flex direction="column" gap="2">
              <Flex align="center" gap="2">
                <Text weight="medium">Or Type Guidelines</Text>
                {isTyping && (
                  <Text size="2" color="gray">
                    <Spinner size={12} className="animate-spin" />
                  </Text>
                )}
                {isSaved && (
                  <Text size="2" color="green">
                    Saved
                  </Text>
                )}
              </Flex>
              <TextArea
                placeholder="Enter your guidelines here"
                value={brandGuidelines}
                onChange={handleInput}
                rows={8}
              />
            </Flex>

            <ul className="space-y-2">
              {files
                ?.filter((file) => {
                  const ext = getFileExtension(file);
                  return [
                    "pdf",
                    "doc",
                    "docx",
                    "xls",
                    "xlsx",
                    "ppt",
                    "pptx",
                    "odt",
                    "ods",
                    "odp",
                    "odg",
                    "odf",
                    "txt",
                    "rtf",
                  ].includes(ext);
                })
                .map(
                  (file: {
                    source: string;
                    originalSource?: string;
                    name?: string;
                    publicUrl?: string;
                  }) => {
                    const fileName =
                      file?.originalSource ||
                      file?.name ||
                      file?.source?.split("/").pop();
                    return (
                      <li
                        key={file?.source}
                        className="group flex-wrap items-center justify-start"
                      >
                        <Dialog.Root>
                          <Dialog.Trigger>
                            <Button
                              variant="ghost"
                              className="max-w-full truncate py-0 text-puntt-blue-12 underline decoration-puntt-blue-12 underline-offset-4"
                            >
                              {getFileIcon(fileName || "")}
                              <span className="truncate">{fileName}</span>
                            </Button>
                          </Dialog.Trigger>
                          <Dialog.Content
                            style={{
                              width: "90vw",
                              maxWidth: "80rem",
                              height: "90vh",
                              maxHeight: "60rem",
                            }}
                          >
                            <Flex
                              justify="between"
                              align="center"
                              gap="4"
                              mb="4"
                            >
                              <Dialog.Title className="mb-0">
                                {fileName}
                              </Dialog.Title>
                              <Dialog.Description className="hidden">
                                File preview
                              </Dialog.Description>
                              <Button
                                className="ml-auto flex gap-1 hover:bg-puntt-accent-10 hover:text-base-white"
                                variant="outline"
                                size="1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAsset(file?.source, fileName);
                                }}
                              >
                                <Text>Download</Text>
                                <DownloadSimple width={16} height={16} />
                              </Button>
                              <Dialog.Close>
                                <Button
                                  type="button"
                                  variant="soft"
                                  color="gray"
                                >
                                  Close
                                </Button>
                              </Dialog.Close>
                            </Flex>
                            <Preview url={file?.source} />
                          </Dialog.Content>
                        </Dialog.Root>

                        <AddUrlButton
                          file={file}
                          files={files}
                          editingUrlForFile={editingUrlForFile}
                          setEditingUrlForFile={setEditingUrlForFile}
                          startingUrl={file.publicUrl || ""}
                          onSave={(newFiles) => {
                            setFiles(newFiles);
                            mutation.mutate(
                              { description: brandGuidelines, files: newFiles },
                              {
                                onSuccess() {
                                  notify({
                                    title: "URL added",
                                    message: "",
                                    variant: "success",
                                  });
                                  setEditingUrlForFile(null);
                                },
                                onError() {
                                  notify({
                                    title: "Error adding URL",
                                    message: "Please try again",
                                    variant: "error",
                                  });
                                },
                              },
                            );
                          }}
                        />

                        <Button
                          variant="ghost"
                          onClick={() => handleDelete(file)}
                          className="opacity-0 group-hover:opacity-100"
                        >
                          <Trash size={16} />
                        </Button>
                      </li>
                    );
                  },
                )}
            </ul>
          </Flex>
        </Flex>
        {/* leaving this for now, but we can add it back in after figuring out the api */}
        {/* <Flex direction="column" gap="4">
          <Heading size="6" mb="2" className="font-sans">
            Fonts
          </Heading>
          <Text size="2" color="gray" mb="4">
            Add any custom fonts used in documents so they render properly and
            Puntt AI can review for correct usage.
          </Text>

          <input
            type="file"
            className="hidden"
            ref={fontFileInputRef}
            accept=".ttf,.otf,.woff,.woff2"
            onChange={handleFontUpload}
            multiple
          />

          <Button
            variant="outline"
            onClick={() => fontFileInputRef.current?.click()}
            className={cx(
              "flex h-8 w-1/2 items-center justify-center gap-2 rounded-md border border-[rgba(0,50,255,0.47)] px-3 md:w-fit",
              {
                "text-puntt-neutral-gray-11": isUploadingFont,
              },
              "hover:bg-puntt-neutral-gray-4",
              "active:text-puntt-neutral-gray-5",
            )}
          >
            {isUploadingFont ? (
              <Spinner size={20} className="animate-spin" />
            ) : (
              <>
                <Plus size={20} />
                <Text size="2">
                  {fontFileInputRef.current?.files?.[0]?.name ?? "Select Files"}
                </Text>
              </>
            )}
          </Button>

          <ul className="space-y-2">
            {currentEnterprise?.profileSettings?.extraFonts?.map((file) => {
              const fileName = file.split("/").pop();
              return (
                <li
                  key={file}
                  className="group flex items-center justify-start gap-2"
                >
                  <Flex align="center" gap="2" className="min-w-0">
                    {getFileIcon(fileName || "")}
                    <Button
                      variant="ghost"
                      onClick={(e) => {
                        e.preventDefault();
                        downloadAsset(file, fileName);
                      }}
                      className="truncate text-puntt-blue-12 underline decoration-puntt-blue-12 underline-offset-4"
                    >
                      <span className="truncate">{fileName}</span>
                    </Button>
                  </Flex>

                  {/* <Button
                    variant="ghost"
                    onClick={() => handleDelete(file)}
                    className="opacity-0 group-hover:opacity-100"
                  >
                    <Trash size={16} />
                  </Button>
                </li>
              );
            })}
          </ul>
        </Flex> */}
      </Grid>
    </div>
  );
}
