import { File, Trash } from "@phosphor-icons/react";
import {
  Badge,
  Button,
  Card,
  Dialog,
  Flex,
  Grid,
  IconButton,
  Text,
  VisuallyHidden,
} from "@radix-ui/themes";
import cx from "classnames";

import { useAppSelector } from "../../../../../utils/hooks";
import { assetForUser } from "../../../../../utils/imageHandler";

type VersionManagerDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  onDeleteVersion(revisionId: string, index: number): void;
  revisions: { _id: string; title: string; thumbnail?: string }[];
  isPending: boolean;
  deletingId?: string;
  isLoadingTickets?: boolean;
};

export function VersionManagerDialog(props: VersionManagerDialogProps) {
  const {
    open,
    onOpenChange,
    onDeleteVersion,
    revisions,
    isPending,
    deletingId,
    isLoadingTickets = false,
  } = props;

  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Manage versions</Dialog.Title>
        <VisuallyHidden>
          <Dialog.Description>
            Delete a version for this ticket
          </Dialog.Description>
        </VisuallyHidden>

        <Grid gap="4">
          <Card variant="surface">
            {isLoadingTickets && (
              <Flex align="center" justify="center" py="4">
                <Text>Loading versions...</Text>
              </Flex>
            )}
            {revisions.map((r, i) => (
              <Flex
                key={r._id}
                align="center"
                justify="between"
                className="border-b-puntt-neutral-6 border-b px-4 py-2 last:border-b-0"
              >
                <Flex align="center" gap="4">
                  {r.thumbnail ? (
                    <img
                      src={r.thumbnail}
                      alt={`Thumbnail for version ${r.title}`}
                      className="block size-7 border border-puntt-neutral-gray-4 object-cover"
                    />
                  ) : (
                    <Flex
                      align="center"
                      justify="center"
                      className="size-7 bg-puntt-neutral-gray-4"
                    >
                      <File className="text-puntt-neutral-gray-9" size={21} />
                    </Flex>
                  )}

                  <Text>V{revisions.length - i}</Text>
                </Flex>

                <Badge
                  variant="surface"
                  className={cx({ hidden: i !== reversedVersionIndex })}
                >
                  Current
                </Badge>
                <IconButton
                  variant="ghost"
                  color="gray"
                  onClick={() => onDeleteVersion(r._id, i)}
                  // Disallow deleting the last version
                  disabled={isPending || revisions.length === 1}
                  loading={isPending && deletingId === r._id}
                >
                  <Trash />
                </IconButton>
              </Flex>
            ))}
          </Card>

          <Dialog.Close className="max-w-max place-self-end">
            <Button color="gray" variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}
