import {
  type BaseTicketComment,
  type CreateTicketCommentRequest,
} from "@mg/schemas/src/christo/catalyst";
import {
  EnterpriseProfileType,
  TicketCommentDisposition,
  TicketCommentBoard,
} from "@mg/schemas/src/commons";
import {
  SpeakerSimpleHigh,
  Play,
  Pause,
  CornersOut,
  Timer,
} from "@phosphor-icons/react";
import {
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Slider,
  Text,
} from "@radix-ui/themes";
import cx from "classnames";
import Hls from "hls.js";
import {
  useState,
  useRef,
  useEffect,
  useReducer,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";

import {
  CommentMentions,
  type Selections,
} from "./CommentMentions/CommentMentions";
import { CommentPin } from "./CommentPin";

import PendingProgress from "../../../components/PendingProgressBar";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  useCreateAskPunttComment,
  useNewCommentMutation,
} from "../../../utils/queries/projects";
import { setActiveCommentId, setComments } from "../../../utils/slices/ticket";

export const formatTimeStamp = (seconds?: number | null): string => {
  if (!seconds) return "00:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const VideoMode = forwardRef<
  {
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  },
  unknown
>((_, ref) => {
  const ticketId = useAppSelector((state) => state.ticket.value!._id);
  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  const currentVersion = useAppSelector(
    (state) => state.ticket.revisions[reversedVersionIndex!],
  );
  const boardId = currentVersion._id;
  const videoUrl = currentVersion.reviewFiles![0].source;
  const revisionIsPending = currentVersion.isPending;
  const videoRef = useRef<HTMLVideoElement>(null);
  const commentBoxRef = useRef<HTMLDivElement>(null);
  const commentTrackRef = useRef<HTMLDivElement>(null);
  const mentionsRef = useRef<{
    getSelections: () => Selections;
    closeMentions: () => void;
  }>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState([0]);
  const [volume, setVolume] = useState([0.5]);

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [required, setRequired] = useState<boolean | "indeterminate">(true);
  const [videoStart, setVideoStart] = useState<number | null>(0);
  const [hasTimeSet, setHasTimeSet] = useState(true);
  const [isVolumeShowing, toggleVolumeShowing] = useReducer((x) => !x, true);

  const user = useAppSelector((state) => state.auth.value);
  const comments = useAppSelector((state) => state.ticket.comments);
  const userIsReviewing = comments.some(
    (c) => c.isPending && c.createdBy === user?.userID,
  );
  const { showResolvedComments, showDismissedComments } = useAppSelector(
    (state) => state.ui,
  );
  const dispatch = useAppDispatch();

  const askPunttMutation = useCreateAskPunttComment();

  const currentRevisionComments = comments.filter(
    (comment: BaseTicketComment) => {
      if (
        !showDismissedComments &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }

      if (
        !showResolvedComments &&
        comment.disposition == TicketCommentDisposition.RESOLVED
      ) {
        return false;
      }
      if (
        user?.role != "ai" &&
        user?.role != "meaningful-gigs" &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }
      return comment.boardId == boardId && comment.videoStart != null;
    },
  );
  function sortByVideoStart(
    comments: BaseTicketComment[],
  ): (BaseTicketComment | BaseTicketComment[])[] {
    // First, sort the comments array based on videoStart values
    const sortedComments = comments.sort(
      (a, b) => (a.videoStart || 0) - (b.videoStart || 0),
    );
    return sortedComments;
  }

  const sortedComments = useMemo(
    () => sortByVideoStart(currentRevisionComments),
    [currentRevisionComments],
  );

  const [duration, setDuration] = useState<number>(0);

  const toggleTimeStampSet = () => {
    setHasTimeSet((prev) => !prev);
    if (!hasTimeSet) {
      setVideoStart(videoRef.current?.currentTime ?? null);
    } else {
      setVideoStart(null);
    }
  };

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration);
      }
    };

    if (revisionIsPending) {
      return;
    }

    const videoElement = videoRef.current;
    if (videoElement) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(videoElement);
      }
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.addEventListener("durationchange", handleLoadedMetadata);
    }

    return () => {
      videoElement?.removeEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement?.removeEventListener("durationchange", handleLoadedMetadata);
    };
  }, [videoUrl, revisionIsPending]);
  // Expose the handleCommentClicked method to the parent component
  useImperativeHandle(ref, () => ({
    handleCommentClicked,
    duration: videoRef.current?.duration ?? 0,
  }));
  // const [playbackRate, setPlaybackRate] = useState(1);

  const mutation = useNewCommentMutation();

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
        dispatch(setActiveCommentId(null));
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const setMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      toggleVolumeShowing();
    }
  };

  const handleProgress = () => {
    if (videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;

      setProgress([progress]);
      if (!message.trim().length) {
        setVideoStart(videoRef.current.currentTime);
      }
      if (progress >= 100) {
        setIsPlaying(false);
      }
    }
  };
  const handleCommentClicked = (seekTime: number, commentId?: string) => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      const progress = (seekTime / videoRef.current.duration) * 100;
      videoRef.current.currentTime = seekTime;
      setProgress([progress]);

      if (!message.trim().length) {
        setVideoStart(videoRef.current.currentTime);
      }
      if (commentId) {
        dispatch(setActiveCommentId(commentId as string));
      }
    }
  };

  const handleSeek = (videoSpot: number[]) => {
    if (videoRef.current) {
      const seekTime = (videoSpot[0] / 100) * videoRef.current.duration;
      videoRef.current.currentTime = seekTime;
      setProgress(videoSpot);

      if (!message.trim().length) {
        setVideoStart(videoRef.current.currentTime);
      }
      dispatch(setActiveCommentId(null));
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      return videoRef.current?.requestFullscreen();
    }
    document.exitFullscreen();
  };

  const handleGetSelections = () => {
    if (mentionsRef.current) {
      return mentionsRef.current.getSelections();
    }
  };

  const handleVolumeChange = (vol: number[]) => {
    setVolume(vol);
    if (videoRef.current) {
      videoRef.current.volume = vol[0];
    }
  };

  return (
    <div
      data-mode="video"
      className="grid min-h-80 min-w-0 grid-rows-[1fr_auto] place-self-stretch"
    >
      {revisionIsPending ? (
        <div className="flex h-full justify-center p-4 align-middle">
          <PendingProgress expectedDuration={102_000} />
        </div>
      ) : (
        <>
          <Grid
            p="4"
            width="100%"
            height="100%"
            justify="center"
            align="center"
            className="min-h-0 min-w-0 max-w-fit grid-rows-[1fr_auto] place-self-center"
          >
            <div className="relative flex size-full max-h-full min-h-0 min-w-0 max-w-full place-self-center overflow-hidden rounded-t-lg bg-puntt-neutral-gray-8">
              {/* eslint-disable jsx-a11y/media-has-caption  */}
              <video
                ref={videoRef}
                src={videoUrl}
                className="mx-auto max-h-full min-h-0 w-max min-w-0 max-w-full cursor-pointer place-self-center bg-puntt-neutral-gray-8 object-contain"
                onTimeUpdate={handleProgress}
                onClick={togglePlayPause}
                style={
                  videoRef.current?.videoHeight
                    ? {
                        height: videoRef.current?.videoHeight + "px",
                        aspectRatio:
                          videoRef.current?.videoWidth /
                          videoRef.current?.videoHeight,
                      }
                    : {}
                }
              />
              <div
                className={cx(
                  "pointer-events-none absolute left-1/2 top-1/2 size-fit -translate-x-1/2 -translate-y-1/2 rounded-full bg-puntt-neutral-gray-10/75 p-2 transition-opacity",
                  {
                    "opacity-0": isPlaying,
                  },
                )}
              >
                <Play className="text-base-white" size={32} />
              </div>
            </div>
            <div className="w-full min-w-[22rem]">
              <Flex className="flex-none">
                <Slider
                  variant="classic"
                  size="1"
                  min={0}
                  max={100}
                  step={0.1}
                  value={progress}
                  onValueChange={handleSeek}
                  className="z-[1] cursor-pointer [--cursor-slider-thumb:pointer] [--radius-factor:0]"
                />
              </Flex>
              <div className="flex-none rounded-b-lg bg-base-white">
                <div className="relative h-9" ref={commentTrackRef}>
                  {sortedComments.map((comment) => (
                    <CommentPin
                      // @ts-expect-error TS2339: _id should exist here
                      key={comment._id}
                      // @ts-expect-error TS2719: these properties should exist
                      // here
                      comment={comment}
                      duration={duration}
                      trackWidth={commentTrackRef.current?.offsetWidth}
                      onClick={(time) =>
                        // @ts-expect-error TS2339: _id should exist here
                        handleCommentClicked(time, comment._id)
                      }
                    />
                  ))}
                </div>
                <Flex
                  align="center"
                  justify="between"
                  className="flex-none border-t border-t-puntt-neutral-gray-5 p-2"
                >
                  <Flex className="items-center gap-x-2">
                    <IconButton
                      variant="ghost"
                      size="1"
                      onClick={togglePlayPause}
                      aria-label={isPlaying ? "Pause video" : "Play video"}
                    >
                      {!isPlaying ? (
                        <Play className="text-puntt-accent-11" />
                      ) : (
                        <Pause className="text-puntt-accent-11" />
                      )}
                    </IconButton>

                    <IconButton variant="ghost" size="1" onClick={setMute}>
                      <SpeakerSimpleHigh className="text-puntt-accent-11" />
                    </IconButton>
                    <div className={cx("relative")}>
                      <Slider
                        variant="classic"
                        size="1"
                        min={0}
                        max={1}
                        step={0.1}
                        value={volume}
                        onValueChange={handleVolumeChange}
                        className={cx("absolute -top-1 left-0 w-20", {
                          hidden: !isVolumeShowing,
                        })}
                      />
                    </div>
                  </Flex>
                  <Text size="2">
                    {formatTimeStamp(videoRef.current?.currentTime)}/
                    {formatTimeStamp(duration)}
                  </Text>
                  <IconButton
                    variant="ghost"
                    size="1"
                    onClick={toggleFullscreen}
                  >
                    <CornersOut className="text-puntt-accent-11" />
                  </IconButton>
                </Flex>
              </div>
            </div>
          </Grid>

          <div className="w-full p-2" ref={commentBoxRef}>
            <div className="grid w-full place-items-center">
              <div
                className="flex flex-col gap-2 rounded-lg bg-base-white p-4"
                data-auth-trigger="video-playback-comments"
              >
                <CommentMentions
                  value={message}
                  ref={mentionsRef}
                  onChange={setMessage}
                  placeholder="Add a comment"
                  disabled={
                    mutation.isPending ||
                    !message.trim().length ||
                    isLoading ||
                    askPunttMutation.isPending
                  }
                  inputDisabled={
                    mutation.isPending ||
                    isLoading ||
                    askPunttMutation.isPending
                  }
                  loading={mutation.isPending || isLoading}
                  onSend={async () => {
                    if (isLoading) return;
                    setIsLoading(true);

                    const selections = handleGetSelections();
                    if (selections?.find((s) => s._id == "@puntt")) {
                      return askPunttMutation
                        .mutateAsync({
                          ticketId: ticketId as string,
                          description: message,
                          isRequired: required as boolean,
                          mentions: selections
                            ? selections
                                .map((s) => s._id!)
                                .filter((s) => s && s != "@puntt")
                            : [],
                          videoStart:
                            hasTimeSet && videoStart != null
                              ? videoStart
                              : undefined,
                          boardId,
                          shapeIds: [],
                        })
                        .then((res) => {
                          dispatch(setComments([...comments, res]));
                        })
                        .catch(() => {
                          dispatch(setComments(comments));
                        })
                        .finally(() => {
                          setMessage("");
                          setIsLoading(false);
                        });
                    }
                    const payload: CreateTicketCommentRequest["body"] = {
                      description: message,
                      boardId,
                      boardType: TicketCommentBoard.REVISION,
                      isPending: userIsReviewing,
                      isRequired: required as boolean,
                      mentions: selections
                        ? selections.map((s) => s._id).filter(Boolean)
                        : [],
                      videoStart:
                        hasTimeSet && videoStart != null
                          ? videoStart
                          : undefined,
                    };

                    setMessage("");
                    setVideoStart(null);
                    mutation.mutate(
                      {
                        ticketId: ticketId as string,
                        payload,
                      },
                      {
                        onSuccess: (data) => {
                          dispatch(setComments([...comments, data]));
                        },

                        onSettled() {
                          setIsLoading(false);
                        },
                      },
                    );
                  }}
                  className="w-96"
                />
                <Flex className="items-center" justify="between">
                  <Flex align="center" gap="2">
                    <IconButton
                      variant="ghost"
                      size="1"
                      onClick={toggleTimeStampSet}
                      className={cx({
                        "bg-puntt-accent-4": hasTimeSet,
                        hidden: !message.trim().length,
                      })}
                    >
                      <Timer className="text-puntt-accent-11" />
                    </IconButton>
                    <Text
                      className={cx({
                        hidden: !message.trim().length || !hasTimeSet,
                      })}
                    >
                      {formatTimeStamp(videoStart)}
                    </Text>
                  </Flex>
                  <Text
                    as="label"
                    className={cx("text-base-black", {
                      hidden:
                        user?.role ===
                          EnterpriseProfileType.CATALYST_REQUESTER ||
                        user?.role === EnterpriseProfileType.CATALYST_CREATIVE,
                    })}
                  >
                    <Flex gap="2" className="items-center" justify="end">
                      <Checkbox
                        defaultChecked={
                          user?.role !==
                            EnterpriseProfileType.CATALYST_REQUESTER &&
                          user?.role !== EnterpriseProfileType.CATALYST_CREATIVE
                        }
                        onCheckedChange={setRequired}
                      />
                      Required
                    </Flex>
                  </Text>
                </Flex>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

VideoMode.displayName = "VideoMode";
