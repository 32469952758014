/**
 * @deprecated - use ./dialogs/ShareDialog.tsx instead
 */
import {
  type GetTicketResponse,
  type GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import { TicketCommentDisposition } from "@mg/schemas/src/commons";
import { type ShowcaseToken } from "@mg/schemas/src/prince/auth";
import { Share } from "@phosphor-icons/react";
import {
  Flex,
  Button,
  Grid,
  Text,
  TextField,
  Dialog,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";

import { AuthTooltip } from "../../../components/AuthTooltip";
import { useUI } from "../../../contexts/ui";
import { useAnalytics } from "../../../utils/analytics";
import { useAppSelector } from "../../../utils/hooks";
import { useGetAndSendUrl } from "../../../utils/queries/projects";

function analyticsPayload(
  ticket: GetTicketResponse,
  comments: GetTicketCommentsResponse | undefined,
  renderTime: number,
  user: ShowcaseToken | null,
) {
  const latestRevision = ticket.revisionBoards?.at(-1);
  const latestRevisionComments = comments?.filter(
    (c) => c.boardId === latestRevision?._id,
  );
  return {
    created_hours_ago:
      (Date.now() - new Date(ticket.createdAt).getTime()) / (1000 * 60 * 60),
    num_revisions: ticket.revisionBoards?.length,
    num_comments: comments?.length,
    num_new_comments:
      comments?.filter((c) => new Date(c.createdAt).getTime() > renderTime)
        .length || 0,
    num_latest_revision_comments: latestRevisionComments?.length || 0,
    num_latest_revision_ai_comments:
      latestRevisionComments?.filter(
        (c) => c.isAI && c.disposition === TicketCommentDisposition.DEFAULT,
      ).length || 0,
    num_latest_revision_resolved_comments:
      latestRevisionComments?.filter(
        (c) => c.disposition === TicketCommentDisposition.RESOLVED,
      ).length || 0,
    num_latest_revision_dismissed_comments:
      latestRevisionComments?.filter(
        (c) => c.disposition === TicketCommentDisposition.DISMISSED,
      ).length || 0,
    num_new_revisions:
      ticket.revisionBoards?.filter(
        (r) => new Date(r.createdAt).getTime() > renderTime,
      ).length || 0,
    latest_revision_name: latestRevision?.name,
    latest_revision_id: latestRevision?._id,
    latest_revision_number: latestRevision?.revision,
    latest_revision_file_types: latestRevision?.reviewFiles?.map((f) =>
      f.source.slice(-3),
    ),
    latest_minutes_since_revision_created:
      (Date.now() -
        new Date(latestRevision?.createdAt ?? Date.now()).getTime()) /
      (1000 * 60),
    latest_revision_created_by_current_user:
      (typeof latestRevision?.createdBy === "string"
        ? latestRevision?.createdBy
        : latestRevision?.createdBy._id) === user?.userID &&
      Boolean(user?.userID),
    isRevisionAIReviewed: latestRevision?.isAIReviewed,
    minutes_since_render: (Date.now() - renderTime) / (1000 * 60),
  };
}

export function ShareTicketDialog() {
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const renderTime = useRef(0);
  const numShares = useRef(0);
  const { value: ticket, comments } = useAppSelector((state) => state.ticket);
  const mutation = useGetAndSendUrl();
  const { notify } = useUI();
  const posthog = useAnalytics("ShareTicketDialog");
  const user = useAppSelector((state) => state.auth.value);

  useEffect(() => {
    renderTime.current = Date.now();
    numShares.current = 0;
  }, [ticket?._id]);

  const onCopyLink = () => {
    navigator.clipboard.writeText(link);
    notify({
      title: "Copied to Clipboard!",
      message: "",
    });

    if (!ticket) return;
    numShares.current += 1;
    posthog.capture("copied_share_link", {
      ...analyticsPayload(ticket, comments, renderTime.current, user),
      num_shares: numShares.current,
    });
  };
  const onSendLink = () => {
    const emails = email.split(",").map((e) => e.trim());
    mutation.mutate(
      { ticketId: ticket?._id as string, emails },
      {
        onSuccess: () => {
          setEmail("");

          notify({
            title: "Email(s) Sent!",
            message: "",
          });

          if (!ticket) return;
          numShares.current += 1;
          posthog.capture("emailed_share_link", {
            ...analyticsPayload(ticket, comments, renderTime.current, user),
            emails,
            num_shares: numShares.current,
          });
        },
      },
    );
  };

  return (
    <Dialog.Root
      onOpenChange={(open) => {
        if (open) {
          setEmail("");
          if (!ticket) return;

          mutation.mutate(
            { ticketId: ticket._id as string, emails: [] },
            {
              onSuccess: (data) => {
                setLink(data.url);
              },
            },
          );

          posthog.capture(
            "opened_share_ticket_dialog",
            analyticsPayload(ticket, comments, renderTime.current, user),
          );
        }
      }}
    >
      <AuthTooltip classNames="cursor-pointer">
        <Dialog.Trigger>
          <Button size="1">
            Share <Share />
          </Button>
        </Dialog.Trigger>
      </AuthTooltip>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Share This Ticket</Dialog.Title>
        <VisuallyHidden>
          <Dialog.Description>
            Copy or email a link to this ticket.
          </Dialog.Description>
        </VisuallyHidden>
        <div className="rt-Text rt-r-size-3 mt-2">
          <Grid gap="4">
            <Grid gap="2">
              <Text size="2" weight="medium">
                Link
              </Text>

              <Flex className="items-center" gap="4">
                <TextField.Root
                  type="text"
                  name="link"
                  id="link"
                  value={link}
                  className="w-full"
                  size="2"
                  disabled
                />
                <Button size="2" onClick={onCopyLink} variant="solid">
                  Copy Link
                </Button>
              </Flex>
            </Grid>
            <Grid gap="2">
              <Text size="2" weight="medium">
                Email(s)
              </Text>
              <div>
                <Flex className="items-center" gap="4">
                  <TextField.Root
                    size="2"
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full"
                  />
                  <Dialog.Close>
                    <Button
                      size="2"
                      variant="outline"
                      onClick={onSendLink}
                      disabled={
                        !email.split(",").every((em) => isEmail(em.trim()))
                      }
                      className="cursor-pointer disabled:cursor-not-allowed"
                    >
                      Send Link
                    </Button>
                  </Dialog.Close>
                </Flex>
                <Text color="gray" size="1">
                  Comma separated for multiple addresses.
                </Text>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="flex items-center justify-end gap-2">
          <Dialog.Close>
            <Button color="gray" variant="soft" size="2">
              Close
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
