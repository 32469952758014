import {
  type ScrapeResponse,
  scrapeResponseSchema,
} from "@mg/schemas/src/baldwin/scraper";
import {
  assistantWriteResponseSchema,
  type AssistantWriteContext,
} from "@mg/schemas/src/christo/assistant";

import { API_URL, SCRAPE_URL, LEGACY_AI_URL } from "../config/env";
import { getAuthToken, smartFetch } from "../utils/http";

//
// WRITER ASSISTANT
//

/**
 * Execute an AI Writer Assistant action, returns a Markdown string with the suggested new text content.
 */
export async function assistWrite(
  payload: AssistantWriteContext,
  signal: AbortSignal,
) {
  const json = await smartFetch(`${API_URL}/private/assistant/write`, {
    method: "POST",
    body: JSON.stringify(payload),
    signal,
  });

  const parsedData = assistantWriteResponseSchema.parse(json);
  return parsedData;
}

export async function scrapeUserByUrl(url: string): Promise<ScrapeResponse> {
  const json = await smartFetch(
    SCRAPE_URL +
      "?" +
      new URLSearchParams({
        url,
      }),
    {
      method: "GET",
    },
    true,
  );

  const parsedData = scrapeResponseSchema.parse(json);
  return parsedData;
}

export type SummarizeAppendTextFn = (s: string) => void;

export function summarizeAi(
  queries: string[],
  creativeId: string,
  appendText: SummarizeAppendTextFn,
) {
  const localToken = getAuthToken();
  if (localToken == null) {
    throw new Error(
      "Attempt to access private route without an auth token is forbidden.",
    );
  }

  const defaultHeaders: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: localToken,
  };

  const controller = new AbortController();

  const signal = controller.signal;

  fetch(
    LEGACY_AI_URL +
      "?" +
      new URLSearchParams({
        queries: JSON.stringify(queries),
        creativeId,
      }),
    { headers: defaultHeaders, method: "GET", mode: "cors", signal },
  )
    .then((response) => {
      // Get the readable stream from the response body
      const stream = response.body;
      // Get the reader from the stream
      const reader = stream?.getReader();

      if (!reader) {
        throw new Error("No reader");
      }
      // Define a function to read each chunk
      const readChunk = () => {
        // Read a chunk from the reader
        reader
          .read()
          .then(({ value, done }) => {
            // Check if the stream is done
            if (done) {
              // Log a message
              // console.log("Stream finished");
              // Return from the function
              return;
            }

            // Convert the chunk value to a string
            const chunkString = new TextDecoder().decode(value);
            // Log the chunk string
            // console.log(chunkString);
            appendText(chunkString);
            // Read the next chunk
            readChunk();
          })
          .catch((error) => {
            // Log the error
            console.error(error);
          });
      };
      // Start reading the first chunk
      readChunk();
    })
    .catch((e) => {
      if (e instanceof DOMException && e.name == "AbortError") {
        // console.log("Fetch aborted");
        // don't do anything, it's fine to abort this process
      } else {
        throw e;
      }
    });

  return controller;
}
