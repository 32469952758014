import {
  AIType,
  type GenerateBriefBody,
  generateBriefResponseSchema,
  generateCommentsResponseSchema,
  type ValidateBriefBody,
  validateBriefResponseSchema,
  type GenerateCommentsRequest,
  generatePromptResponseSchema,
  type AskPunttRequest,
  askPunttResponseSchema,
} from "@mg/schemas/src/baldwin/ai";
import {
  type CreateTicketRequest,
  createTicketResponseSchema,
  generateMoodBoardImagesResponseSchema,
  getBriefTemplatesResponseSchema,
  getTicketResponseSchema,
  type UpdateTicketBody,
  updateTicketResponseSchema,
  type CreateTicketCommentBody,
  createTicketCommentResponseSchema,
  getTicketCommentsResponseSchema,
  type UpdateTicketCommentBody,
  updateTicketCommentResponseSchema,
  addReviewerToRevisionBoardResponseSchema,
  type ReviewTicketBody,
  reviewTicketResponseSchema,
  type SendToDesignBody,
  sendToDesignResponseSchema,
  getDownloadDeliveryResponseSchema,
  suggestCreativesResponseSchema,
  updateRevisionBoardResponseSchema,
  suggestReviewersResponseSchema,
  shareTicketResponseSchema,
  getTicketsPaginatedResponseSchema,
  type CreateFolderBody,
  createFolderResponseSchema,
  getFolderTicketsPaginatedResponseSchema,
  UpdateFolderBody,
  ShareTicketBody,
  ShareTicketParams,
  downloadTicketResponseSchema,
  CombineTicketsBody,
  softDeleteRevisionBoardResponseSchema,
  AddBoardToRevisionBoardBody,
  addBoardToRevisionBoardResponseSchema,
  getRevisionStatusResponseSchema,
} from "@mg/schemas/src/christo/catalyst";
import { ticketParticipantsSchema } from "@mg/schemas/src/commons";

import { LEGACY_AI_URL, AI_URL, API_URL } from "../config/env";
import { smartFetch } from "../utils/http";
import { parseResponse } from "../utils/parseResponse";

interface QueryParams {
  [key: string]: string | string[] | number | boolean | undefined;
}

export async function getProjects() {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/paginated`,
    {
      method: "POST",
    },
  );

  const parsedData = parseResponse(getTicketsPaginatedResponseSchema, json);
  return parsedData;
}

export async function getProject(ticketId: string) {
  const urlToken = localStorage.getItem("urlToken");
  const tokenParam = urlToken ? `?t=${urlToken}` : "";
  const token = sessionStorage.getItem(`${ticketId}|token`);

  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}${tokenParam}`,
    {
      method: "GET",
    },
    false,
    { token },
  );
  localStorage.removeItem("urlToken");
  const parsedData = getTicketResponseSchema.parse(json);
  return parsedData;
}

export async function getTicketVersionStatus({
  ticketId,
  versionId,
}: {
  ticketId: string;
  versionId: string;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/revisions/${versionId}/status`,
    {
      method: "GET",
    },
  );

  const parsedData = getRevisionStatusResponseSchema.parse(json);
  return parsedData;
}

export async function createProject(payload: CreateTicketRequest["body"]) {
  const json = await smartFetch(`${API_URL}/private/catalyst/tickets`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = createTicketResponseSchema.parse(json);
  return parsedData;
}

export async function updateProject(payload: UpdateTicketBody) {
  const json = await smartFetch(`${API_URL}/private/catalyst/tickets`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });

  const parsedData = updateTicketResponseSchema.parse(json);
  return parsedData;
}

export async function getBriefTemplates() {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/brief-templates`,
    {
      method: "GET",
    },
  );

  const parsedData = getBriefTemplatesResponseSchema.parse(json);
  return parsedData;
}

export async function generateBrief(payload: GenerateBriefBody) {
  const json = await smartFetch(
    `${LEGACY_AI_URL}/?type=${AIType.TICKET_BRIEF}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true,
  );

  const parsedData = generateBriefResponseSchema.parse(json);
  return parsedData;
}

export async function validateBrief({
  payload,
}: {
  payload: ValidateBriefBody;
}) {
  const json = await smartFetch(
    `${LEGACY_AI_URL}/?type=${AIType.TICKET_VALIDATE_BRIEF}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true,
  );

  const parsedData = validateBriefResponseSchema.parse(json);
  return parsedData;
}

export async function generateMoodBoard(description: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/generate-moodboard`,
    {
      method: "POST",
      body: JSON.stringify({ description }),
    },
  );

  const parsedData = generateMoodBoardImagesResponseSchema.parse(json);
  return parsedData;
}

export async function fetchTicketComments({
  ticketId,
}: {
  ticketId: string;
  query: string;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/comments`,
    {
      method: "GET",
    },
  );

  const parsedData = getTicketCommentsResponseSchema.parse(json);
  return parsedData;
}

export async function createComment({
  ticketId,
  payload,
}: {
  ticketId: string;
  payload: CreateTicketCommentBody;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/comments`,
    {
      method: "POST",
      body: JSON.stringify({
        ...payload,
      }),
    },
  );

  const parsedData = createTicketCommentResponseSchema.parse(json);
  return parsedData;
}

export async function editComment({
  ticketId,
  messageId,
  payload,
}: {
  ticketId: string;
  messageId: string;
  payload: UpdateTicketCommentBody;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/comments/${messageId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        ...payload,
      }),
    },
  );

  const parsedData = updateTicketCommentResponseSchema.parse(json);
  return parsedData;
}

export async function deleteCommentMessage({
  ticketId,
  messageId,
}: {
  ticketId: string;
  messageId: string;
}) {
  await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/comments/${messageId}`,
    {
      method: "DELETE",
    },
  );

  return undefined;
}

export async function requestAIComment(payload: GenerateCommentsRequest) {
  const json: any = await smartFetch(
    `${AI_URL}/api/v1/reviews`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true,
  );

  let parsed: any;

  try {
    parsed = JSON.parse(json["body"] || json);
  } catch (_e) {
    parsed = json;
  }

  const parsedData = generateCommentsResponseSchema.parse(parsed);
  return parsedData;
}

export async function requestAskPunttComment(payload: AskPunttRequest) {
  const json = await smartFetch(
    `${LEGACY_AI_URL}/?type=${AIType.ASK_PUNTT}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true,
  );

  const parsedData = askPunttResponseSchema.parse(json);
  return parsedData;
}

export async function createRevision({
  ticketId,
  payload,
}: {
  ticketId: string;
  payload: AddBoardToRevisionBoardBody;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/revisions`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
  );

  const parsedData = addBoardToRevisionBoardResponseSchema.parse(json);
  return parsedData;
}

export async function updateRevision({
  ticketId,
  boardId,
  payload,
}: {
  ticketId: string;
  boardId: string;
  payload: Record<string, unknown>;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/revisions`,
    {
      method: "PUT",
      body: JSON.stringify({
        _id: boardId,
        ...payload,
      }),
    },
  );

  const parsedData = updateRevisionBoardResponseSchema.parse(json);
  return parsedData;
}

export async function deleteRevision(ticketId: string, revisionId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/revisions`,
    {
      method: "DELETE",
      body: JSON.stringify({
        boardId: revisionId,
      }),
    },
  );

  const parsedData = softDeleteRevisionBoardResponseSchema.parse(json);
  return parsedData;
}

export async function addReviewersToTicket({
  ticketId,
  payload,
}: {
  ticketId: string;
  payload: {
    reviewers: string[];
    note?: string;
  };
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/revisions/reviewer`,
    {
      method: "POST",
      body: JSON.stringify({
        ...payload,
      }),
    },
  );

  const parsedData = addReviewerToRevisionBoardResponseSchema.parse(json);
  return parsedData;
}

export async function approveTicket({ ticketId }: { ticketId: string }) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/approve`,
    {
      method: "POST",
    },
  );

  const parsedData = updateTicketResponseSchema.parse(json);
  return parsedData;
}

export async function reviewTicket({ payload }: { payload: ReviewTicketBody }) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/review-design`,
    {
      method: "POST",
      body: JSON.stringify({
        ...payload,
      }),
    },
  );

  const parsedData = reviewTicketResponseSchema.parse(json);
  return parsedData;
}

export async function sendToDesign(
  payload: SendToDesignBody & { ticketId: string },
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${payload.ticketId}/send-to-design`,
    {
      method: "POST",
      body: JSON.stringify({
        creatives: payload.creatives,
        note: payload.note,
      }),
    },
  );

  const parsedData = sendToDesignResponseSchema.parse(json);
  return parsedData;
}

export async function getPromptFromTitle(title: string) {
  const json = await smartFetch(
    `${LEGACY_AI_URL}/?type=${AIType.TICKET_PROMPT}`,
    {
      method: "POST",
      body: JSON.stringify({ title }),
    },
    true,
  );

  const parsedData = generatePromptResponseSchema.parse(json);
  return parsedData;
}

export async function fetchDownloadableRevisionAsset({
  ticketId,
  boardId,
}: {
  ticketId: string;
  boardId: string;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/boards/${boardId}/download-delivery`,
    {
      method: "GET",
    },
  );

  const parsedData = getDownloadDeliveryResponseSchema.parse(json);
  return parsedData;
}

export async function fetchRecommendedCreatives(ticketId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/suggest-creatives`,
    {
      method: "GET",
    },
  );

  const parsedData = suggestCreativesResponseSchema.parse(json);
  return parsedData;
}

export async function fetchRecommendedReviewers(ticketId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/suggest-reviewers`,
    {
      method: "GET",
    },
  );

  const parsedData = suggestReviewersResponseSchema.parse(json);
  return parsedData;
}

export async function shareTicket({
  emails,
  ticketId,
}: {
  emails: string[];
  ticketId: string;
}) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/share`,
    {
      method: "POST",
      body: JSON.stringify({
        emails,
      }),
    },
  );

  const parsedData = shareTicketResponseSchema.parse(json);
  return parsedData;
}

export async function deleteTicket({ ticketId }: { ticketId: string }) {
  await smartFetch(`${API_URL}/private/catalyst/tickets/${ticketId}`, {
    method: "DELETE",
  });

  return undefined;
}

export async function getParticipants(ticketOrFolderId?: string) {
  let token = null;

  if (ticketOrFolderId) {
    token = sessionStorage.getItem(`${ticketOrFolderId}|token`);
  }

  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/participants`,
    {
      method: "GET",
    },
    false,
    { token },
  );

  const parsedData = parseResponse(ticketParticipantsSchema, json);
  return parsedData;
}
export async function getFoldersAndTickets(
  params: QueryParams,
  folderId?: string,
) {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined),
  );

  // Create URLSearchParams and handle arrays specially
  const searchParams = new URLSearchParams();
  Object.entries(filteredParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // For arrays, add multiple entries with the same key
      value.forEach((item) => searchParams.append(key, String(item)));
    } else {
      searchParams.append(key, String(value));
    }
  });

  const queryString = searchParams.toString();
  const urlToken = localStorage.getItem("urlToken");

  const tokenParam = urlToken ? `t=${urlToken}` : "";

  const separator = queryString ? "&" : "?";
  const tokenQueryString = tokenParam ? `${separator}${tokenParam}` : "";

  const url =
    folderId != null
      ? `${API_URL}/private/catalyst/folders/${folderId}?${queryString}${tokenQueryString}`
      : `${API_URL}/private/catalyst/folders?${queryString}`;

  const json = await smartFetch(
    url,
    {
      method: "GET",
    },
    false,
    { token: sessionStorage.getItem(`${folderId}|token`) },
  );
  localStorage.removeItem("urlToken");
  const parsedData = getFolderTicketsPaginatedResponseSchema.parse(json);
  return parsedData;
}

export async function createFolder(payload: CreateFolderBody) {
  const json = await smartFetch(`${API_URL}/private/catalyst/folders`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = createFolderResponseSchema.parse(json);
  return parsedData;
}

export async function deleteFolder(folderId: string) {
  await smartFetch(`${API_URL}/private/catalyst/folders/${folderId}`, {
    method: "DELETE",
  });

  return undefined;
}

export async function updateFolder(
  payload: UpdateFolderBody & { _id: string },
) {
  const { _id, ...rest } = payload;
  await smartFetch(`${API_URL}/private/catalyst/folders/${_id}`, {
    method: "PUT",
    body: JSON.stringify(rest),
  });

  return undefined;
}

export async function shareTicket2(
  payload: ShareTicketBody & ShareTicketParams,
) {
  const { ticketId, emails, version } = payload;

  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/share`,
    {
      method: "POST",
      body: JSON.stringify({
        emails,
        version:
          version === "all" || version === "new" ? version : Number(version),
      }),
    },
  );

  const parsedData = parseResponse(shareTicketResponseSchema, json);
  return parsedData;
}

export async function getDownloadTicketLink(ticketId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/tickets/${ticketId}/download`,
    {
      method: "GET",
    },
  );

  const parsedData = parseResponse(downloadTicketResponseSchema, json);
  return parsedData;
}

export async function combineTickets({
  payload,
}: {
  payload: CombineTicketsBody;
}) {
  const json = await smartFetch(`${API_URL}/private/catalyst/tickets/combine`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return json;
}

// @ts-expect-error TS2339: `window` is strictly typed
window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
  ticketApis: {
    getFoldersAndTickets,
    getTicket: getProject,
    getTicketVersionStatus,
    getDownloadTicketLink,
    createTicket: createProject,
    updateTicket: updateProject,
    shareTicket: shareTicket2,
    deleteTicket,
    getComments: fetchTicketComments,
    createComment,
    updateComment: editComment,
    deleteCommentMessage,
    requestAIComment,
    requestAskPunttComment,
    createRevision,
    updateRevision,
    deleteRevision,
    getParticipants,
    createFolder,
    updateFolder,
    deleteFolder,
    combineTickets,
  },
});
