import {
  MagnifyingGlassPlus,
  MagnifyingGlassMinus,
  ArrowsIn,
  FrameCorners,
  Selection,
} from "@phosphor-icons/react";
import { DropdownMenu } from "@radix-ui/themes";
import cx from "classnames";
import { useState } from "react";
import { useEditor, useValue } from "tldraw";

export function ZoomMenuContent() {
  const editor = useEditor();
  const zoom = useValue("zoom level", () => editor.getZoomLevel(), [editor]);
  const isZoom100 = Math.abs(zoom - 1) < 0.01;

  const [lastFitZoom, setLastFitZoom] = useState<number | null>(null);

  const handleZoomIn = (e: Event) => {
    e.preventDefault();
    editor.zoomIn();
  };

  const handleZoomOut = (e: Event) => {
    e.preventDefault();
    editor.zoomOut();
  };

  const handleResetZoom = (e: Event) => {
    e.preventDefault();
    editor.resetZoom();
  };

  const handleZoomToFit = (e: Event) => {
    e.preventDefault();
    editor.zoomToFit();
    setLastFitZoom(editor.getZoomLevel());
  };

  const handleZoomToSelection = (e: Event) => {
    e.preventDefault();
    editor.zoomToSelection();
  };

  const isZoomToFit = Boolean(
    lastFitZoom && Math.abs(zoom - lastFitZoom) < 0.01,
  );

  return (
    <>
      <DropdownMenu.Item
        className="tlui-menu__item hover:bg-gray-100 flex items-center gap-2 rounded-lg px-3 py-1.5 hover:text-puntt-cool-gray-dark-1"
        onSelect={handleZoomIn}
      >
        <MagnifyingGlassPlus className="size-4" />
        <span className="!text-xs">Zoom In</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        className="tlui-menu__item hover:bg-gray-100 flex items-center gap-2 rounded-lg px-3 py-1.5 hover:text-puntt-cool-gray-dark-1"
        onSelect={handleZoomOut}
      >
        <MagnifyingGlassMinus className="size-4" />
        <span className="!text-xs">Zoom Out</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        className={cx(
          "tlui-menu__item hover:bg-gray-100 flex items-center gap-2 rounded-lg px-3 py-1.5 hover:text-puntt-cool-gray-dark-1",
          isZoom100 && "cursor-not-allowed opacity-50",
        )}
        onSelect={handleResetZoom}
        disabled={isZoom100}
      >
        <ArrowsIn className="size-4" />
        <span className="!text-xs">Zoom to 100%</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        className={cx(
          "tlui-menu__item hover:bg-gray-100 flex items-center gap-2 rounded-lg px-3 py-1.5 hover:text-puntt-cool-gray-dark-1",
          isZoomToFit ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100",
        )}
        onSelect={handleZoomToFit}
        disabled={isZoomToFit}
      >
        <FrameCorners className="size-4" />
        <span className="!text-xs">Zoom to Fit</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        className="tlui-menu__item hover:bg-gray-100 flex items-center gap-2 rounded-lg px-2 py-1 hover:text-puntt-cool-gray-dark-1"
        onSelect={handleZoomToSelection}
        disabled={!editor.getSelectedShapeIds().length}
      >
        <Selection className="size-4" />
        <span className="!text-xs">Zoom to Selection</span>
      </DropdownMenu.Item>
    </>
  );
}
