import {
  Navigate,
  Outlet,
  // eslint-disable-next-line import/named
  createRoute,
  redirect,
  useRouter,
  matchPathname,
} from "@tanstack/react-router";

import { useAuthenticationOverlay } from "../../components/AuthenticationDialog";
import { CreativeCatalystLayout } from "../../components/layouts/catalyst";
import PendingComponent from "../../components/PendingComponent";
import { BASEPATH } from "../../config/env";
import { getFolderTree } from "../../services/folders";
import { useAppSelector } from "../../utils/hooks";
import { useLists } from "../../utils/queries/lists";
import { logout } from "../../utils/slices/auth";
import { store } from "../../utils/store";
import { addPasswordRoute } from "../add-password/route";
import { listRoute } from "../list/route";
import { loginRoute } from "../login/route";
import { rootRoute } from "../root/route";
import { ticketsRoute } from "../tickets/route";
import { ticketRoute } from "../tickets/routes/ticket/route";
import { ticketCompatibilityRoute } from "../tickets/routes/ticket_compat";

export const authLayoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "authenticated",
  async loader({ context }) {
    const { value: user } = store.getState().auth;

    if (!user?.ticketId) {
      const { queryClient } = context;

      const folderTree = await queryClient.ensureQueryData({
        queryKey: ["folders", "tree"],
        queryFn: getFolderTree,
      });

      return {
        folderTree,
      };
    }

    return { folderTree: [] };
  },
  beforeLoad({ search, location }) {
    const { value: user } = store.getState().auth;

    const baseLocation = location.pathname.replace(BASEPATH, "");

    if (user == null) {
      throw redirect({
        to: loginRoute.to,
        search: {
          redirect: baseLocation,
        },
      });
    }

    const isTicketRoute = matchPathname(
      BASEPATH,
      location.pathname,
      ticketRoute,
    );
    const isTicketCompatRoute = matchPathname(
      BASEPATH,
      location.pathname,
      ticketCompatibilityRoute,
    );
    const isTicketsRoute = matchPathname(
      BASEPATH,
      location.pathname,
      ticketsRoute,
    );
    const folderTokenPresent =
      search.folderId && sessionStorage.getItem(`${search?.folderId}|token`);

    if (
      user.role == null &&
      ((user.ticketId != null &&
        !isTicketRoute &&
        !isTicketCompatRoute &&
        !folderTokenPresent) ||
        (user.folderId != null &&
          !isTicketsRoute &&
          !isTicketRoute &&
          !isTicketCompatRoute))
    ) {
      console.warn("We are logging out now...");
      store.dispatch(logout());
      throw redirect({
        to: loginRoute.to,
        search: {
          redirect: baseLocation,
        },
      });
    }
    const hasStartedOnboardingFlow = localStorage.getItem("onboarding_flow");

    if (
      !user.isEnterpriseOnboarded &&
      !user.referredBy &&
      !hasStartedOnboardingFlow
    ) {
      throw redirect({
        to: addPasswordRoute.to,
      });
    }

    if (search.redirect) {
      throw redirect({ to: search.redirect });
    }
  },
  component: AuthLayout,
  pendingComponent: PendingComponent,
});

export function logoutUrl(basepath: string): string {
  if (basepath === "/") basepath = "";
  const redirect = localStorage.getItem("redirect");
  const destination = new URL(location.origin + basepath + loginRoute.to);
  if (redirect) {
    destination.searchParams.append("redirect", redirect);
  }
  return destination.toString();
}

function AuthLayout() {
  useAuthenticationOverlay();
  useLists();
  const listId = localStorage.getItem("listPath");
  const router = useRouter();
  const user = useAppSelector((state) => state.auth.value);

  // This is called only when the user logs out
  if (user == null && router.state.location.pathname !== loginRoute.to) {
    return location.assign(logoutUrl(router.basepath));
  }

  // Hide the navigation chrome and other UI elements for the Figma plugin
  if (router.state.location.pathname.includes("tickets/figma-plugin")) {
    return <Outlet />;
  } else if (listId) {
    return (
      <Navigate to={listRoute.to} params={{ listId: listId.split("/")[2] }} />
    );
  }

  return <CreativeCatalystLayout />;
}
