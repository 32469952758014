// eslint-disable-next-line import/no-named-as-default
import posthog, { type PostHog } from "posthog-js";

import { POSTHOG_HOST, POSTHOG_KEY } from "./env";

export const posthogClient = posthog.init(POSTHOG_KEY, {
  api_host: POSTHOG_HOST,
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      if (
        element instanceof HTMLInputElement &&
        // Mask password fields and the text version when toggling password visibility
        (element.type === "password" ||
          element.name === "password" ||
          element.id === "password" ||
          element.id === "password-input")
      ) {
        return "*".repeat(text.length);
      }
      return text;
    },
  },
}) as PostHog;
