import {
  // eslint-disable-next-line import/named
  createRoute,
  redirect,
} from "@tanstack/react-router";
import { isMongoId } from "validator";
import { z } from "zod";

import { TicketsError } from "./components/TicketsError";
import { Tickets } from "./view";

import { getFolderBreadcrumbs } from "../../services/folders";
import { getFoldersAndTickets, getParticipants } from "../../services/projects";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
} from "../../utils/auth";
import { logout } from "../../utils/slices/auth";
import { mergePunttProjectState } from "../../utils/slices/punttProjects";
import { setVisibleCommentShapes } from "../../utils/slices/ticket";
import { setDrawerOpen } from "../../utils/slices/ui";
import { store } from "../../utils/store";
import { authLayoutRoute } from "../auth-layout/route";
import { listsRoute } from "../lists/route";
import { loginRoute } from "../login/route";
import { myNetworkRoute } from "../network/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

// Define the search params type explicitly because TS is not inferring it from validateSearch
type TicketsSearchParams = {
  view?: "list" | "grid";
  folderId?: string;
  pageSize?: number;
  participants?: string[];
  ticketTitle?: string;
};

export const ticketsRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tickets",
  validateSearch(rawSearchParams): TicketsSearchParams {
    const defaultView = localStorage.getItem(
      "layout",
    ) as TicketsSearchParams["view"];

    const searchParser = z.object({
      view: z.enum(["list", "grid"]).default(defaultView ?? "grid"),
      folderId: z
        .string()
        .optional()
        .refine((val) => (val != null ? isMongoId(val) : true)),
      pageSize: z.coerce.number().default(100),
      // preprocess is nice to remove empty arrays from the URL bar, but
      // `validateSearch` is not executed on every route invalidation
      participants: z.preprocess((arg) => {
        if (Array.isArray(arg) && arg.length === 0) {
          return undefined;
        }

        return arg;
      }, z.array(z.string()).optional()),
      ticketTitle: z.preprocess((arg) => {
        if (arg == null || arg === "") {
          return undefined;
        }

        return arg;
      }, z.string().optional()),
    });

    return searchParser.parse(rawSearchParams);
  },
  beforeLoad({ location, search }) {
    const user = store.getState().auth.value;

    if (
      user != null &&
      user.role == null &&
      "folderId" in user &&
      user.folderId &&
      !(search as TicketsSearchParams).folderId
    ) {
      store.dispatch(logout());
      throw redirect({
        to: loginRoute.to,
        search: {
          redirect: location.pathname,
        },
      });
    }

    if (!canAccessPuntt()) {
      if (canAccessCreativeConnect()) {
        // @ts-expect-error TS2345: incorrect typing search params
        throw redirect({
          to: myNetworkRoute.to,
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }
    }

    store.dispatch(setVisibleCommentShapes([]));
  },
  loaderDeps({ search }) {
    const { folderId, pageSize, participants, ticketTitle } =
      search as TicketsSearchParams;

    return {
      folderId,
      pageSize,
      participants,
      ticketTitle,
    };
  },
  async loader({ context, deps }) {
    const { punttProjects } = store.getState();
    const initialTicketsAndFolders = {
      folders: punttProjects.folders,
      tickets: punttProjects.tickets,
      more: punttProjects.more,
    };
    const { queryClient } = context;
    const { folderId, pageSize, participants, ticketTitle } =
      deps as TicketsSearchParams;

    const params = { pageSize, participants, query: ticketTitle };

    const [breadcrumbsJson, ticketsJson, participantsJson] = await Promise.all([
      queryClient.fetchQuery({
        queryKey: ["breadcrumbs", folderId],
        queryFn: () => getFolderBreadcrumbs(folderId),
      }),
      queryClient.fetchQuery({
        queryKey: [
          "folders-and-tickets",
          pageSize,
          JSON.stringify(participants),
          ticketTitle,
        ],
        queryFn: () => getFoldersAndTickets(params, folderId),
        initialData: initialTicketsAndFolders,
      }),
      queryClient.ensureQueryData({
        queryKey: ["participants"],
        queryFn: () => getParticipants(folderId),
      }),
    ]);

    store.dispatch(
      mergePunttProjectState({
        breadcrumbs: breadcrumbsJson,
        folders: ticketsJson.folders ?? [],
        more: ticketsJson.more ?? false,
        participants: participantsJson ?? [],
        tickets: ticketsJson.tickets,
      }),
    );

    return {};
  },
  onEnter() {
    store.dispatch(setDrawerOpen(true));
  },
  gcTime: 0,
  shouldReload: false,
  pendingMs: 0,
  pendingMinMs: 500,
  component: Tickets,
  errorComponent: TicketsError,
});
