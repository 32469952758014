import { Text } from "@radix-ui/themes";
import cx from "classnames";
import { type ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type StickyHeaderProps = {
  /**
   * An optional action shown in the header (e.g. "Finish
   * review" action)
   */
  action?: ReactNode;
  /**
   * Optional className override
   */
  className?: string;
  /**
   * Self-explanatory
   */
  label: string;
};

export function StickyHeader(props: StickyHeaderProps) {
  const { action, className = "", label } = props;

  return (
    <header
      className={twMerge(
        cx(
          "sticky top-0 z-[1] flex items-center justify-between border-b border-b-puntt-neutral-gray-6 bg-puntt-neutral-gray-3 px-4 py-2",
          className,
        ),
      )}
    >
      <Text
        size="1"
        weight="medium"
        className="max-w-full hyphens-auto whitespace-normal break-words"
      >
        {label}
      </Text>

      {action}
    </header>
  );
}
