import {
  type CreatePasswordBody,
  createPasswordResponseSchema,
  type LoginBody,
  loginResponseSchema,
  logoutResponseSchema,
  switchEnterpriseResponseSchema,
  type VerifyMfaBody,
  VerifyMfaResponseSchema,
  verifyUserExistResponseSchema,
  type VerifyUserExistsBody,
  workosLoginResponseSchema,
} from "@mg/schemas/src/prince/auth";

import { AUTH_URL } from "../config/env";
import { handleApiError, smartFetch } from "../utils/http";

/**
 * Given an email and a password, returns a token with the authenticated
 * user information. If no user is found, a 404 error is thrown.
 *
 * Note: the token returned should be decoded into an object and stored on the
 * User slice
 */
export async function loginWithEmail(payload: LoginBody) {
  const json = await smartFetch(`${AUTH_URL}/login`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = loginResponseSchema.parse(json);
  return parsedData;
}

/**
 * Given an email and a password, returns a token with the authenticated
 * user information. If no user is found, a 404 error is thrown.
 *
 * Note: the token returned should be decoded into an object and stored on the
 * User slice
 */

export async function workOsLogin(payload: LoginBody) {
  try {
    const response = await smartFetch(`${AUTH_URL}/login-workos`, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    const parsedData = workosLoginResponseSchema.parse(response);
    return parsedData;
  } catch (e) {
    await handleApiError(e);
  }
}

export async function verifyMfa(payload: VerifyMfaBody) {
  try {
    const response = await smartFetch(`${AUTH_URL}/workos-mfa`, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    const parsedData = VerifyMfaResponseSchema.parse(response);
    return parsedData;
  } catch (e) {
    await handleApiError(e);
  }
}

/**
 * Given an email, invokes an API method that verifies if a user exists in db
 */

export async function verifyUserExists(payload: VerifyUserExistsBody) {
  try {
    const response = await smartFetch(`${AUTH_URL}/verify-user`, {
      method: "POST",
      body: JSON.stringify({ email: payload.email, state: payload.state }),
    });
    const parsed = verifyUserExistResponseSchema.parse(response);
    return parsed;
  } catch (e) {
    await handleApiError(e);
  }
}

/**
 * Given an email, invokes an API method that sends an email to the provided
 * address, and returns nothing. If the email is not found, no error is
 * returned as an effort to retain some security.
 */
export async function sendPasswordResetEmail(email: string) {
  await smartFetch(`${AUTH_URL}/reset-password`, {
    method: "POST",
    body: JSON.stringify({ email }),
  });

  return undefined;
}

/**
 * Given a password and a token, submits the updated password to the API and
 * returns a new token.
 *
 * Note: this endpoint should be used to update a user's forgotten password, or
 * to set a new password for a new user.
 */
export async function updatePassword(
  payload: CreatePasswordBody & UpdatePasswordPayload,
) {
  const json = await smartFetch(`${AUTH_URL}/create-password`, {
    method: "POST",
    body: JSON.stringify({ password: payload.password }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const parsedData = createPasswordResponseSchema.parse(json);
  return parsedData;
}

export type UpdatePasswordPayload = {
  token: string;
};

type CreateAccountPayload = {
  name: string;
  email: string;
  password: string;
};
/**
 * Given a name, email, and password, creates a new user and logs them in
 * automatically.
 *
 * Note: the token returned should be decoded into an object and stored on the
 * User slice
 */
export async function createAccount(payload: CreateAccountPayload) {
  try {
    const json = await smartFetch(`${AUTH_URL}/guest-account`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const parsedData = workosLoginResponseSchema.parse(json);
    return parsedData;
  } catch (e) {
    await handleApiError(e);
  }
}

/**
 * Given a password and a token, submits the updated password to the API and
 * returns a new token.
 *
 * Note: this endpoint should be used to update a user's forgotten password, or
 * to set a new password for a new user.
 */
export async function resetWorkosPassword(
  payload: CreatePasswordBody & UpdatePasswordPayload,
) {
  try {
    const json = await smartFetch(`${AUTH_URL}/reset-workos-password`, {
      method: "POST",
      body: JSON.stringify({
        password: payload.password,
        token: payload.token,
      }),
    });

    const parsedData = createPasswordResponseSchema.parse(json);
    return parsedData;
  } catch (e) {
    await handleApiError(e);
  }
}

export async function enrollFactor(email: string) {
  try {
    const response = await smartFetch(`${AUTH_URL}/enroll-factor`, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
    return response;
  } catch (e) {
    await handleApiError(e);
  }
}

export async function deleteFactor() {
  try {
    const response = await smartFetch(`${AUTH_URL}/reset-mfa`, {
      method: "DELETE",
    });
    return response;
  } catch (e) {
    await handleApiError(e);
  }
}

export async function switchEnterprise(enterpriseId: string) {
  const json = await smartFetch(`${AUTH_URL}/enterprise/switch`, {
    method: "POST",
    body: JSON.stringify({ enterpriseId }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const parsedData = switchEnterpriseResponseSchema.parse(json);
  return parsedData;
}

export async function logout() {
  const json = await smartFetch(`${AUTH_URL}/logout`, {
    method: "POST",
    keepalive: true,
    credentials: "include",
  });

  const parsedData = logoutResponseSchema.parse(json);
  return parsedData;
}

// @ts-expect-error TS2339: `window` is strictly typed
window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
  authApis: {
    login: workOsLogin,
    signup: createAccount,
    logout,
    sendPasswordResetEmail,
    resetPassword: resetWorkosPassword,
    updatePassword,
    enrollFactor,
    deleteFactor,
    verifyMfa,
    verifyUserExists,
    switchEnterprise,
  },
});
