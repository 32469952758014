import { type FolderTree } from "@mg/schemas/src/commons";
import {
  ArrowLineLeft,
  CaretRight,
  Kanban,
  CaretDown,
} from "@phosphor-icons/react";
import * as Accordion from "@radix-ui/react-accordion";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Link,
  ScrollArea,
  Text,
} from "@radix-ui/themes";
import {
  // eslint-disable-next-line import/named
  Link as NavLink,
  useLoaderData,
  useSearch,
} from "@tanstack/react-router";
import cx from "classnames";
import { useMemo, useState } from "react";

import { CreateFolderDialog } from "./dialogs/CreateFolderDialog";

import { authLayoutRoute } from "../routes/auth-layout/route";
import { ticketsRoute } from "../routes/tickets/route";
import { useAppSelector } from "../utils/hooks";
import {
  useEnterprises,
  useSwitchEnterprise,
} from "../utils/queries/enterprises";

type AppDrawerProps = {
  open: boolean;
  onClose(): void;
};

export function AppDrawer(props: AppDrawerProps) {
  const { open, onClose } = props;
  const { data: enterprises } = useEnterprises();
  const switchEnterpriseMutation = useSwitchEnterprise();
  const enterpriseId = useAppSelector(
    (state) => state.auth.value?.enterpriseId,
  );

  const currentEnterprise = enterprises?.find((e) => e._id === enterpriseId);

  return (
    <aside
      className={cx(
        "fixed left-0 z-[4] h-screen w-80 bg-base-white shadow-lg transition-transform",
        {
          "-translate-x-80": !open,
        },
      )}
    >
      <header className="flex items-center justify-between border-b border-b-puntt-neutral-gray-6 p-4">
        <IconButton size="1" variant="soft" color="gray" onClick={onClose}>
          <ArrowLineLeft />
        </IconButton>

        {!enterprises?.length ? null : enterprises?.length === 1 ? (
          <Text size="2" weight="medium" className="ml-2">
            {currentEnterprise?.name}
          </Text>
        ) : (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <Button
                variant="soft"
                color="gray"
                className="ml-2"
                loading={switchEnterpriseMutation.isPending}
              >
                <Text
                  size="2"
                  weight="medium"
                  className="max-w-[180px] truncate"
                >
                  {currentEnterprise?.name || "Select Enterprise"}
                </Text>
                <CaretDown className="ml-1" />
              </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="min-w-[220px] rounded-md bg-base-white p-1 shadow-lg"
                sideOffset={5}
              >
                {enterprises
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((enterprise) => (
                    <DropdownMenu.Item
                      key={enterprise._id}
                      data-id={enterprise._id}
                      className={cx(
                        "flex items-center rounded-sm px-2 py-1.5 outline-none",
                        "hover:bg-puntt-neutral-gray-4 focus:bg-puntt-neutral-gray-4",
                        {
                          "bg-puntt-neutral-gray-2":
                            enterprise._id === enterpriseId,
                          "cursor-pointer": enterprise._id !== enterpriseId,
                        },
                      )}
                      onClick={() => {
                        if (enterprise._id !== enterpriseId && enterprise._id) {
                          switchEnterpriseMutation.mutate(enterprise._id, {
                            onSuccess: () => {
                              location.reload();
                            },
                          });
                        }
                      }}
                    >
                      <Text size="2">{enterprise.name}</Text>
                    </DropdownMenu.Item>
                  ))}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}
      </header>

      <FolderTreeView />
    </aside>
  );
}

function FolderTreeView() {
  const { folderId } = useSearch({ strict: false });
  const { folderTree } = useLoaderData({ from: authLayoutRoute.id });

  const sortedFolderTree = useMemo(() => {
    return folderTree.sort((a, b) => a.name.localeCompare(b.name));
  }, [folderTree]);

  const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);

  function renderSidebarHeader() {
    return (
      <Box p="1">
        <Link asChild color="gray">
          <NavLink
            to={ticketsRoute.to}
            search={{}}
            data-auth-trigger="projects-sidebar-link"
          >
            <Flex
              gap="3"
              align="center"
              py="3"
              px="4"
              className={cx("rounded-md hover:bg-puntt-neutral-gray-4", {
                "bg-puntt-neutral-gray-4": folderId == null,
              })}
            >
              <Kanban />
              Projects
            </Flex>
          </NavLink>
        </Link>
      </Box>
    );
  }

  function renderTree() {
    if (!sortedFolderTree.length) {
      return (
        <Grid gap="4">
          {renderSidebarHeader()}

          <Button
            size="2"
            variant="outline"
            color="gray"
            mx="auto"
            className="max-w-max"
            onClick={() => setCreateFolderDialogOpen(true)}
          >
            Add Your First Project
          </Button>
        </Grid>
      );
    }

    return (
      <>
        {renderSidebarHeader()}
        <ScrollArea
          type="auto"
          scrollbars="both"
          className="h-[calc(100vh_-_114px)]"
        >
          {sortedFolderTree.map((folder) => (
            <Folder key={folder._id} {...folder} />
          ))}
        </ScrollArea>
      </>
    );
  }

  return (
    <>
      <CreateFolderDialog
        open={createFolderDialogOpen}
        onOpenChange={setCreateFolderDialogOpen}
        folderId={folderId}
      />
      {renderTree()}
    </>
  );
}

function Folder(props: FolderTree) {
  const { folderId } = useSearch({ strict: false });
  const { _id, name, folders = [] } = props;

  return (
    <Accordion.Root type="single" collapsible>
      <Accordion.Item value={_id as string}>
        <Accordion.Header className="my-1 ml-4 mr-1 flex items-center gap-4 rounded-md px-2 py-1 hover:bg-puntt-neutral-gray-4">
          <Accordion.Trigger
            asChild
            className={cx("group", { "opacity-0": !folders.length })}
          >
            <IconButton size="1" variant="ghost">
              <CaretRight
                className="transition-transform group-data-[state=open]:rotate-90"
                color="rgb(var(--puntt-neutral-gray-11))"
              />
            </IconButton>
          </Accordion.Trigger>
          <Link
            color={folderId === _id ? "blue" : "gray"}
            asChild
            className="max-w-[236px] truncate"
          >
            <NavLink
              to={ticketsRoute.to}
              search={(prev) => ({ ...prev, folderId: _id })}
            >
              {name}
            </NavLink>
          </Link>
        </Accordion.Header>

        <Accordion.Content className="ml-4">
          {folders.map((folder) => (
            <Folder key={folder._id} {...folder} />
          ))}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
