import { Icon, Typography } from "@mg/dali/src";
import {
  type TLBaseShape,
  BaseBoxShapeUtil,
  HTMLContainer,
  BaseBoxShapeTool,
  type JsonObject,
} from "@tldraw/tldraw";

import { downloadAsset } from "../../../utils/http";

// [1]
export type LinkShapeType = TLBaseShape<
  "external",
  {
    w: number;
    h: number;
    label: string;
    href: string;
  }
>;

export class LinkShapeUtil extends BaseBoxShapeUtil<LinkShapeType> {
  static override type = "external" as const;

  override getDefaultProps() {
    return {
      type: "external",
      w: 300,
      h: 250,
      href: "",
      label: "",
    };
  }

  override canEdit = () => true;

  override component(shape: LinkShapeType) {
    const fileType = (shape?.meta?.href as string)?.split(".").pop();

    return (
      <HTMLContainer
        style={{
          width: shape.props.w,
          height: shape.props.h,
          borderRadius: 8,
          backgroundColor: "white",
          padding: 12,
          border: "1px solid rgba(17, 24, 39, 0.13)",
        }}
      >
        <>
          <div className="mb-4 grid h-[100px] w-full place-content-center place-items-center bg-carbon-50">
            <Icon.File size={40} />
            <Typography size="2xl" className="text-carbon-800">
              {fileType?.toUpperCase()}
            </Typography>
          </div>
          <div
            className="flex items-center justify-between"
            style={{ pointerEvents: "all" }}
          >
            <Typography size="3xl" className="w-4/5 truncate text-base-black">
              {shape.meta.label as string}
            </Typography>
            <button
              onPointerDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                downloadAsset(
                  shape.meta.href as string,
                  (shape.meta.label ??
                    (shape.meta.file as JsonObject)?.name ??
                    (shape.meta.href as string)?.split("/").pop()) as string,
                  "downloaded_link_shape",
                );
              }}
            >
              <Icon.DownloadSimple size={40} />
            </button>
          </div>
          <Typography size="3xl" className="text-carbon-600" weight="bold">
            {fileType?.toUpperCase()}
          </Typography>
        </>
      </HTMLContainer>
    );
  }

  indicator(shape: LinkShapeType) {
    let height = shape.props.h;
    const shapeDefaultRatio = shape.meta.ratio as number;
    const currentShapeRatio = shape.props.w / shape.props.h;
    if (shapeDefaultRatio && shapeDefaultRatio !== currentShapeRatio) {
      height = shape.props.w / shapeDefaultRatio;
      this.editor.updateShape({
        ...shape,
        props: {
          ...shape.props,
          h: height,
        },
      });
    }

    return (
      <rect
        stroke="rgb(var(--puntt-blue-7))"
        width={shape.props.w}
        height={height}
      />
    );
  }
}

export class LinkShapeTool extends BaseBoxShapeTool {
  static override id = "external";
  static override initial = "idle";
  override shapeType = "external";
}
