import {
  baseTicketCommentSchema,
  type BaseTicketComment,
  type CreateTicketCommentRequest,
} from "@mg/schemas/src/christo/catalyst";
import {
  EnterpriseProfileType,
  TicketCommentBoard,
  TicketCommentDisposition,
} from "@mg/schemas/src/commons";
import { X } from "@phosphor-icons/react";
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Select,
  Text,
} from "@radix-ui/themes";
import cx from "classnames";
import { useEffect, useRef, useState, useMemo } from "react";
import {
  HTMLContainer,
  Rectangle2d,
  ShapeUtil,
  type RecordProps,
  type TLBaseShape,
  T,
  stopEventPropagation,
  type TLShapeId,
} from "tldraw";

import { useUI } from "../../../../contexts/ui";
import { useAnalytics } from "../../../../utils/analytics";
import { isNil } from "../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  useCreateAskPunttComment,
  useNewCommentMutation,
} from "../../../../utils/queries/projects";
import { getAiRuleMap } from "../../../../utils/selections";
import {
  setActiveCommentId,
  setComments,
  setVisibleCommentShapes,
} from "../../../../utils/slices/ticket";
import {
  isCommentShapeVisible,
  useGetVisibleUsers,
} from "../../../../utils/tldraw/comments";
import { Comment } from "../../routes/components/comment-drawer/Comment";
import {
  CommentMentions,
  type Selections,
} from "../CommentMentions/CommentMentions";

export type CommentShape = TLBaseShape<
  "comment",
  {
    w: number;
    h: number;
    commentId: unknown;
    isLocked?: boolean;
  }
>;

export class CommentShapeUtil extends ShapeUtil<CommentShape> {
  static override type = "comment" as const;

  static override props: RecordProps<CommentShape> = {
    w: T.number,
    h: T.number,
    commentId: T.unknown,
    isLocked: T.boolean.optional(),
  };

  override isAspectRatioLocked = (_shape: CommentShape) => false;
  override canResize = (_shape: CommentShape) => false;
  override hideRotateHandle = (_shape: CommentShape) => true;
  override canBind = () => true;
  override canEdit = () => true;

  getDefaultProps(): CommentShape["props"] {
    return {
      w: 0,
      h: 0,
      commentId: null,
      isLocked: false,
    };
  }

  private isVisible(shape: CommentShape, userId?: string) {
    return isCommentShapeVisible(shape, userId);
  }

  getGeometry(shape: CommentShape) {
    const zoomLevel = this.editor.getZoomLevel();

    return new Rectangle2d({
      width: shape.props.w / zoomLevel,
      height: shape.props.h / zoomLevel,
      isFilled: true,
    });
  }

  component = (shape: CommentShape) => {
    const zoomLevel = this.editor.getZoomLevel();
    const selectedShapeIds = this.editor.getSelectedShapeIds();
    const users = useGetVisibleUsers();
    const aiRuleMap = getAiRuleMap();
    const { notify } = useUI();

    // Slices
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.value);
    const ticket = useAppSelector((state) => state.ticket.value);
    const versions = useAppSelector((state) => state.ticket.revisions);
    const reversedVersionIndex = useAppSelector(
      (state) => state.ticket.reversedVersionIndex,
    );
    const selectedVersion = versions[reversedVersionIndex!];
    const comments = useAppSelector((state) => state.ticket.comments);
    const visibleCommentShapes = useAppSelector(
      (state) => state.ticket.visibleCommentShapes,
    );

    // Queries and mutations
    const createCommentMutation = useNewCommentMutation();
    const askPunttMutation = useCreateAskPunttComment();

    // Analytics
    const posthog = useAnalytics("CommentTool");

    // Local state
    const mentionsRef = useRef<{
      getSelections: () => Selections;
      closeMentions: () => void;
    }>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [required, setRequired] = useState<boolean | "indeterminate">(true);
    const [rule, setRule] = useState("no_rule");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string>("");
    const isSelected =
      selectedShapeIds.includes(shape.id) ||
      selectedShapeIds.includes(shape.meta?.linkedAvatarId as string);
    const boardId = selectedVersion._id;
    const userIsReviewing = comments.some(
      (c) => c.isPending && c.createdBy === user?.userID,
    );
    const comment = useMemo(() => {
      if (isNil(shape.meta) || isNil(shape.meta.comment)) return null;

      try {
        const commentObj = JSON.parse(shape.meta.comment as string);
        const comment = baseTicketCommentSchema.parse(commentObj);

        return comments.find((c) => c._id === comment._id);
      } catch {
        return null;
      }
    }, [comments, shape.meta?.comment]);

    // Effect chain

    useEffect(() => {
      const newSize = isSelected
        ? {
            w: 300,
            h: 150,
          }
        : { w: 0, h: 0 };
      // shape wont change inside of render, so must define the changes here to user it with parsed origin point
      const shapeWithSize = { ...shape, props: newSize };

      if (shape.props.w !== newSize.w) {
        this.editor.updateShape(shapeWithSize);
      }

      if (isSelected) {
        this.editor.bringToFront([
          shape.id,
          shape.meta.linkedAvatarId as TLShapeId,
        ]);
        if (!isNil(comment?._id)) {
          dispatch(setActiveCommentId(comment._id));
        }
      } else {
        if (isNil(comment)) {
          this.editor.deleteShapes([
            shape.id,
            shape.meta.linkedAvatarId as TLShapeId,
          ]);
        } else {
          dispatch(setActiveCommentId(null));
        }
      }
    }, [comment, isSelected]);

    useEffect(() => {
      if (mentionsRef.current) {
        mentionsRef.current?.closeMentions();
      }
    }, [zoomLevel]);

    useEffect(() => {
      if (!isSelected) {
        const parentShape = this.editor.getShape(
          shape.meta.linkedAvatarId as TLShapeId,
        );
        if (!parentShape) return;

        this.editor.updateShape({
          ...shape,
          props: {
            ...shape.props,
            w: 0,
            h: 0,
          },
          x: parentShape.x,
          y: parentShape.y + 25 / zoomLevel,
        });
        return;
      }

      // Detects if the comment box will open near the edge of the canvas and,
      // if so, moves the box further from the edge of the canvas.
      const observer = new ResizeObserver(() => {
        if (containerRef.current) {
          const { height: shapeHeight } =
            containerRef.current.getBoundingClientRect();
          const parentShape = this.editor.getShape(
            shape.meta.linkedAvatarId as TLShapeId,
          );
          if (!parentShape) {
            return;
          }
          let x = shape.x;
          let y = shape.y;
          const viewport = this.editor.getViewportScreenBounds();

          // 600 because the comment tool is 300px wide and we double it to take the sidebar into account
          if (x == parentShape.x && parentShape.x + 600 > viewport.width) {
            x = parentShape.x - 300 / zoomLevel;
          }
          const { originScreenPoint } = this.editor.inputs;

          if (originScreenPoint.y > viewport.height - viewport.y) {
            // we have to account for the zoom level which effects the size of the comment box
            y = parentShape.y - shapeHeight / zoomLevel;
          }

          this.editor.updateShape({
            ...shape,
            x,
            y,
          });
        }
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      // Clean up the observer when the component unmounts
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, [isSelected, shape, zoomLevel]);

    this.editor.addListener("event", (e) => {
      if (e.type === "wheel" && mentionsRef.current) {
        mentionsRef.current?.closeMentions();
      }
    });

    function handleGetSelections() {
      if (mentionsRef.current) {
        return mentionsRef.current.getSelections();
      }
    }

    if (
      shape.props.w == 0 ||
      shape.props.h == 0 ||
      !isSelected ||
      !this.isVisible(shape, user?.userID)
    ) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            width: 0,
            height: 0,
          }}
        />
      );
    }

    // Arrow functions have transient `this` context.
    const renderCommentContents = () => {
      if (!isNil(comment)) {
        return (
          <Box
            height="100%"
            overflow="auto"
            py="4"
            className="border-b"
            onPointerDown={stopEventPropagation}
          >
            <Comment
              mentions={comment.mentions as string[]}
              key={comment._id}
              author={
                comment.createdBy as Exclude<typeof comment.createdBy, string>
              }
              createdAt={comment.createdAt}
              isAI={comment.isAI}
              message={comment.description}
              messageId={comment._id}
              commentId={comment._id}
              meta={comment}
              replies={comment.messages}
              updatedAt={comment.updatedAt}
              rule={comment.rule}
              onSuccess={(data) => {
                if (data != null) {
                  if (
                    user?.role != "ai" &&
                    user?.role != "meaningful-gigs" &&
                    (data.disposition == TicketCommentDisposition.DISMISSED ||
                      data.disposition == TicketCommentDisposition.RESOLVED)
                  ) {
                    this.editor.deleteShapes([shape.id]);
                  }
                  const replaceCommentIndex = comments.findIndex(
                    (comment) => comment._id === data._id,
                  );
                  const commentsCopy = [...comments];
                  commentsCopy.splice(replaceCommentIndex, 1, data);

                  dispatch(setComments(commentsCopy));
                }
                this.editor.bringToFront([shape.id]);
                posthog.capture("added_new_comment", {
                  isAI: data.isAI,
                  comment: data,
                });
                this.editor.updateShape({
                  ...shape,
                  meta: {
                    ...shape.meta,
                    comment: JSON.stringify(data),
                  },
                  props: {
                    ...shape.props,
                    commentId: data._id,
                  },
                });
              }}
              editor={this.editor}
              users={users}
              isCanvas
            />
          </Box>
        );
      }

      return (
        <Grid gap="4" p="4" onPointerDown={stopEventPropagation}>
          <CommentMentions
            value={message}
            ref={mentionsRef}
            onChange={setMessage}
            placeholder="Add a comment"
            disabled={
              createCommentMutation.isPending ||
              !message.trim().length ||
              isLoading
            }
            loading={createCommentMutation.isPending || isLoading}
            // TODO: let's break this logic out into some into a separate
            // function so that we can keep this logic concern deal only with
            // the shape and be agnostic to how we're adding shapes to the board
            // (even though we are literally dealing within the context of
            // TLDraw). For example, the API of TLDraw may change, but the way
            // you construct shapes may stay the same, etc.
            onSend={async () => {
              if (isLoading) return;
              setIsLoading(true);

              const selections = handleGetSelections();
              const parsedOriginPoint =
                shape.meta?.originalCoords &&
                (JSON.parse(shape.meta.originalCoords as string) as {
                  x?: number;
                  y?: number;
                });

              const payload: CreateTicketCommentRequest["body"] = {
                description: message,
                x: parsedOriginPoint ? parsedOriginPoint.x : shape.x,
                y: parsedOriginPoint
                  ? (parsedOriginPoint?.y ?? 0) - 26 / zoomLevel
                  : shape.y - 26 / zoomLevel, // minus 26px is needed for the offset since we're creating the comment pin shape differently
                boardId,
                boardType: TicketCommentBoard.REVISION,
                isPending: userIsReviewing,
                isRequired: required as boolean,
                rule: !required || rule === "no_rule" ? undefined : rule,
              };
              const sisterShape = this.editor.getShape(
                shape.meta.linkedAvatarId as TLShapeId,
              );
              const onSuccess = (data: BaseTicketComment) => {
                this.editor.bringToFront([shape.id]);

                this.editor.updateShapes([
                  {
                    ...shape,
                    meta: {
                      ...shape.meta,
                      comment: JSON.stringify(data),
                    },
                    props: {
                      ...shape.props,
                      commentId: data._id,
                    },
                  },
                  // @ts-expect-error TS2322: tlshape type isn't expecting meta here
                  {
                    ...sisterShape,
                    meta: {
                      ...sisterShape?.meta,
                      comment: JSON.stringify(data),
                    },
                  },
                ]);
                const commentWithShapeId = {
                  ...data,
                  shapeId: shape.id,
                };
                dispatch(
                  setVisibleCommentShapes([
                    ...visibleCommentShapes,
                    commentWithShapeId,
                  ]),
                );

                dispatch(setComments([...comments, data]));
              };

              if (selections?.find((s) => s._id === "@puntt")) {
                const tempCommentData = {
                  ...payload,
                  _id: (Math.random() * 10000).toString(),
                  createdBy: {
                    _id: user?.userID,
                    name: user?.name ?? "Anonymous",
                    avatar: user?.avatar,
                  },
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  isDeleted: false,
                  isReview: false,
                  votes: [],
                  isAI: false,
                  mentions: [],
                  messages: [
                    {
                      createdBy: {
                        _id: "63bc994226c323d41d67a11f",
                        name: "AI Reviewer",
                        avatar:
                          "users/615f5203e1cb445ef2486b74/3-color-logo-white-bg-square.png",
                      },
                      aiPersona: {
                        name: "",
                        avatar: "",
                      },
                      description: "",
                      createdAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      isDeleted: false,
                      isPending: false,
                      isReview: false,
                      votes: [],
                      isAI: true,
                      reviewId: "67082ad6ecce06a419fe876d",
                      mentions: [],
                      messages: [],
                      x: 20,
                      y: 20,
                      isRequired: false,
                      boardType: "revisionBoard",
                      boardId: "6706ca0b6a3ca36a292610a5",
                      disposition: "default",
                      _id: (Math.random() * 100000).toString(),
                      aiPending: true,
                    },
                  ],
                  isRequired: required as boolean,
                  disposition: "default" as TicketCommentDisposition,
                };
                onSuccess(tempCommentData);
                setIsLoading(false);
                setMessage("");
                // this is where we will have the call to get the ai response and then we will update as below
                return askPunttMutation
                  .mutateAsync({
                    ticketId: ticket!._id,
                    description: message,
                    x: payload.x as number,
                    y: payload.y as number,
                    boardId,
                    shapeIds: [],
                    isRequired: required as boolean,
                  })
                  .then((res) => {
                    onSuccess(res);
                  })
                  .catch(() => {
                    dispatch(setComments(comments));
                    const deletingShapes = [shape.id];

                    if (sisterShape != null) {
                      deletingShapes.push(sisterShape.id);
                    }

                    this.editor.deleteShapes(deletingShapes);
                    notify({
                      message: "An error occurred while asking Puntt AI",
                      timeout: 8000,
                      variant: "error",
                    });
                  })
                  .finally(() => {
                    setMessage("");
                    setIsLoading(false);
                  });
              }

              if (selections?.length) {
                payload.mentions = selections.map((s) => s._id as string);
              }
              if (!isNil(comment)) {
                payload.commentId = comment._id;
              }

              setMessage("");

              createCommentMutation.mutate(
                {
                  ticketId: ticket!._id,
                  payload,
                },
                {
                  onSuccess: (data) => {
                    posthog.capture("added_new_comment", {
                      isAI: data.isAI,
                      comment: data,
                    });

                    onSuccess(data);
                  },

                  onSettled() {
                    setIsLoading(false);
                  },
                },
              );
            }}
          />

          <Text
            as="label"
            className={cx("text-base-black", {
              hidden:
                user?.role === EnterpriseProfileType.CATALYST_REQUESTER ||
                user?.role === EnterpriseProfileType.CATALYST_CREATIVE,
            })}
          >
            <Flex gap="2" className="items-center">
              <Checkbox
                defaultChecked={
                  user?.role !== EnterpriseProfileType.CATALYST_REQUESTER &&
                  user?.role !== EnterpriseProfileType.CATALYST_CREATIVE
                }
                onCheckedChange={setRequired}
              />
              Required Change
            </Flex>
          </Text>
          <Select.Root size="3" value={rule} onValueChange={setRule}>
            <Select.Trigger
              className={cx("w-full", {
                hidden:
                  (user?.role !== EnterpriseProfileType.MEANINGFUL_GIGS &&
                    user?.role !== EnterpriseProfileType.CATALYST_AI) ||
                  !required,
              })}
              variant="surface"
              color="gray"
            />
            <Select.Content>
              <Select.Item value="no_rule">No Rule</Select.Item>
              {Object.keys(aiRuleMap).map((key) => (
                <Select.Item key={key} value={key}>
                  {aiRuleMap[key]}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Grid>
      );
    };

    return (
      <HTMLContainer
        // colon is not a valid selector character
        id={shape.id.replace(":", "-")}
        style={{
          transform: `scale(${1 / zoomLevel})`,
          width: shape.props.w,
          position: "relative",
        }}
        data-testid="comment-container"
      >
        <div
          role="button"
          tabIndex={0}
          className="relative -ml-0.5 cursor-default border bg-base-white shadow-lg [pointer-events:all]"
          ref={containerRef}
        >
          <Flex className="h-6 items-center justify-end border-b border-puntt-neutral-gray-6 pr-[3px]">
            <IconButton
              size="1"
              variant="ghost"
              color="gray"
              onClick={() => {
                this.editor.selectNone();
                dispatch(setActiveCommentId(null));
              }}
              className="cursor-pointer"
              onPointerDown={stopEventPropagation}
            >
              <X size={16} />
            </IconButton>
          </Flex>

          {renderCommentContents()}
        </div>
      </HTMLContainer>
    );
  };

  indicator = (_shape: CommentShape) => null;
}
