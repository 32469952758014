import "./styles.css";
import { MagnifyingGlassPlus } from "@phosphor-icons/react";
import { DropdownMenu } from "@radix-ui/themes";
import { useEditor, useValue } from "@tldraw/tldraw";
import { ReactNode, useCallback, useState } from "react";

import { ZoomMenuContent } from "./ZoomMenuContent";
export interface TLUiZoomMenuProps {
  children?: ReactNode;
  onClick?: () => void;
}

export function PunttZoomMenu({ onClick }: TLUiZoomMenuProps) {
  const editor = useEditor();
  const [isOpen, setIsOpen] = useState(false);
  const zoom = useValue("zoom level", () => editor.getZoomLevel(), [editor]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open);
      if (!open) {
        editor.setCurrentTool("combo");
      }
    },
    [editor],
  );

  const handleTriggerClick = useCallback(() => {
    if (!isOpen) {
      onClick?.();
    }
  }, [isOpen, onClick]);

  return (
    <DropdownMenu.Root
      modal={false}
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      <DropdownMenu.Trigger>
        <div
          className="tlui-button tlui-zoom-menu__button py-8px flex w-[52px] flex-col items-center justify-center gap-1"
          onClick={handleTriggerClick}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              handleTriggerClick();
            }
          }}
          role="button"
          tabIndex={0}
          title="Zoom"
        >
          <MagnifyingGlassPlus className="size-5" />
          <span
            key={zoom}
            className="bg-white !important text-black puntt-border-gray-400 min-w-[36px] animate-fade-in rounded-full border px-2 py-0 text-center text-[10px] opacity-100"
          >
            {Math.floor(zoom * 100)}%
          </span>
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className="tlui-menu !bg-white border-gray-200 rounded-xl border bg-base-white p-2 shadow-lg [background-color:white]"
        side="top"
        align="start"
        sideOffset={6}
      >
        <ZoomMenuContent />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
