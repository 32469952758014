import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { useState, useEffect, useCallback } from "react";
// eslint-disable-next-line import/order
import {
  AssetRecordType,
  Box,
  createShapeId,
  type TLAssetId,
  type TLShapeId,
} from "@tldraw/tldraw";

import {
  MAX_IMAGE_DIMENSION,
  uploadImageAsset,
  uploadToS3,
} from "../../services/upload";
import { batch } from "../batch";
export interface PdfPage {
  src: string;
  bounds: Box;
  assetId: TLAssetId;
  shapeId: TLShapeId;
}

export interface Pdf {
  name: string;
  pages: PdfPage[];
  source: string | ArrayBuffer;
}

const DOC_FILE_EXTENSIONS = [".pdf", ".doc", ".docx", ".ppt", ".pptx"];
const pageSpacing = 32;
GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";

export const usePdfDimensions = (fileUrl: string) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
    ratio: number;
  } | null>(null);

  const fetchPdfDimensions = useCallback(async () => {
    const pdf = await getDocument(fileUrl).promise;

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });

    setDimensions({
      width: viewport.width,
      height: viewport.height,
      ratio: viewport.width / viewport.height,
    });
  }, [fileUrl]);

  useEffect(() => {
    fetchPdfDimensions();
  }, [fetchPdfDimensions]);

  return dimensions;
};

export async function loadPdf(name: string, source: ArrayBuffer): Promise<Pdf> {
  const visualScale = 1.5;
  const scale = window.devicePixelRatio;

  let top = 0;
  let widest = 0;
  const pdf = await getDocument(source.slice(0)).promise;

  async function processPage(i: number): Promise<PdfPage> {
    try {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: scale * visualScale });

      // Create a new canvas for each page
      const canvas = window.document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) throw new Error("Failed to create canvas context");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport,
      };
      await page.render(renderContext).promise;

      const width = viewport.width / scale;
      const height = viewport.height / scale;

      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error("Failed to create blob from canvas"));
          },
          "image/png",
          1.0,
        );
      });

      const nameSlug = name
        .replace(/\.\w+$/, "")
        .replace(/\W+/g, "-")
        .substring(0, 240);
      const file = new File([blob], `${nameSlug}-page-${i}.png`, {
        type: "image/png",
      });

      const payload = (await uploadImageAsset(file)) as FormData;
      const url = payload?.get("url") as string;
      const key = payload?.get("key") as string;
      payload?.delete("url");

      await uploadToS3({ url, payload });
      const originalUrl = `https://static.puntt.ai/${key}`;

      widest = Math.max(widest, width);

      return {
        src: originalUrl,
        bounds: new Box(0, 0, width, height),
        assetId: AssetRecordType.createId(),
        shapeId: createShapeId(),
      };
    } catch (error) {
      console.error(`Error processing page ${i}:`, error);
      throw error;
    }
  }

  const data = new Array(pdf.numPages).fill(0);
  const MAX_CONCURRENCY = 6;
  const pages: PdfPage[] = await batch(
    data,
    1,
    (_, i) => processPage(i + 1), // page numbers start from 1
    MAX_CONCURRENCY,
  );

  for (const page of pages) {
    page.bounds.x = (widest - page.bounds.width) / 2;
    top += page.bounds.height + pageSpacing;
    page.bounds.y = top;
  }

  return {
    name,
    pages,
    source,
  };
}

export function isDocFile(fileName?: string) {
  if (!fileName) return false;

  return DOC_FILE_EXTENSIONS.includes(
    fileName.slice(fileName.lastIndexOf(".")).toLowerCase(),
  );
}

export async function downscalePdf(file: File): Promise<File> {
  const { PDFDocument } = await import("pdf-lib");
  const pdf = await getDocument(await file.arrayBuffer()).promise;
  const pdfDoc = await PDFDocument.create();

  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale: 1 });

    // Downscale if necessary
    const scaleFactor = Math.min(
      1,
      MAX_IMAGE_DIMENSION / Math.max(viewport.width, viewport.height),
    );
    const scaledViewport = page.getViewport({ scale: scaleFactor });

    const canvas = document.createElement("canvas");
    canvas.width = scaledViewport.width;
    canvas.height = scaledViewport.height;
    const context = canvas.getContext("2d");

    await page.render({ canvasContext: context!, viewport: scaledViewport })
      .promise;

    const imgDataUrl = canvas.toDataURL("image/png");
    const imgBytes = await fetch(imgDataUrl).then((res) => res.arrayBuffer());

    const pdfImage = await pdfDoc.embedPng(imgBytes);

    const newPage = pdfDoc.addPage([
      scaledViewport.width,
      scaledViewport.height,
    ]);
    newPage.drawImage(pdfImage, {
      x: 0,
      y: 0,
      width: scaledViewport.width,
      height: scaledViewport.height,
    });
  }

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], `${file.name.slice(0, -4)}-downscaled.pdf`, {
    type: "application/pdf",
  });
}
