import { EyeClosed } from "@phosphor-icons/react";
import { Grid, Heading } from "@radix-ui/themes";
import cx from "classnames";

import { useAppSelector } from "../../../utils/hooks";

export function NoTicketsFoundView() {
  const { drawerOpen } = useAppSelector((state) => state.ui);
  return (
    <Grid
      gap="4"
      className={cx("mx-auto mt-40 max-w-prose text-center", {
        "ml-[calc(50%)] -translate-x-1/2": drawerOpen,
      })}
    >
      <EyeClosed size={48} className="mx-auto text-puntt-blue-9" />

      <Heading size="6" className="text-puntt-neutral-gray-9">
        No tickets matched your search. Please refine your filters.
      </Heading>
    </Grid>
  );
}
