import { Code, Grid, Heading, Text } from "@radix-ui/themes";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorComponent } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect } from "react";

import { useAppSelector } from "../../../utils/hooks";

export const TicketsError: typeof ErrorComponent = ({
  error,
}: {
  error: Error | Response;
}) => {
  const queryErrorResetBoundary = useQueryErrorResetBoundary();
  const { drawerOpen } = useAppSelector((state) => state.ui);
  const defaultErrorMessage =
    "Unable to retrieve tickets at this time. Please try again shortly.";

  useEffect(() => {
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  const isResponseError = (error: any): error is Response => {
    return error && typeof error === "object" && "status" in error;
  };

  const isError = (error: any): error is Error => {
    return error && typeof error === "object" && "message" in error;
  };

  const getResponseError = (error: Response) => {
    switch (error.status) {
      case 401:
        return "You are not authorized to access this folder.";
      default:
        return defaultErrorMessage;
    }
  };

  return (
    <Grid
      gap="4"
      className={cx("mx-auto mt-40 max-w-prose text-center", {
        "ml-[calc(50%+10rem)] -translate-x-1/2": drawerOpen,
      })}
    >
      <Heading color="red">Error</Heading>

      <Text color="red">An error occurred:</Text>

      {isResponseError(error) ? (
        <Code color="red">{getResponseError(error)}</Code>
      ) : isError(error) ? (
        <Code color="red">{error.message}</Code>
      ) : (
        <Code color="red">{defaultErrorMessage}</Code>
      )}
    </Grid>
  );
};
