import { z } from "zod";

import { EnterpriseProfileType } from "./user";

// User/role selection ========================================================

export enum WorkflowUserTypes {
  ENTERPRISE_ADMIN = EnterpriseProfileType.ADMIN,
  ENTERPRISE_OPS = EnterpriseProfileType.OPS,
  ENTERPRISE_MARKETING = EnterpriseProfileType.CATALYST_MARKETING,
  ENTERPRISE_CREATIVE = EnterpriseProfileType.CATALYST_CREATIVE,
}

export enum WorkflowLimitedUserTypes {
  ENTERPRISE_OPS = EnterpriseProfileType.OPS,
  ENTERPRISE_MARKETING = EnterpriseProfileType.CATALYST_MARKETING,
  ENTERPRISE_CREATIVE = EnterpriseProfileType.CATALYST_CREATIVE,
}

export const allUserTypesSchema = z.nativeEnum(WorkflowUserTypes);

export const limitableUserTypesSchema = z.nativeEnum(WorkflowLimitedUserTypes);

// Use this when admins can be deselected, e.g. when choosing who to notify
export const workflowAllUserTypesOptionsSchema = z.object({
  roles: z.array(allUserTypesSchema),
  userIds: z.array(z.string()),
});
export type WorkflowAllUserTypesOptions = z.infer<
  typeof workflowAllUserTypesOptionsSchema
>;

// Use this when admins cannot be deselected, e.g. when choosing who has access
export const workflowLimitableUserTypesOptionsSchema = z.object({
  roles: z.array(limitableUserTypesSchema),
  userIds: z.array(z.string()),
});

// Step statuses ==============================================================

export enum TicketWorkflowStep {
  BRIEF = "brief",
  MOOD_BOARD = "mood_board",
  CONCEPT_DESIGN = "concept_design",
  DESIGN_REVIEW = "review",
  DELIVERY = "delivery",
}

export const ticketWorkflowStepSchema = z.nativeEnum(TicketWorkflowStep);

export enum TicketWorkflowStepStatus {
  ASSIGN_CREATIVES = "assign_creatives",
  SENT_TO_DESIGN = "sent_to_design",
  AI_REVIEWING = "ai_reviewing",
}

export const ticketWorkflowStepStatusSchema = z.nativeEnum(
  TicketWorkflowStepStatus,
);

// Step configs ===============================================================

export enum CreativeBriefMissingDetails {
  DO_NOTHING = "do_nothing",
  WARN = "warn",
  ERROR = "error",
}

const sendToDesignSchema = z
  .object({
    canAssignCreatives: workflowLimitableUserTypesOptionsSchema,
    canBeAssigned: workflowAllUserTypesOptionsSchema,
    notifyOfRequestToAssign: workflowAllUserTypesOptionsSchema,
    autoAssignToUserIds: z.array(z.string()),
    minAssignees: z.number().min(1),
    maxAssignees: z.number().min(1).optional(),
  })
  .optional();

export type SendToDesign = z.infer<typeof sendToDesignSchema>;

const requiresApprovalSchema = z
  .object({
    canAssignReviewers: workflowLimitableUserTypesOptionsSchema,
    canBeAssigned: workflowAllUserTypesOptionsSchema,
    canAlwaysApprove: workflowAllUserTypesOptionsSchema,
    notifyOfRequestToAssign: workflowAllUserTypesOptionsSchema,
    requiredReviewersForApproval: z.number().min(1),
    requireReApprovalAfterRevisions: z.boolean(),
  })
  .optional();

export type RequiresApproval = z.infer<typeof requiresApprovalSchema>;

export const creativeBriefConfigSchema = z.object({
  templateInstructions: z.string(),
  onMissingFields: z.nativeEnum(CreativeBriefMissingDetails),
  sendToDesign: sendToDesignSchema,
  requiresApproval: requiresApprovalSchema,
});

export type CreativeBriefConfig = z.infer<typeof creativeBriefConfigSchema>;

export const moodBoardConfigSchema = z.object({
  canAccess: workflowLimitableUserTypesOptionsSchema,
  sendToDesign: sendToDesignSchema,
  requiresApproval: requiresApprovalSchema,
});

export type MoodBoardConfig = z.infer<typeof moodBoardConfigSchema>;

export const conceptDesignConfigSchema = z.object({
  canAccess: workflowLimitableUserTypesOptionsSchema,
  sendToDesign: sendToDesignSchema,
  requiresApproval: requiresApprovalSchema,
});

export type ConceptDesignConfig = z.infer<typeof conceptDesignConfigSchema>;

export const designReviewConfigSchema = z.object({
  canMarketingAccess: z.boolean(),
  requiresApproval: requiresApprovalSchema,
});

export type DesignReviewConfig = z.infer<typeof designReviewConfigSchema>;

export const deliveryConfigSchema = z.object({
  canSetTicketStatus: workflowLimitableUserTypesOptionsSchema,
});

export type DeliveryConfig = z.infer<typeof deliveryConfigSchema>;

// Steps ======================================================================

const creativeBriefStepSchema = z.object({
  name: z.string(),
  status: ticketWorkflowStepStatusSchema.optional(),
  type: z.literal(TicketWorkflowStep.BRIEF),
  config: creativeBriefConfigSchema,
  startedAt: z.coerce.date().optional(),
});

export type CreativeBriefStep = z.infer<typeof creativeBriefStepSchema>;

const moodBoardStepSchema = z.object({
  name: z.string(),
  status: ticketWorkflowStepStatusSchema.optional(),
  type: z.literal(TicketWorkflowStep.MOOD_BOARD),
  config: moodBoardConfigSchema,
  startedAt: z.coerce.date().optional(),
});

export type MoodBoardStep = z.infer<typeof moodBoardStepSchema>;

const conceptDesignStepSchema = z.object({
  name: z.string(),
  status: ticketWorkflowStepStatusSchema.optional(),
  type: z.literal(TicketWorkflowStep.CONCEPT_DESIGN),
  config: conceptDesignConfigSchema,
  startedAt: z.coerce.date().optional(),
});

export type ConceptDesignStep = z.infer<typeof conceptDesignStepSchema>;

const designReviewStepSchema = z.object({
  name: z.string(),
  status: ticketWorkflowStepStatusSchema.optional(),
  type: z.literal(TicketWorkflowStep.DESIGN_REVIEW),
  config: designReviewConfigSchema,
  startedAt: z.coerce.date().optional(),
});

export type DesignReviewStep = z.infer<typeof designReviewStepSchema>;

const deliveryStepSchema = z.object({
  name: z.string(),
  status: ticketWorkflowStepStatusSchema.optional(),
  type: z.literal(TicketWorkflowStep.DELIVERY),
  config: deliveryConfigSchema,
  startedAt: z.coerce.date().optional(),
});

export type DeliveryStep = z.infer<typeof deliveryStepSchema>;

// Workflows ==================================================================

export const workflowStepSchema = z.discriminatedUnion("type", [
  creativeBriefStepSchema,
  moodBoardStepSchema,
  conceptDesignStepSchema,
  designReviewStepSchema,
  deliveryStepSchema,
]);

export type WorkflowStep = z.infer<typeof workflowStepSchema>;

type DesignReviewSteps = z.infer<typeof designReviewStepSchema>[];

// Constraints:
// - Creative Brief must be first
// - Mood Board is optional, must come second if present
// - Concept Design is optional, must come after Mood Board if present
// - Design Reviews are optional, must come just before Delivery if present
// - Delivery must be last
// - Send to Design can only happen once
export type WorkflowSteps =
  | [z.infer<typeof creativeBriefStepSchema>, ...DesignReviewSteps]
  | [...DesignReviewSteps]
  | [
      z.infer<typeof creativeBriefStepSchema>,
      ...DesignReviewSteps,
      z.infer<typeof deliveryStepSchema>,
    ]
  | [
      z.infer<typeof creativeBriefStepSchema>,
      z.infer<typeof moodBoardStepSchema>,
      ...DesignReviewSteps,
      z.infer<typeof deliveryStepSchema>,
    ]
  | [
      z.infer<typeof creativeBriefStepSchema>,
      z.infer<typeof moodBoardStepSchema>,
      z.infer<typeof conceptDesignStepSchema>,
      ...DesignReviewSteps,
      z.infer<typeof deliveryStepSchema>,
    ];

export const workflowStepsSchema = z
  .array(workflowStepSchema)
  .refine((steps) => {
    if (steps.length === 0) return false;

    let briefIndex = -1;
    let deliveryIndex = -1;
    let hasInvalidOrder = false;

    steps.forEach((step, index) => {
      switch (step.type) {
        case TicketWorkflowStep.BRIEF:
          if (briefIndex === -1) {
            briefIndex = index;
          } else {
            hasInvalidOrder = true;
          }
          break;
        case TicketWorkflowStep.DELIVERY:
          if (deliveryIndex === -1) {
            deliveryIndex = index;
          } else {
            hasInvalidOrder = true;
          }
          break;
        default:
          if (
            (briefIndex !== -1 && index < briefIndex) ||
            (deliveryIndex !== -1 && index > deliveryIndex)
          ) {
            hasInvalidOrder = true;
          }
          break;
      }
    });

    if (hasInvalidOrder) return false;
    if (briefIndex !== -1 && deliveryIndex !== -1 && briefIndex > deliveryIndex)
      return false;

    return true;
  }, "Invalid workflow step sequence");

export const workflowSchema = z.object({
  _id: z.string().optional(),
  enterprise: z.string(),
  name: z.string(),
  steps: workflowStepsSchema,
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
});

export type Workflow = z.infer<typeof workflowSchema>;

export function getDefaultWorkflows(enterpriseId: string): Workflow[] {
  const DESIGN_REVIEW: DesignReviewStep = {
    name: "Design Review",
    type: TicketWorkflowStep.DESIGN_REVIEW,
    config: {
      canMarketingAccess: true,
      requiresApproval: {
        canAssignReviewers: {
          roles: Object.values(
            WorkflowLimitedUserTypes,
          ) as WorkflowLimitedUserTypes[],
          userIds: [],
        },
        canBeAssigned: {
          roles: Object.values(WorkflowUserTypes) as WorkflowUserTypes[],
          userIds: [],
        },
        canAlwaysApprove: {
          roles: [
            WorkflowUserTypes.ENTERPRISE_ADMIN,
            WorkflowUserTypes.ENTERPRISE_OPS,
          ],
          userIds: [],
        },
        notifyOfRequestToAssign: {
          roles: [WorkflowUserTypes.ENTERPRISE_ADMIN],
          userIds: [],
        },
        requiredReviewersForApproval: 1,
        requireReApprovalAfterRevisions: true,
      },
    },
  };

  const STEPS: WorkflowSteps = [DESIGN_REVIEW];

  const DEFAULT_WORKFLOWS: Workflow[] = [
    {
      name: "Default Design Flow",
      enterprise: enterpriseId,
      steps: STEPS,
    },
  ];

  return DEFAULT_WORKFLOWS;
}
