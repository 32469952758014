import {
  type GetTicketResponse,
  type GetUsersResponse,
  type BaseTicketComment,
  type BaseTicketMessage,
  type CreateTicketCommentResponse,
} from "@mg/schemas/src/christo/catalyst";
import {
  EnterpriseProfileType,
  TicketCommentDisposition,
  TicketMessage,
} from "@mg/schemas/src/commons";
import { type ShowcaseToken } from "@mg/schemas/src/prince/auth";
import {
  ArrowSquareOut,
  CheckCircle,
  PencilSimple,
  PencilSimpleSlash,
  Robot,
  ThumbsDown,
  ThumbsUp,
  Trash,
} from "@phosphor-icons/react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Grid,
  IconButton,
  Select,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import { type Editor } from "@tldraw/tldraw";
import cx from "classnames";
import { formatDistanceToNowStrict } from "date-fns";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { useState, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { AvatarWithInitials } from "../../../../../components/AvatarWithInitials";
import { Preview } from "../../../../../components/Preview";
import leapfrogLoader from "../../../../../images/leapfrog-gray.gif";
import { useAnalytics } from "../../../../../utils/analytics";
import {
  canDeleteComments,
  canDismissComments,
  canDoRestrictedCommentEdit,
  canResolveComments,
  canThumbsDownAiComments,
} from "../../../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import {
  useCreateAskPunttComment,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useNewCommentMutation,
} from "../../../../../utils/queries/projects";
import { getAiRuleMap } from "../../../../../utils/selections";
import {
  setComments,
  setHighlightedCommentId,
} from "../../../../../utils/slices/ticket";
import { knowledgeSettingRoute } from "../../../../settings/routes/knowledgeSettingRoute";
import {
  CommentMentions,
  type Selections,
} from "../../../components/CommentMentions/CommentMentions";
import { MessageComponent } from "../../../components/CommentTool/MessageComponent";
import { formatTimeStamp } from "../../../components/VideoPlayback";

const commentActionClasses =
  "opacity-0 transition-opacity group-hover:opacity-100";

type CommentProps = {
  /**
   * The author of the comment
   */
  author?: {
    _id?: string | undefined;
    avatar?: string | undefined;
    name?: string | undefined;
  };
  /**
   * The timestamp (string or date) or the comment
   * submission. This value is rendered relative to the
   * current time (e.g. "15 minutes ago").
   */
  createdAt: string;
  /**
   * Optionally, whether the comment is an AI comment or
   * not; defaults to `false`.
   */
  isAI?: boolean;
  /**
   * Optionally, if the comment is a reply to another comment
   */
  isReply?: boolean;
  /**
   * The message text of the comment.
   */
  message: string;
  /**
   * The ID of the comment or message, used for editing and
   * deleting a message
   */
  messageId: string;
  /**
   * The original comment object
   */
  meta: BaseTicketComment;
  /**
   * Handler when any comment or reply is clicked
   */
  onClick?(): void;
  /**
   * An array of replies to the main comment
   */
  replies?: BaseTicketMessage[];
  /**
   * Optionally, forces the reply input to render. This is
   * because we conditionally render the reply box on the
   * root comment, or on the last reply if one is available
   */
  showReplyInput?: boolean;
  /**
   * An optional title that appears at the top of the
   * component (e.g. which revision you are actively
   * reviewing)
   */
  title?: string;
  updatedAt: string;
  onSuccess?: (res: CreateTicketCommentResponse) => void;
  onError?: (
    error: Error,
    data: CreateTicketCommentResponse,
    operation: string,
  ) => void;
  mentions?: string[];
  rule?: string;
  editor: Editor | null | undefined;
  users?: GetUsersResponse;
  /**
   * Used to show a background color for active comments
   * when the comment is clicked
   * */
  isActive?: boolean;
  videoStart?: number;
  commentId: string;
  isAIPending?: boolean;
  isNotFirstReply?: boolean;
  isCanvas?: boolean;
  citations?: TicketMessage["citations"];
};

function analyticsPayload(
  renderTime: number,
  comment: BaseTicketComment,
  messageId: string,
  ticket: GetTicketResponse,
  comments: BaseTicketComment[],
  revisionName?: string,
  user?: ShowcaseToken | null,
) {
  const message =
    comment._id === messageId
      ? comment
      : comment.messages.find((m) => m._id === messageId);
  const currentRevision = ticket.revisionBoards?.find(
    (r) => r.name === revisionName,
  );

  return {
    rootCommentCreatedAt: comment.createdAt,
    rootCommentUpdatedAt: comment.updatedAt,
    rootCommentIsAi: comment.isAI,
    rootCommentText: comment.description,
    rootCommentId: comment._id,
    rootCommentIsRequired: comment.isRequired,
    rootCommentRule: comment.rule,
    rootCommentHasAiPersona: Boolean(comment.aiPersona),
    messageCreatedAt: message?.createdAt,
    messageUpdatedAt: message?.updatedAt,
    messageIsAi: message?.isAI,
    messageText: message?.description,
    messageId: message?._id,
    commentIsReply: comment._id !== messageId,
    numReplies: comment.messages.length,
    numComments: comments.length,
    numNewComments: comments.filter(
      (c) => new Date(c.createdAt).getTime() > renderTime,
    ).length,
    numActiveCommentsOnCurrentRevision: comments.filter(
      (c) =>
        c.boardId === currentRevision?._id &&
        c.disposition === TicketCommentDisposition.DEFAULT,
    ).length,
    numRevisions: ticket.revisionBoards?.length,
    revisionName,
    revisionId: currentRevision?._id,
    revisionNumber: currentRevision?.revision,
    revisionFileTypes: currentRevision?.reviewFiles?.map((f) =>
      f.source.slice(-3),
    ),
    minutesSinceRevisionCreated:
      (Date.now() -
        new Date(currentRevision?.createdAt ?? Date.now()).getTime()) /
      (1000 * 60),
    revisionCreatedByCurrentUser:
      (typeof currentRevision?.createdBy === "string"
        ? currentRevision?.createdBy
        : currentRevision?.createdBy._id) === user?.userID &&
      Boolean(user?.userID),
    numMentions: message?.mentions?.length ?? 0,
    messageMentions: message?.mentions?.map((m) =>
      typeof m === "string" ? m : m.email,
    ),
    minutesSinceRender: (Date.now() - renderTime) / (1000 * 60),
    x: comment.x,
    y: comment.y,
    boardType: comment.boardType,
    boardId: comment.boardId,
    disposition: comment.disposition,
  };
}

export function Comment(props: CommentProps) {
  const {
    author,
    createdAt,
    isAI = false,
    isReply = false,
    message,
    messageId,
    meta,
    onClick,
    replies = [],
    showReplyInput = false,
    title,
    updatedAt,
    onSuccess,
    onError,
    mentions,
    rule,
    editor,
    users,
    isActive,
    videoStart,
    commentId,
    isAIPending,
    isNotFirstReply,
    isCanvas,
    citations,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);
  const posthog = useAnalytics("Comment");
  const user = useAppSelector((state) => state.auth.value);
  const userIsMG = user?.role === EnterpriseProfileType.MEANINGFUL_GIGS;
  const { comments, value: ticket } = useAppSelector((state) => state.ticket);
  const dispatch = useAppDispatch();
  const createCommentMutation = useNewCommentMutation();
  const editCommentMutation = useEditCommentMutation();
  const deleteCommentMutation = useDeleteCommentMutation({
    parentCommentId: meta._id,
    editor,
  });
  const askPunttMutation = useCreateAskPunttComment();

  const manageBrandGuidelines = useFeatureFlagVariantKey(
    "manage-brand-guidelines",
  );

  const canEditCommentRequired = canDoRestrictedCommentEdit();

  // used to edit the comment's text
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(message);
  // used to reply to the comment
  const [replyText, setReplyText] = useState("");
  // used to toggle the thumbs icon states. This state does
  // not persist refreshes.
  const [thumbsUp, setThumbsUp] = useState(meta.liked);
  const [thumbsDown, setThumbsDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequired, setIsRequired] = useState<boolean | "indeterminate">(
    meta.isRequired,
  );
  const [editedRule, setEditedRule] = useState(rule ?? "no_rule");
  const aiRuleMap = getAiRuleMap();

  const mentionsRef = useRef<{
    getSelections: () => Selections;
    closeMentions: () => void;
  }>();

  const editMentionsRef = useRef<{
    getSelections: () => Selections;
    closeMentions: () => void;
  }>();

  const renderTimeRef = useRef(0);
  useEffect(() => {
    renderTimeRef.current = Date.now();
  }, [ticket?._id]);

  const currentCitations = citations || isReply ? citations : meta.citations;
  // Being a little less strict with the union of citation types makes this much easier to work with
  const citation: { url?: string; pageNumber?: number; text?: string } | false =
    Array.isArray(currentCitations) &&
    typeof currentCitations[0] === "object" &&
    currentCitations[0];

  const handleGetSelections = () => {
    if (mentionsRef.current) {
      return mentionsRef.current.getSelections();
    }
  };

  const handleEditGetSelections = () => {
    if (editMentionsRef.current) {
      return editMentionsRef.current.getSelections();
    }
  };

  const isEdited = createdAt !== updatedAt;
  const isOwnComment = user?.userID === author?._id;
  useEffect(() => {
    setEditText(message);
  }, [message]);

  useEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isActive, meta.isRequired]);

  function handleThumbsUp(liked: boolean) {
    const startTime = performance.now();

    if (!ticket || editCommentMutation.isPending) return;
    setThumbsUp(liked);

    editCommentMutation.mutateAsync({
      ticketId: ticket._id,
      messageId,
      payload: {
        description: message,
        liked,
      },
    });

    posthog.capture("thumbs_up_comment", {
      ...analyticsPayload(
        renderTimeRef.current,
        meta,
        messageId,
        ticket,
        comments,
        title,
        user,
      ),
      requestDurationSeconds: (performance.now() - startTime) / 1000,
    });
  }

  // Everyone should be able to leave a thumbs up on an AI
  // comment regardless of user account type.
  function renderThumbsUpAction() {
    if (!isAI) {
      return null;
    }

    return (
      <IconButton
        data-testid="thumbs-up-action"
        size="1"
        variant="ghost"
        onClick={() => handleThumbsUp(!thumbsUp)}
        color={thumbsUp ? "green" : undefined}
        className={commentActionClasses}
        data-auth-trigger="thumbs-up"
      >
        <ThumbsUp
          weight={thumbsUp ? "fill" : "regular"}
          height="16"
          width="16"
        />
      </IconButton>
    );
  }

  function handleThumbsDown() {
    const startTime = performance.now();
    setThumbsDown(true);

    if (!ticket) return;
    posthog.capture("thumbs_down_comment", {
      ...analyticsPayload(
        renderTimeRef.current,
        meta,
        messageId,
        ticket,
        comments,
        title,
        user,
      ),
      requestDurationSeconds: (performance.now() - startTime) / 1000,
    });
  }

  // Only MG and AI Studio users can thumbs down.
  function renderThumbsDownAction() {
    if (!isAI || !canThumbsDownAiComments()) {
      return null;
    }

    return (
      <IconButton
        data-testid="thumbs-down-action"
        size="1"
        variant="ghost"
        onClick={() => handleThumbsDown()}
        color={thumbsDown ? "red" : undefined}
        className={commentActionClasses}
        data-auth-trigger="thumbs-down"
      >
        <ThumbsDown
          weight={thumbsDown ? "fill" : "regular"}
          height="16"
          width="16"
        />
      </IconButton>
    );
  }

  function handleReply() {
    if (ticket == null || replyText.trim() === "" || isLoading) {
      return;
    }
    setIsLoading(true);
    const selections = handleGetSelections();
    if (selections?.find((s) => s._id === "@puntt")) {
      setIsLoading(false);

      const commentsCopy = comments.map((comm) => {
        if (comm._id === meta._id) {
          return {
            ...comm,
            messages: [
              ...comm.messages,
              {
                _id: (Math.random() * 10000).toString(),
                createdBy: {
                  _id: user?.userID,
                  name: user?.name ?? "Anonymous",
                  avatar: user?.avatar,
                },
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                isDeleted: false,
                isPending: false,
                isReview: false,
                votes: [],
                isAI: false,
                description: replyText,
                mentions: [],
              },
              {
                createdBy: {
                  _id: "63bc994226c323d41d67a11f",
                  name: "AI Reviewer",
                  avatar:
                    "users/615f5203e1cb445ef2486b74/3-color-logo-white-bg-square.png",
                },
                aiPersona: {
                  name: "",
                  avatar: "",
                },
                description: "",
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                isDeleted: false,
                isPending: true,
                isReview: false,
                votes: [],
                isAI: true,
                reviewId: "67082ad6ecce06a419fe876d",
                mentions: [],
                messages: [],
                x: 20,
                y: 20,
                boardType: "revisionBoard",
                boardId: "6706ca0b6a3ca36a292610a5",
                disposition: "default",
                _id: (Math.random() * 100000).toString(),
                aiPending: true,
              },
            ],
          };
        }

        return comm;
      });
      const updatedComment = commentsCopy.find((comm) => comm._id === meta._id);
      dispatch(setComments(commentsCopy));
      if (onSuccess) onSuccess(updatedComment!);

      return askPunttMutation
        .mutateAsync({
          ticketId: ticket._id,
          description: replyText,
          x: meta.x as number,
          y: meta.y as number,
          boardId: meta.boardId as string,
          commentId: meta._id,
          shapeIds: [],
          isRequired: meta.isRequired,
        })
        .then((res) => {
          if (onSuccess) onSuccess(res);
        })
        .catch((error) => {
          dispatch(setComments(comments));
          if (onError) onError(error, meta, "asking Puntt AI");
        })
        .finally(() => {
          setReplyText("");
          setIsLoading(false);
        });
    }
    createCommentMutation
      .mutateAsync({
        ticketId: ticket._id,
        payload: {
          description: replyText,
          x: meta.x,
          y: meta.y,
          boardId: meta.boardId,
          commentId: meta._id, // always the parent comment ID
          mentions: selections
            ?.map((s) => s._id)
            .filter((id): id is string => id !== undefined),
        },
      })
      .then((res) => {
        if (onSuccess) onSuccess(res);
        setReplyText("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [isEditingPending, setIsEditingPending] = useState(false);
  function handleEdit() {
    if (ticket == null || editText.trim().length === 0) {
      return;
    }

    const selections = handleEditGetSelections();

    const uniqueMentions =
      Array.from(
        new Set(
          selections
            ?.map((s) => s._id)
            .filter((id): id is string => id !== undefined),
        ),
      ) ?? [];

    const hasPunttMention = selections?.some((s) => s._id === "@puntt");

    if (hasPunttMention) {
      setIsEditingPending(true);
      setIsLoading(true);
      const commentsCopy = comments.map((comm) => {
        if (comm._id === meta._id) {
          const messages = comm.messages;

          // Find the index of the message being edited
          const messageIndex = messages.findIndex(
            (msg) => msg._id === messageId,
          );
          const isRootComment = comm._id === messageId;

          const aiResponse = {
            aiPersona: {
              name: "",
              avatar: "",
            },
            description: "",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            isDeleted: false,
            isPending: true,
            isReview: false,
            votes: [],
            isAI: true,
            reviewId: "67082ad6ecce06a419fe876d",
            mentions: [],
            messages: [],
            x: 20,
            y: 20,
            boardType: "revisionBoard",
            boardId: "6706ca0b6a3ca36a292610a5",
            disposition: "default",
            _id: (Math.random() * 100000).toString(),
            aiPending: true,
          };
          if (isRootComment) {
            // If editing the root comment, update its description and add AI response as first message
            return {
              ...comm,
              description: editText,
              messages: [aiResponse, ...messages],
            };
          } else {
            // If editing a reply, update the message and insert AI response after it
            const updatedMessages = [...messages];
            updatedMessages[messageIndex] = {
              ...messages[messageIndex],
              description: editText,
            };
            updatedMessages.splice(messageIndex + 1, 0, aiResponse);
            return {
              ...comm,
              messages: updatedMessages,
            };
          }
        }
        return comm;
      });

      const updatedComment = commentsCopy.find((comm) => comm._id === meta._id);
      dispatch(setComments(commentsCopy));
      if (onSuccess) onSuccess(updatedComment!);
      setIsEditing(false);
      editMentionsRef.current?.closeMentions();
      // Then create the AI response

      const punttPayload = {
        ticketId: ticket._id,
        commentId: meta._id,
        description: editText,
        x: typeof meta.x === "number" ? meta.x : 0,
        y: typeof meta.y === "number" ? meta.y : 0,
        boardId: meta.boardId || "",
        shapeIds: [] as string[],
        disposition: meta.disposition || "default",
        liked: meta.liked, // Add liked field here as well
        rule: editedRule,
        isRequired: isRequired as boolean,
        isEdit: true,
      };
      if (messageId != commentId) {
        // @ts-expect-error TS2399: its there optionally
        punttPayload.messageId = messageId;
      }
      askPunttMutation
        .mutateAsync(punttPayload)
        .then((res) => {
          if (onSuccess) onSuccess(res);
        })
        .catch((error) => {
          dispatch(setComments(comments));
          if (onError) onError(error, meta, "asking Puntt AI");
        })
        .finally(() => {
          setIsEditingPending(false);
          setIsLoading(false);
          editMentionsRef.current?.closeMentions();
        });
    } else {
      // Regular edit without AI
      editCommentMutation
        .mutateAsync({
          ticketId: ticket._id,
          messageId,
          payload: {
            description: editText,
            mentions: uniqueMentions,
            liked: meta.liked,
            isRequired: isRequired as boolean,
            rule: editedRule,
          },
        })
        .then((res) => {
          setIsEditing(false);
          editMentionsRef.current?.closeMentions();
          if (onSuccess) onSuccess(res);
        });
    }
  }

  function handleDelete() {
    if (ticket == null) {
      return;
    }

    const startTime = performance.now();
    deleteCommentMutation.mutate({
      ticketId: ticket._id,
      messageId,
    });

    posthog.capture("deleted_ticket_comment", {
      ...analyticsPayload(
        renderTimeRef.current,
        meta,
        messageId,
        ticket,
        comments,
        title,
        user,
      ),
      requestDurationSeconds: (performance.now() - startTime) / 1000,
    });
  }

  function renderAvatar() {
    if (isAI) {
      return (
        <Avatar
          data-testid="ai-avatar"
          radius="full"
          color="amber"
          size="1"
          variant="solid"
          fallback={<Robot />}
          className="size-6"
        />
      );
    }

    return (
      <AvatarWithInitials
        data-testid="user-avatar"
        name={author?.name}
        avatar={author?.avatar}
        size={6}
      />
    );
  }

  function renderEditAction() {
    function canEditComment() {
      // Everyone should be able to edit their own comments
      if (isOwnComment) {
        return true;
      }
      // Only MG users can edit or delete AI comments
      if (isAI && userIsMG) {
        return true;
      }

      if (canEditCommentRequired && isAI) {
        return true;
      }

      return false;
    }

    const mentionRegex = new RegExp(
      `(^|\\W)@(${["puntt"].join("|")})\\b`,
      "gi",
    );
    const hasPunttMention = mentionRegex.test(message);
    const isDisabled = isEditingPending || hasPunttMention;

    const button = (
      <IconButton
        data-testid="edit-action"
        size="1"
        variant="soft"
        onClick={() => {
          if (!isDisabled) {
            setIsEditing(!isEditing);
          }
        }}
        className={commentActionClasses}
        data-auth-trigger="edit"
        disabled={isDisabled}
      >
        {isEditing ? (
          <PencilSimpleSlash height="16" width="16" />
        ) : (
          <PencilSimple height="16" width="16" />
        )}
      </IconButton>
    );

    if (!canEditComment()) {
      return null;
    }

    if (isDisabled) {
      return (
        <Tooltip
          content={
            hasPunttMention
              ? `@Puntt questions \ncannot be edited`
              : `Cannot edit while saving is taking place`
          }
          side="left"
          maxWidth="110px"
          align="center"
        >
          {button}
        </Tooltip>
      );
    }

    return button;
  }

  function renderDeleteAction() {
    function canDeleteComment() {
      // if the current user left the comment
      if (isOwnComment) {
        return true;
      }

      // MG and Admin users can delete (but not edit) any non-AI comment
      if (!isAI && canDeleteComments()) {
        return true;
      }

      // only MG users can edit or delete AI comments
      if (isAI && userIsMG) {
        return true;
      }

      return false;
    }

    if (!canDeleteComment() || isEditing || isEditingPending) {
      return null;
    }

    return (
      <IconButton
        data-testid="delete-action"
        size="1"
        variant="soft"
        onClick={(e) => {
          // if the shape that they are deleting is not currently selected,
          // we should prevent it from being selected flickering and then immediately being deleted
          e.stopPropagation();
          handleDelete();
        }}
        color="red"
        disabled={deleteCommentMutation.isPending}
        loading={deleteCommentMutation.isPending}
        className={commentActionClasses}
        data-auth-trigger="delete"
      >
        <Trash height="16" width="16" />
      </IconButton>
    );
  }

  function renderCommentContents() {
    // if its not their comment, and their role is not MG, they cannot edit the comment text, but they can edit the rule and required
    function canEditComment() {
      if (isOwnComment) {
        return true;
      }
      if (isAI && userIsMG) {
        return true;
      }
      return false;
    }
    if (isEditing) {
      return (
        <Grid gap="1">
          {!canEditComment() ? (
            <MessageComponent
              mentions={mentions}
              messageContent={message}
              users={users}
              rule={rule}
            />
          ) : (
            <CommentMentions
              ref={editMentionsRef}
              data-testid="edit-input"
              placeholder="Add Comment"
              onChange={setEditText}
              value={editText}
              disabled={editCommentMutation.isPending}
              loading={editCommentMutation.isPending}
              onSend={handleEdit}
              isEdit
              data-auth-trigger="edit"
              key={messageId}
            />
          )}
          <Flex gap="2" align="center" justify="between" mt="2">
            <Text
              size="1"
              as="label"
              className={cx("text-base-black", {
                hidden:
                  user?.role === EnterpriseProfileType.CATALYST_REQUESTER ||
                  user?.role === EnterpriseProfileType.CATALYST_CREATIVE ||
                  messageId != commentId,
              })}
            >
              <Flex gap="2" className="items-center">
                <Checkbox
                  size="1"
                  defaultChecked={meta.isRequired}
                  onCheckedChange={setIsRequired}
                />
                Required Change
              </Flex>
            </Text>
            <Button
              size="1"
              data-testid="edit-comment-save-action"
              disabled={
                editText.trim().length === 0 || editCommentMutation.isPending
              }
              loading={editCommentMutation.isPending}
              onClick={() => handleEdit()}
              className="float-right mt-2 min-h-6 w-1/2"
            >
              Save change
            </Button>
          </Flex>
          <Select.Root
            size="1"
            value={editedRule}
            onValueChange={setEditedRule}
          >
            <Select.Trigger
              className={cx("w-full", {
                hidden:
                  (user?.role !== EnterpriseProfileType.MEANINGFUL_GIGS &&
                    user?.role !== EnterpriseProfileType.CATALYST_AI) ||
                  !isRequired ||
                  messageId != commentId,
              })}
              variant="surface"
              color="gray"
            />
            <Select.Content>
              <Select.Item value="no_rule">No Rule</Select.Item>
              {Object.keys(aiRuleMap).map((key) => (
                <Select.Item key={key} value={key}>
                  {aiRuleMap[key]}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Grid>
      );
    }

    return (
      <MessageComponent
        mentions={mentions}
        messageContent={message}
        users={users}
        rule={rule}
      />
    );
  }

  function renderCitation() {
    if (!citation) return null;
    return (
      <Dialog.Root>
        <Dialog.Trigger>
          <Flex
            className="mt-2 cursor-pointer text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
            gap="1"
          >
            <Text className="underline" size="3">
              Source
            </Text>
            <ArrowSquareOut width={16} height={16} className="mt-1" />
          </Flex>
        </Dialog.Trigger>
        <Dialog.Content
          style={
            !citation.url && citation.text
              ? {} // Smaller width for text-only citations
              : {
                  width: "90vw",
                  maxWidth: "80rem",
                  height: "90vh",
                  maxHeight: "60rem",
                }
          }
        >
          <Flex justify="between" align="center" gap="4" mb="4">
            <Dialog.Title className="mb-0">Comment source details</Dialog.Title>
            <Dialog.Description className="hidden">
              Citation details
            </Dialog.Description>
            <Button
              className="ml-auto hover:bg-puntt-accent-10 hover:text-base-white"
              variant="outline"
              size="1"
            >
              <a
                href={citation.url ?? knowledgeSettingRoute.to}
                target="_blank"
                rel="noreferrer"
                className={cx("flex gap-1", {
                  hidden: !citation.url && manageBrandGuidelines !== "enabled",
                })}
              >
                <Text>
                  {citation.url ? "Open in New Tab" : "View Brand Guidelines"}
                </Text>
                <ArrowSquareOut width={16} height={16} />
              </a>
            </Button>
            <Dialog.Close>
              <Button type="button" variant="soft" color="gray">
                Close
              </Button>
            </Dialog.Close>
          </Flex>
          <Preview
            url={citation.url}
            pageNumber={citation.pageNumber}
            text={citation.text}
            textPrefix="From custom guidelines:"
          />
        </Dialog.Content>
      </Dialog.Root>
    );
  }

  function handleChangeDisposition(disposition: TicketCommentDisposition) {
    if (isReply || ticket == null) {
      return;
    }

    return editCommentMutation
      .mutateAsync({
        ticketId: ticket._id,
        messageId,
        payload: {
          description: message,
          disposition,
        },
      })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      });
  }

  function renderDismissAction() {
    if (isReply || !canDismissComments() || isOwnComment) {
      return null;
    }

    return (
      <IconButton
        data-testid="dismiss-action"
        size="1"
        variant="soft"
        disabled={meta.disposition !== TicketCommentDisposition.DEFAULT}
        onClick={async () => {
          const startTime = performance.now();
          await handleChangeDisposition(TicketCommentDisposition.DISMISSED);

          if (!ticket) return;
          posthog.capture("dismissed_ticket_comment", {
            ...analyticsPayload(
              renderTimeRef.current,
              meta,
              messageId,
              ticket,
              comments,
              title,
              user,
            ),
            requestDurationSeconds: (performance.now() - startTime) / 1000,
          });
        }}
        className={twMerge(
          cx(commentActionClasses, {
            "opacity-100":
              meta.disposition === TicketCommentDisposition.RESOLVED,
          }),
        )}
        data-auth-trigger="dismiss"
      >
        <Trash
          weight={
            meta.disposition === TicketCommentDisposition.DISMISSED
              ? "fill"
              : "regular"
          }
          height="16"
          width="16"
        />
      </IconButton>
    );
  }

  function renderResolveAction() {
    if (isReply || !canResolveComments()) {
      return null;
    }

    return (
      <IconButton
        data-testid="resolve-action"
        size="1"
        disabled={meta.disposition !== TicketCommentDisposition.DEFAULT}
        variant="soft"
        onClick={async () => {
          const startTime = performance.now();
          await handleChangeDisposition(TicketCommentDisposition.RESOLVED);

          if (!ticket) return;
          posthog.capture("resolved_ticket_comment", {
            ...analyticsPayload(
              renderTimeRef.current,
              meta,
              messageId,
              ticket,
              comments,
              title,
              user,
            ),
            requestDurationSeconds: (performance.now() - startTime) / 1000,
          });
        }}
        className={twMerge(
          cx(commentActionClasses, {
            "opacity-100":
              meta.disposition === TicketCommentDisposition.RESOLVED,
          }),
        )}
        data-auth-trigger="resolve"
      >
        <CheckCircle
          weight={
            meta.disposition === TicketCommentDisposition.RESOLVED
              ? "fill"
              : "regular"
          }
          height="16"
          width="16"
        />
      </IconButton>
    );
  }

  return (
    <>
      <div
        ref={itemRef}
        data-testid={isReply ? "comment-reply" : "comment"}
        className={cx("group grid cursor-default gap-4 p-4 transition-colors", {
          "ml-4 border-l border-l-puntt-neutral-gray-6": isReply,
          "border-y border-y-puntt-neutral-gray-6": !isReply && !isCanvas,
          "border-t border-t-puntt-neutral-gray-6": isNotFirstReply,
          "border-b border-b-puntt-neutral-gray-6": isCanvas,
          "bg-puntt-accent-3": isActive,
          "cursor-pointer hover:bg-puntt-accent-2":
            meta.x != null || meta.y != null || videoStart != null,
        })}
        tabIndex={-1}
        role="button"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.code === "space" && typeof onClick === "function") {
            onClick();
          }
        }}
        data-x={!isReply && meta.x}
        data-y={!isReply && meta.y}
        data-id={messageId}
        onMouseEnter={() => {
          dispatch(setHighlightedCommentId(commentId));
        }}
        onMouseLeave={() => dispatch(setHighlightedCommentId(null))}
      >
        {isAIPending ? (
          <section className="min-w-0">
            <Flex gap="2">
              {renderAvatar()}

              <article className="min-w-0 grow">
                <header className="relative mb-2 flex min-w-0 items-start justify-between">
                  <Text
                    size="3"
                    weight="medium"
                    className="min-w-0 hyphens-auto whitespace-normal break-words"
                  >
                    Puntt AI
                  </Text>
                </header>

                <Button
                  className="pointer-events-none w-full bg-puntt-neutral-gray-8"
                  size="2"
                >
                  <img
                    alt="leap frog loader"
                    src={leapfrogLoader}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Button>
              </article>
            </Flex>
          </section>
        ) : (
          <>
            <section
              data-testid="comment-title"
              className={cx({ hidden: title == null })}
            >
              <Text size="2" weight="medium" className="text-carbon-300">
                {title}
              </Text>
            </section>
            <section className="min-w-0">
              <Flex gap="2">
                {renderAvatar()}

                <article className="min-w-0 grow">
                  <header className="relative mb-2 flex min-w-0 items-start justify-between">
                    <Text
                      size="3"
                      weight="medium"
                      className="min-w-0 hyphens-auto whitespace-normal break-words"
                    >
                      {isAI ? "Puntt AI" : author?.name}
                    </Text>

                    <Text
                      size="1"
                      className={cx(
                        "pointer-events-none mt-1 max-w-[6rem] select-none hyphens-auto whitespace-normal break-words text-right text-puntt-neutral-grayA-9 transition-opacity group-hover:opacity-0",
                        {
                          hidden:
                            meta.disposition !==
                              TicketCommentDisposition.DEFAULT || isAI,
                        },
                      )}
                    >
                      {formatDistanceToNowStrict(
                        new Date(isEdited ? updatedAt : createdAt),
                      )}{" "}
                      ago {isEdited && !isAI ? "(edited)" : null}
                    </Text>

                    <Flex
                      gap="2"
                      align="center"
                      justify="between"
                      className="absolute right-0 top-0 ml-2 flex-1"
                    >
                      <Flex gap="2">
                        {renderThumbsUpAction()}
                        {renderThumbsDownAction()}
                      </Flex>

                      <Flex gap="2">
                        {renderEditAction()}
                        {renderDeleteAction()}
                        {renderDismissAction()}
                        {renderResolveAction()}
                      </Flex>
                    </Flex>
                  </header>

                  <Text
                    className={cx("text-puntt-accent-12 underline", {
                      hidden: typeof videoStart !== "number",
                    })}
                    weight="medium"
                    size="2"
                  >
                    {formatTimeStamp(videoStart)}
                  </Text>

                  {renderCommentContents()}
                  {renderCitation()}
                </article>
              </Flex>
            </section>

            {/* TODO: the UX around replying to a resolved or rejected comment is strange and we need to rethink it. */}
            {meta.disposition === TicketCommentDisposition.DEFAULT && (
              <>
                <CommentMentions
                  ref={mentionsRef}
                  data-testid="reply-input"
                  className={cx({
                    hidden: replies.length > 0 || isReply,
                  })}
                  placeholder="Add Comment"
                  onChange={setReplyText}
                  value={replyText}
                  disabled={isLoading || createCommentMutation.isPending}
                  loading={createCommentMutation.isPending}
                  onSend={handleReply}
                  data-auth-trigger="reply"
                />
                {showReplyInput && (
                  <CommentMentions
                    ref={mentionsRef}
                    data-testid="reply-input"
                    placeholder="Add Comment"
                    onChange={setReplyText}
                    value={replyText}
                    disabled={isLoading || createCommentMutation.isPending}
                    loading={createCommentMutation.isPending}
                    onSend={handleReply}
                    data-auth-trigger="reply"
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      {replies.map((reply, i) => (
        <Comment
          key={reply._id}
          author={reply.createdBy as Exclude<typeof reply.createdBy, string>}
          createdAt={reply.createdAt}
          isReply
          message={reply.description}
          messageId={reply._id}
          meta={meta}
          onClick={onClick}
          showReplyInput={i === replies.length - 1}
          isNotFirstReply={i !== 0}
          updatedAt={reply.updatedAt}
          onSuccess={onSuccess}
          editor={editor}
          users={users}
          mentions={reply.mentions as string[]}
          isActive={isActive}
          commentId={commentId}
          // @ts-expect-error TS2551: uhhhh.. Not sure what the key is.
          isAIPending={reply.aiPending}
          isAI={reply.isAI}
          isCanvas={isCanvas}
          citations={reply.citations}
        />
      ))}
    </>
  );
}
