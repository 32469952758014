import { DotsThree } from "@phosphor-icons/react";
import { DropdownMenu, Flex, Heading, IconButton } from "@radix-ui/themes";
import { type Editor } from "@tldraw/tldraw";
import cx from "classnames";

import { Comments } from "./Comments";

import { canShowDismissedComments } from "../../../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import {
  toggleShowDismissedComments,
  toggleShowResolvedComments,
} from "../../../../../utils/slices/ui";

type CommentDrawerProps = {
  editor?: Editor | null;
  setActiveRevisionIndex(index: number): Promise<void>;
  videoRef: React.RefObject<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>;
  isLoading: boolean;
};

export function CommentDrawer(props: CommentDrawerProps) {
  const dispatch = useAppDispatch();
  const ticket = useAppSelector((state) => state.ticket.value);
  const { showResolvedComments, showDismissedComments } = useAppSelector(
    (state) => state.ui,
  );

  if (ticket == null) {
    return null;
  }

  return (
    <aside
      data-testid="comments-drawer"
      className="col-start-2 row-span-2 row-start-1 h-[calc(100vh_-_57px)] w-80 bg-base-white shadow-lg"
    >
      <header
        data-testid="comments-drawer-header"
        className="flex items-center justify-between border-b border-b-puntt-neutral-gray-8 p-4"
      >
        <Heading size="3" weight="medium">
          Comments
        </Heading>

        <Flex align="center" gap="2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <IconButton size="1" variant="ghost" color="gray">
                <DotsThree />
              </IconButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content>
              <DropdownMenu.Item
                onClick={() => dispatch(toggleShowResolvedComments())}
              >
                {showResolvedComments ? "Hide" : "Show"} resolved comments
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => dispatch(toggleShowDismissedComments())}
                disabled={!canShowDismissedComments()}
                className={cx({ hidden: !canShowDismissedComments() })}
              >
                {showDismissedComments ? "Hide" : "Show"} dismissed comments
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Flex>
      </header>

      <Comments {...props} />
    </aside>
  );
}
