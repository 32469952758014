import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi/dist/zod-extensions";
import { z } from "zod";

import {
  EnterpriseProfileType,
  UserPermission,
  UserType,
} from "@mg/schemas/src/commons/user";
extendZodWithOpenApi(z);

export enum AuthSource {
  APP = "app",
  PORTAL = "portal",
}

export const showcaseTokenSchema = z.object({
  userID: z.string().optional(),
  email: z.string().email(),
  type: z.nativeEnum(UserType),
  isOnboardingComplete: z.boolean().optional(),
  isEnterpriseOnboarded: z.boolean().optional(),
  hasGigs: z.boolean().optional(),
  enterpriseSlug: z.string().optional(),
  name: z.string().optional(),
  avatar: z.string().optional(),
  company: z.string().optional(),
  enterpriseId: z.string().optional(),
  enterpriseLogo: z.string().optional(),
  enterpriseName: z.string().optional(),
  referredBy: z.string().optional(),
  permissions: z.array(z.nativeEnum(UserPermission)).optional(),
  role: z.nativeEnum(EnterpriseProfileType).optional(),
  ticketId: z.string().optional(),
  folderId: z.string().optional(),
  revisionBoardId: z.string().optional(),
  originalRevisionBoardId: z.string().optional(),

  // standard JWT attributes
  iss: z.string().optional(),
  sub: z.string().optional(),
  aud: z.string().optional(),
  exp: z.number().optional(),
  nbf: z.number().optional(),
  iat: z.number().optional(),
  jti: z.string().optional(),
});

export type ShowcaseToken = z.infer<typeof showcaseTokenSchema>;

export const emailSchema = z
  .string()
  .email()
  .nonempty()
  .transform((val) => val.toLowerCase());

export const loginBodySchema = z.object({
  email: emailSchema.openapi({
    description: "Email address",
    example: "email@test.com",
  }),
  password: z.string(),
});

export type LoginBody = z.infer<typeof loginBodySchema>;

export const loginRequestSchema = z.object({
  body: loginBodySchema,
});

export type LoginRequest = z.infer<typeof loginRequestSchema>;

export const workosLoginResponseSchema = z
  .object({
    token: z.string(),
    authenticationFactor: z
      .object({
        id: z.string(),
        totp: z.object({
          qrCode: z.string(),
          secret: z.string(),
        }),
      })
      .optional(),
    authenticationChallenge: z
      .object({
        id: z.string(),
      })
      .optional(),
    pendingAuthenticationToken: z.string(),
  })
  .deepPartial();

export type WorkosLoginResponse = z.infer<typeof workosLoginResponseSchema>;

export const loginResponseSchema = z.object({
  token: z.string().openapi({
    description: "JWT token",
  }),
});

export type LoginResponse = z.infer<typeof loginResponseSchema>;

export const verifyMfaBodySchema = z.object({
  authenticationChallengeId: z.string(),
  pendingAuthenticationToken: z.string(),
  code: z.string(),
});

export type VerifyMfaBody = z.infer<typeof verifyMfaBodySchema>;

export const VerifyMfaRequestSchema = z.object({
  body: verifyMfaBodySchema,
});

export type VerifyMfaRequest = z.infer<typeof VerifyMfaRequestSchema>;

export const VerifyMfaResponseSchema = z.object({
  session: z.string().optional(),
  token: z.string().optional(),
});

export type VerifyMfaResponse = z.infer<typeof VerifyMfaResponseSchema>;

export const signupBodySchema = z.object({
  email: emailSchema.openapi({
    description: "Email address",
    example: "email@test.com",
  }),
  name: z.string().optional().openapi({
    description: "Name",
    example: "John Doe",
  }),
});

export type SignupBody = z.infer<typeof signupBodySchema>;

export const signupRequestSchema = z.object({
  body: signupBodySchema,
});

export type SignupRequest = z.infer<typeof signupRequestSchema>;

export const signupResponseSchema = z.object({
  token: z.string().openapi({
    description: "JWT token",
  }),
});

export type SignupResponse = z.infer<typeof signupResponseSchema>;

export const createPasswordBodySchema = z.object({
  password: z.string(),
  token: z.string().optional(),
});

export type CreatePasswordBody = z.infer<typeof createPasswordBodySchema>;

export const createPasswordRequestSchema = z.object({
  body: createPasswordBodySchema,
});

export type CreatePasswordRequest = z.infer<typeof createPasswordRequestSchema>;

export const createPasswordResponseSchema = z.object({
  token: z.string(),
});

export type CreatePasswordResponse = z.infer<
  typeof createPasswordResponseSchema
>;

export const verifyUserExistsBodySchema = z.object({
  email: z.string(),
  state: z.string().optional(),
});

export type VerifyUserExistsBody = z.infer<typeof verifyUserExistsBodySchema>;

export const verifyUserExistRequestSchema = z.object({
  body: verifyUserExistsBodySchema,
});

export type verifyUserExistRequest = z.infer<
  typeof verifyUserExistRequestSchema
>;

export const verifyUserExistResponseSchema = z.object({
  userExists: z.boolean(),
});

export type VerifyUserExist = z.infer<typeof verifyUserExistResponseSchema>;

export const refreshTokenResponseSchema = z.object({
  token: z.string(),
});

export type RefreshTokenResponse = z.infer<typeof refreshTokenResponseSchema>;

export const linkBodySchema = z.object({
  email: z
    .string()
    .email()
    .nonempty()
    .transform((val) => val.toLowerCase()),
});

export const linkRequestSchema = z.object({
  body: linkBodySchema,
});

export type LinkRequest = z.infer<typeof linkRequestSchema>;

export const linkResponseSchema = z.object({
  email: z.string(),
});

export type LinkResponse = z.infer<typeof linkResponseSchema>;

export const sendResetPasswordLinkBodySchema = z.object({
  email: z
    .string()
    .email()
    .transform((val) => val.toLowerCase()),
});

export type SendResetPasswordLinkBody = z.infer<
  typeof sendResetPasswordLinkBodySchema
>;

export const sendResetPasswordLinkRequestSchema = z.object({
  body: sendResetPasswordLinkBodySchema,
});

export type SendResetPasswordLinkRequest = z.infer<
  typeof sendResetPasswordLinkRequestSchema
>;

export const tokenResponseSchema = z.object({
  token: z.string(),
});

export const createGuestAccountBodySchema = z.object({
  name: z.string(),
  email: z.string(),
  password: z.string(),
});

export type CreateGuestAccountBody = z.infer<
  typeof createGuestAccountBodySchema
>;

export const createGuestAccountRequest = z.object({
  body: createGuestAccountBodySchema,
});

export type CreateGuestAccountRequest = z.infer<
  typeof createGuestAccountRequest
>;

export const createGuestAccountResponseSchema = z.object({
  token: z.string(),
});
export type CreateGuestAccountResponse = z.infer<
  typeof createGuestAccountResponseSchema
>;

export const switchEnterpriseBodySchema = z.object({
  enterpriseId: z.string(),
});

export const switchEnterpriseRequestSchema = z.object({
  body: switchEnterpriseBodySchema,
});

export type SwitchEnterpriseRequest = z.infer<
  typeof switchEnterpriseRequestSchema
>;

export const switchEnterpriseResponseSchema = z.object({
  token: z.string(),
});

export type SwitchEnterpriseResponse = z.infer<
  typeof switchEnterpriseResponseSchema
>;

export type WorkosMfaResponse = {
  rawData?: {
    code?: string;
    message?: string;
    user?: { id?: string; email?: string };
    pending_authentication_token?: string;
    authentication_factors?: { id?: string }[];
  };
};

export const logoutResponseSchema = z.object({
  success: z.boolean(),
});

export type LogoutResponse = z.infer<typeof logoutResponseSchema>;
