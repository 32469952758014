import { Text, Grid, Flex } from "@radix-ui/themes";
import cx from "classnames";

interface PasswordStrength {
  score: number;
  suggestions: string[];
}

interface Props {
  strength: PasswordStrength;
}

export function PasswordRequirements({ strength }: Props) {
  const passwordStrengthText = strength.score < 3 ? "Weak" : "Strong";

  return (
    <Grid gap="2">
      <Flex align="center" gap="2">
        <Text
          size="2"
          className={cx({
            "text-puntt-gold-dark-8": strength.score < 3,
            "text-puntt-blue-10": strength.score >= 3,
          })}
        >
          Password Strength: {passwordStrengthText}
        </Text>
      </Flex>
      {strength.suggestions.map((suggestion, index) => (
        <Flex key={index} align="center" gap="2">
          <Text size="1" className="text-puntt-gold-dark-8">
            {suggestion}
          </Text>
        </Flex>
      ))}
    </Grid>
  );
}
