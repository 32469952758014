import {
  type BaseTicketMessage,
  type GetUsersResponse,
} from "@mg/schemas/src/christo/catalyst";

import { Markdown } from "../../../../components/Markdown";
import { getAiRuleMap } from "../../../../utils/selections";
import { escapeRegex } from "../../../../utils/tldraw/handlers";

interface Props {
  messageContent: string;
  message?: BaseTicketMessage;
  rule?: string;
  users: GetUsersResponse | undefined;
  mentions?: string[];
}

export const MessageComponent: React.FC<Props> = ({
  message,
  messageContent,
  users,
  mentions,
  rule,
}: Props) => {
  const aiRuleMap = getAiRuleMap();
  const messageMentions = mentions ?? message?.mentions ?? [];
  const mentionedUsers = messageMentions?.map((m) =>
    users?.find((u) => u._id === m),
  );
  // we need to handle the puntt mention here so we look for it in the messageMentions array
  // and then we need to handle the email mentions
  // 63bc994226c323d41d67a11f is the puntt user id
  const processMessageContent = (content: string) => {
    const emails = mentionedUsers
      ?.filter((user): user is NonNullable<typeof user> => user != null)
      .map((user) => user.email)
      .filter(Boolean) as string[];

    const mentionRegex = new RegExp(
      `(^|\\W)@(${[...emails.map(escapeRegex), "puntt"].join("|")})\\b`,
      "gi",
    );

    let match;
    const elements = [];
    let lastIndex = 0;

    while ((match = mentionRegex.exec(content)) !== null) {
      const mention = match[2];
      const mentionIndex = match.index;
      const isStartOfContent =
        mentionIndex === 0 || content[mentionIndex - 1] === "\n";

      // Add the text before the mention
      if (mentionIndex > lastIndex) {
        elements.push(content.slice(lastIndex, mentionIndex));
      }
      if (mention.toLowerCase() === "puntt") {
        // Add a non-breaking space to prevent underline connection between mentions
        if (!isStartOfContent) {
          elements.push(" ");
        }
        elements.push("@puntt");
      } else {
        // Rest of the existing user handling logic
        const user = mentionedUsers?.find(
          (u) => u?.email?.toLowerCase() === mention.toLowerCase(),
        );
        if (user) {
          if (!isStartOfContent) {
            elements.push(" ");
          }
          elements.push(
            `@[${user.name?.replace(/\]/g, "\\]")}](mailto:${user.email?.replace(
              /\)/g,
              "\\)",
            )})`,
          );
        } else {
          if (!isStartOfContent) {
            elements.push(" ");
          }
          elements.push(`@${mention}`);
        }
      }

      lastIndex = mentionIndex + match[0].length;
    }

    // Add any remaining content after the last mention
    if (lastIndex < content.length) {
      elements.push(content.slice(lastIndex));
    }

    return `${renderAiRule()}${elements.join("")}`;
  };

  const processedContent = processMessageContent(messageContent);

  function renderAiRule() {
    if (rule == null || aiRuleMap[rule] == null) {
      return "";
    }

    return `**${aiRuleMap[rule]}:** `;
  }

  return <Markdown>{processedContent}</Markdown>;
};
