import {
  DialogButton,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  ButtonNew,
  Typography,
} from "@mg/dali/src";
import { useNavigate } from "@tanstack/react-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { forwardRef, type MutableRefObject, type Ref } from "react";

import { userProfileRoute } from "../../userProfile/route";

export const CompleteOnboardingDialog = forwardRef<
  HTMLDialogElement,
  DialogProps
>((props, ref) => {
  const navigate = useNavigate();
  const dialogRef = ref as MutableRefObject<HTMLDialogElement>;
  return (
    <Dialog
      open={props.open}
      onClose={(evt) => {
        if (props.onClose) props.onClose(evt);
      }}
      ref={dialogRef}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dialogRef?.current?.close();
          navigate({ to: userProfileRoute.to });
        }}
        className="flex flex-col items-center text-center"
      >
        <DialogContent className="grid gap-6">
          <Typography size="4xl" weight="bold">
            Congrats on completing onboarding!
          </Typography>
          <Typography size="2xl">
            Here are a couple things you can do to jump right in.
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonNew type="submit" theme="primary">
            Complete Your Profile
          </ButtonNew>

          <DialogButton theme="primary" action="cancel">
            Explore My Network
          </DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});

CompleteOnboardingDialog.displayName = "CompleteOnboardingDialog";
