import {
  type UpdateRevisionBoardsResponse,
  type GetRevisionsResponse,
  type GetTicketCommentsResponse,
  type GetTicketResponse,
} from "@mg/schemas/src/christo/catalyst";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type TLStoreSnapshot } from "tldraw";

import { isNil } from "../fp";

type TicketState = {
  value: GetTicketResponse | null;
  comments: GetTicketCommentsResponse;
  visibleCommentShapes: GetTicketCommentsResponse;
  revisions: GetRevisionsResponse;
  revisionScreenshots: GetRevisionsResponse;
  isLoading: boolean;
  activeCommentId: string | null;
  highlightedCommentId: string | null;
  selectedVersionIndex: number | null;
  reversedVersionIndex: number | null;
};

const initialState: TicketState = {
  value: null,
  visibleCommentShapes: [],
  comments: [],
  revisions: [],
  revisionScreenshots: [],
  /**
   * Useful for when flagging that the ticket needs to load something, e.g. a
   * file upload
   */
  isLoading: false,
  activeCommentId: null,
  highlightedCommentId: null,
  selectedVersionIndex: null,
  reversedVersionIndex: null,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTicket(state, action: PayloadAction<GetTicketResponse | null>) {
      state.value = action.payload;

      if (action.payload == null) {
        return;
      }
      document.title = `Puntt | ${action.payload.title}`;

      if (action.payload.revisionBoards != null) {
        state.revisions = action.payload.revisionBoards;
        state.revisionScreenshots = action.payload.revisionBoards;
      }
    },
    resetTicket(state) {
      state.value = null;
      state.revisions = [];
      state.comments = [];
      state.selectedVersionIndex = 0;
      state.reversedVersionIndex = 0;
      document.title = "Puntt";
    },
    setComments(state, action: PayloadAction<GetTicketCommentsResponse>) {
      state.comments = action.payload;
    },
    updateCommentById(
      state,
      action: PayloadAction<GetTicketCommentsResponse[number]>,
    ) {
      const commentIndex = state.comments.findIndex(
        (comment) => comment._id === action.payload._id,
      );

      if (commentIndex === -1) {
        return;
      }

      state.comments = state.comments.toSpliced(
        commentIndex,
        1,
        action.payload,
      );
    },
    setVisibleCommentShapes(
      state,
      action: PayloadAction<GetTicketCommentsResponse>,
    ) {
      state.visibleCommentShapes = action.payload;
    },
    setRevisions(state, action: PayloadAction<GetRevisionsResponse>) {
      state.revisions = action.payload;
      state.revisionScreenshots = action.payload;
    },
    updateRevisionAtIndex(
      state,
      action: PayloadAction<{
        index: number;
        revision: UpdateRevisionBoardsResponse;
        screenshotOnly?: boolean;
      }>,
    ) {
      const { index, revision } = action.payload;

      if (action.payload.screenshotOnly) {
        state.revisionScreenshots = state.revisionScreenshots.toSpliced(
          index,
          1,
          revision,
        );
        return;
      }
      state.revisions = state.revisions.toSpliced(index, 1, revision);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setActiveCommentId(state, action: PayloadAction<string | null>) {
      state.activeCommentId = action.payload;
    },
    setHighlightedCommentId(state, action: PayloadAction<string | null>) {
      state.highlightedCommentId = action.payload;
    },
    updateTicketTitle(state, action: PayloadAction<string>) {
      if (isNil(state.value)) {
        throw new Error("Attempt to update a ticket that doesn't exist");
      }

      state.value.title = action.payload;
    },
    setSelectedVersionIndex(state, action: PayloadAction<number | null>) {
      const { payload } = action;

      if (isNil(payload)) {
        state.selectedVersionIndex = null;
        state.reversedVersionIndex = null;
        return;
      }

      if (payload < 0 || payload > state.revisions.length) {
        throw new Error("Cannot set index out of bounds.");
      }

      state.selectedVersionIndex = payload;
      state.reversedVersionIndex = state.revisions.length - 1 - payload;
    },
    setInitialShapes(state, action: PayloadAction<TLStoreSnapshot>) {
      if (isNil(state.value) || isNil(state.reversedVersionIndex)) {
        throw new Error("Attempt to update a ticket that doesn't exist");
      }

      const { reversedVersionIndex } = state;
      const currentVersion = state.revisions[reversedVersionIndex];

      state.revisions[reversedVersionIndex] = {
        ...currentVersion,
        shapes: action.payload,
      };
    },
  },
});

export const {
  resetTicket,
  setTicket,
  setComments,
  updateCommentById,
  setVisibleCommentShapes,
  setRevisions,
  setLoading,
  updateRevisionAtIndex,
  updateTicketTitle,
  setActiveCommentId,
  setHighlightedCommentId,
  setSelectedVersionIndex,
  setInitialShapes,
} = ticketSlice.actions;
