import {
  createEnterpriseResponseSchema,
  getEnterprisesResponseSchema,
  UpdateEnterpriseSettingsBody,
} from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function createEnterprise(name: string) {
  const requestUrl = `${API_URL}/private/catalyst/internal/enterprises`;
  const json = await smartFetch(requestUrl, {
    method: "POST",
    body: JSON.stringify({ name }),
  });

  return createEnterpriseResponseSchema.parse(json);
}

export async function getEnterprises() {
  const requestUrl = `${API_URL}/private/catalyst/enterprises`;
  const json = await smartFetch(requestUrl);

  return getEnterprisesResponseSchema.parse(json);
}

export async function updateEnterpriseSettings(
  payload: UpdateEnterpriseSettingsBody,
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/enterprises/settings`,
    {
      method: "PUT",
      body: JSON.stringify({
        ...payload,
      }),
    },
  );

  const parsedData = createEnterpriseResponseSchema.parse(json);
  return parsedData;
}

// @ts-expect-error TS2339: `window` is strictly typed
window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
  enterpriseApis: {
    createEnterprise,
    getEnterprises,
    updateEnterpriseSettings,
  },
});
