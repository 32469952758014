import { Box, Grid, ScrollArea, Skeleton, Text } from "@radix-ui/themes";
import cx from "classnames";
import { useEffect } from "react";
import { type TLShapeId } from "tldraw";

import { isNil } from "../../../../../../utils/fp";

type DocumentPageThumbnailsProps = {
  pages: { thumbnail?: string; shapeId: string }[];
  /**
   * Given a shapeId, allows the parent to perform some action on the shapeId
   * (e.g. panning to shape, selecting shape, etc.). This allows us to keep
   * TLDraw relatively decoupled from this component. A second argument, `index`
   * is used only for analytics
   */
  onPageClick(shapeId: string, index: number): void;
  /**
   * Whether or not the document is still being processed by Samba
   */
  loading?: boolean;
  /**
   * The currently selected page shapeId; defaults to the first thumbnail and
   * is assumed to be pre-sorted.
   */
  value?: TLShapeId;
};

export default function DocumentPageThumbnails(
  props: DocumentPageThumbnailsProps,
) {
  const { loading = false, pages, value, onPageClick } = props;

  useEffect(() => {
    // When our value changes and the button whose shapeId isn't in view, scroll
    // it into view.
    if (isNil(value)) return;

    const element = document.getElementById(value.replace(":", "-"));
    if (isNil(element)) return;

    element.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, [value]);

  return (
    <ScrollArea
      type="always"
      scrollbars="vertical"
      className="h-[calc(100vh_-_153px)] w-40 bg-puntt-neutral-gray-8"
      data-testid="current-version-page-thumbnails"
    >
      <Grid gap="2" p="2" pr="4" data-testid="page-thumbnails-container">
        {pages.map(({ thumbnail, shapeId }, i) => (
          <button
            key={`.${i}.${shapeId}`}
            id={shapeId.replace(":", "-")}
            className={cx(
              "cursor-pointer rounded-lg p-1 text-left transition-colors",
              {
                "bg-puntt-blue-5 hover:bg-puntt-blue-6": value === shapeId,
                "bg-transparent hover:bg-puntt-neutral-gray-6":
                  value !== shapeId,
              },
            )}
            data-testid={`page-thumbanil-${shapeId}`}
            onClick={() => {
              if (loading) return;

              onPageClick(shapeId, i);
            }}
            disabled={loading}
          >
            <Skeleton loading={loading}>
              {isNil(thumbnail) && loading ? (
                <Box className="h-36 w-full" />
              ) : (
                <img
                  src={thumbnail}
                  alt={`Thumbnail ${i + 1}`}
                  className="rounded-lg border border-blackA-blackA12"
                  data-testid="page-thumbnail-image"
                />
              )}
            </Skeleton>

            <Skeleton loading={loading}>
              <Text
                as="span"
                size="1"
                weight="medium"
                data-testid="page-thumbnail-page-number"
              >
                Page {i + 1}
              </Text>
            </Skeleton>
          </button>
        ))}
      </Grid>
    </ScrollArea>
  );
}
