import { Popover } from "@headlessui/react";
import {
  ArrowUpRight,
  CaretUp,
  Circle,
  Cursor,
  CursorText,
  File,
  IconContext,
  LineSegment,
  PencilLine,
  Square,
} from "@phosphor-icons/react";
import { Text } from "@radix-ui/themes";
import cx from "classnames";
import { useEffect, useRef } from "react";
import {
  createShapeId,
  type TLImageAsset,
  type TLShapeId,
  type TLVideoAsset,
  track,
  useEditor,
  useTools,
} from "tldraw";

import { AuthTooltip } from "../../../../../../components/AuthTooltip";
import { ReactComponent as ComboLogo } from "../../../../../../images/Handbubble.svg";
import { externalAssetHandler } from "../../../../../../utils/tldraw/handlers";
import { PunttZoomMenu } from "../../../../components/ZoomMenu";

/**
 * A toolbar dedicated for TLDraw
 */
export const Toolbar = track(() => {
  const editor = useEditor();
  const tools = useTools();
  const currentTool = editor.getCurrentToolId();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const toolClasses = cx(
    "bg-transparent rounded-xl text-center [pointer-events:all] h-12 aspect-square flex flex-col justify-center items-center",
    "data-[isactive=true]:bg-egyptian-blue-200",
  );
  const toolLabelClasses = cx("text-[10px] leading-4");

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      switch (e.key) {
        case "Delete":
        case "Backspace": {
          const selectedShapes = editor
            .getSelectedShapes()
            .map((shape) => {
              if (
                shape.type !== "comment" &&
                shape.type !== "comment-avatar" &&
                editor.getEditingShapeId() !== shape.id
              ) {
                return shape.id;
              }
            })
            .filter((s): s is TLShapeId => Boolean(s));

          editor.deleteShapes(selectedShapes);
          break;
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [editor]);

  return (
    <IconContext.Provider value={{ size: 20, className: "mx-auto" }}>
      <div className="pointer-events-none absolute inset-0 z-[300]">
        <section className="absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center gap-2 rounded-xl border bg-base-white p-2">
          <PunttZoomMenu onClick={() => editor.setCurrentTool("zoom")} />
          <button
            className={toolClasses}
            data-isactive={currentTool === "combo"}
            onClick={() => editor.setCurrentTool("combo")}
          >
            <ComboLogo className="hidden xs:block" />
            <Text className={toolLabelClasses}>Comment</Text>
          </button>
          <AuthTooltip>
            <Popover className="relative" data-auth-trigger="toolbar-extended">
              <Popover.Button className={toolClasses}>
                <CaretUp />
              </Popover.Button>

              <Popover.Panel className="absolute bottom-[calc(100%_+_1.2rem)] left-1/2 z-10 flex -translate-x-1/2 gap-2 rounded-2xl border bg-base-white p-2">
                {/* Arrow */}
                <div className="absolute -bottom-2 left-1/2 size-0 -translate-x-1/2 border-x-8 border-t-8 border-x-transparent border-t-base-white drop-shadow-sm" />
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "select"}
                  onClick={() => editor.setCurrentTool("select")}
                >
                  <Cursor />
                  <Text className={toolLabelClasses}>Select</Text>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "text"}
                  onClick={() => editor.setCurrentTool("text")}
                >
                  <CursorText />
                  <Text className={toolLabelClasses}>Text</Text>
                </button>
                <>
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    ref={fileInputRef}
                    onChange={async (
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      const files = event.target.files;
                      if (files && files.length > 0) {
                        const handleImage = externalAssetHandler(editor);
                        Promise.all(
                          Array.from(files).map((file) =>
                            handleImage({ type: "file", file }),
                          ),
                        ).then((assets) => {
                          const filteredAssets = assets.filter(
                            (asset) => asset != null,
                          ) as (TLImageAsset | TLVideoAsset)[]; // Can't be a TLBookmarkAsset

                          editor.createAssets(filteredAssets);
                          filteredAssets.forEach((asset) => {
                            const shapeId = createShapeId();

                            editor.createShape({
                              id: shapeId,
                              type: "image",
                              props: {
                                assetId: asset?.id,
                                h: asset.props.h,
                                w: asset.props.w,
                              },
                            });
                          });
                          editor.selectAll();
                          const selectedShapeIds = editor.getSelectedShapeIds();

                          editor.packShapes(selectedShapeIds, 70);
                          editor.zoomToFit();
                          editor.selectNone();
                        });
                      }
                    }}
                  />
                  <button
                    className={toolClasses}
                    data-isactive={currentTool === "file"}
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                  >
                    <File />
                    <Text className={toolLabelClasses}>File</Text>
                  </button>
                </>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "circle"}
                  onClick={() => tools.ellipse.onSelect("toolbar")}
                >
                  <Circle />
                  <Text className={toolLabelClasses}>Oval</Text>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "rectangle"}
                  onClick={() => tools.rectangle.onSelect("toolbar")}
                >
                  <Square />
                  <Text className={toolLabelClasses}>Rectangle</Text>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "line"}
                  onClick={() => editor.setCurrentTool("line")}
                >
                  <LineSegment />
                  <Text className={toolLabelClasses}>Line</Text>
                </button>

                <button
                  className={toolClasses}
                  data-isactive={currentTool === "arrow"}
                  onClick={() => editor.setCurrentTool("arrow")}
                >
                  <ArrowUpRight />
                  <Text className={toolLabelClasses}>Arrow</Text>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "draw"}
                  onClick={() => editor.setCurrentTool("draw")}
                >
                  <PencilLine />
                  <Text className={toolLabelClasses}>Pencil</Text>
                </button>
              </Popover.Panel>
            </Popover>
          </AuthTooltip>
        </section>
      </div>
    </IconContext.Provider>
  );
});
