/**
 * Runs an async function on a large dataset in batches.
 *
 * @param data
 *   The full list of inputs that would be too large to process all at once.
 * @param batchSize
 *   The number of inputs to process per batch.
 * @param action
 *   The async function that processes the data. It receives two parameters:
 *   the batch of data to process and the index of the batch. It can
 *   optionally return an array of results for the batch (typically one per
 *   item in the batch) or some other batch result.
 * @param concurrency
 *   How many invocations of `action` may run at once. Generally you should
 *   limit this to a number from 1-6 to reduce the risk of freezing,
 *   timeouts, and network contention. Passing `serial` is equivalent to `1`
 *   and `parallel` is equivalent to `0` (no concurrency limit).
 *
 * @returns
 *   The concatenation of the return values for each batch's `action`. For
 *   example, if there are four batches and they return `[1, 2]`, `[3, 4]`,
 *   `5`, and `undefined`, respectively, `Utils.batch` will return
 *   `[1, 2, 3, 4, 5]`. Note that the resulting array is always in the same
 *   order as the `data` input, regardless of the order in which batches
 *   finished processing.
 */
export async function batch<T, U>(
  data: T[],
  batchSize: number,
  action: (d: T[], i: number) => Promise<U[] | U | void>,
  concurrency: number | "serial" | "parallel" = "serial",
): Promise<U[]> {
  if (concurrency === "serial") concurrency = 1;
  if (concurrency === "parallel" || concurrency < 0) concurrency = 0;

  const numChunks = Math.ceil(data.length / batchSize);
  const chunks = new Array<Promise<U[] | U | void>>(numChunks);
  const inProgress: typeof chunks = [];
  for (let i = 0; i < numChunks; i++) {
    // If concurrency is limited, wait for a slot to free up before continuing
    if (concurrency && inProgress.length >= concurrency)
      await Promise.race(inProgress);
    const chunk = (chunks[i] = action(
      data.slice(i * batchSize, (i + 1) * batchSize),
      i,
    ));
    // Free up a concurrency slot when an action is complete
    const chunkProgress = chunk.then((result) => {
      removeFromArray(chunkProgress, inProgress);
      return result;
    });
    inProgress.push(chunkProgress);
  }
  return (await Promise.all(chunks))
    .filter((v): v is U[] => typeof v !== "undefined")
    .flat();
}

/**
 * Removes an element from an array by value.
 *
 * @param item The element to remove.
 * @param array The array from which to remove the specified element.
 *
 * @returns An array of removed elements.
 */
function removeFromArray<T>(item: T, array: T[]): T[] | void {
  const i = array.indexOf(item);
  if (i === undefined || i < 0) {
    return undefined;
  }
  return array.splice(i, 1);
}
