import { type CreatePasswordBody } from "@mg/schemas/src/prince/auth";
import { Button, Grid, Heading, Text, Flex } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate } from "@tanstack/react-router";
import {
  type FormEventHandler,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import zxcvbn from "zxcvbn";

import { LoginLayout } from "../../components/layouts/login";
import { PasswordInput } from "../../components/PasswordInput";
import { PasswordRequirements } from "../../components/PasswordRequirements";
import { useUI } from "../../contexts/ui";
import {
  type UpdatePasswordPayload,
  updatePassword,
} from "../../services/auth";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { useAppSelector } from "../../utils/hooks";
import { listsRoute } from "../lists/route";
import { myNetworkRoute } from "../network/route";
import { ticketsRoute } from "../tickets/route";
import { tutorialTicketsRoute } from "../tutorial/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

function useUpdatePasswordMutation() {
  return useMutation({
    mutationKey: ["update-password"],
    mutationFn: updatePassword,
  });
}

export function AddPasswordView() {
  const { notify } = useUI();
  const localToken = localStorage.getItem("token");
  const user = useAppSelector((state) => state.auth.value);

  const navigate = useNavigate();
  const mutation = useUpdatePasswordMutation();
  useEffect(() => {
    localStorage.setItem("onboarding_flow", "true");
  }, []);
  const formRef = useRef<HTMLFormElement>(null);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState<{
    suggestions: string[];
    score: number;
    password: string;
  }>({
    suggestions: [],
    score: 0,
    password: "",
  });

  const checkPasswordStrength = useCallback((password: string) => {
    const evaluation = zxcvbn(password);
    setPasswordStrength({
      suggestions: evaluation.feedback.suggestions,
      score: evaluation.score,
      password: password,
    });
  }, []);

  const checkValidity = useCallback(() => {
    if (formRef.current == null) return;

    const elements = formRef.current.elements;
    const password = elements.namedItem("password") as HTMLInputElement;
    const confirmPassword = elements.namedItem(
      "confirm_password",
    ) as HTMLInputElement;

    if (password) {
      checkPasswordStrength(password.value);
    }

    if (password && confirmPassword) {
      setPasswordsMatch(password.value === confirmPassword.value);
    }
  }, [checkPasswordStrength]);

  const checkPasswordLength = useCallback(() => {
    if (formRef.current == null) {
      return;
    }

    const elements = formRef.current.elements;
    // @ts-expect-error TS7015: this is totally valid
    const password = elements["password"] as HTMLInputElement;

    return !!password?.value.length;
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);
    // convert the password to sha1 before submitting to the API.
    formData.set("password", formData.get("password") as string);
    // remove the confirm_password since we've already verified they're the
    // same.
    // TODO: this is bad practice--the API should be responsible for checking
    // the passwords are the same, as well as removing the `confirm_password`
    // attribute when it is no longer needed. - JLC 2024-06-21
    formData.delete("confirm_password");

    // convert the FormData to a POJO
    const data = Object.fromEntries(formData.entries()) as CreatePasswordBody &
      UpdatePasswordPayload;

    mutation
      .mutateAsync(data)
      .then(async () => {
        localStorage.setItem("onboarding_flow", "true");
        navigate({ to: tutorialTicketsRoute.to });
      })
      .catch(() => {
        return notify({
          title: "Oops! Something went wrong.",
          message:
            "Please contact the Meaningful Gigs team and we'll get you sorted asap!",
          variant: "error",
        });
      });
  };

  if (user?.isEnterpriseOnboarded) {
    if (canAccessPuntt()) {
      return <Navigate to={ticketsRoute.to} search />;
    }

    if (canAccessCreativeConnect()) {
      return (
        <Navigate
          to={myNetworkRoute.to}
          search={createDefaultNetworkRouteSearch()}
        />
      );
    }

    if (canAccessEditOwnProfile()) {
      return <Navigate to={aboutMeEditRoute.to} />;
    }

    if (canAccessLists()) {
      return <Navigate to={listsRoute.to} />;
    }
  }

  return (
    <LoginLayout>
      <div className="w-full max-w-md">
        <Heading
          as="h1"
          weight="medium"
          className="mb-6 break-words text-xl"
          data-testid="create-account-description"
        >
          Set a password for your new account.
        </Heading>

        <form onSubmit={handleSubmit} ref={formRef} className="grid gap-6">
          <input type="hidden" name="token" value={localToken ?? ""} />

          <Grid gap="2">
            <Text weight="medium" size="2">
              New Password
            </Text>
            <PasswordInput
              required
              placeholder="Enter your password"
              name="password"
              id="password"
              minLength={8}
              disabled={mutation.isPending}
              autoComplete="new-password"
              onChange={(e) => checkPasswordStrength(e.target.value)}
              error={
                mutation.isError ||
                (checkPasswordLength() &&
                  (!Object.values(passwordStrength).every(Boolean) ||
                    passwordStrength.score < 3))
              }
            />
          </Grid>

          <PasswordRequirements strength={passwordStrength} />

          <Grid gap="2">
            <Text weight="medium" size="2">
              Verify New Password
            </Text>
            <PasswordInput
              required
              placeholder="Enter password again"
              variant="classic"
              onInput={checkValidity}
              name="confirm_password"
              id="confirm_password"
              minLength={8}
              disabled={mutation.isPending}
              autoComplete="new-password"
              error={!passwordsMatch}
            />

            {!passwordsMatch && (
              <Flex align="center" gap="2">
                <Text size="2" className="text-puntt-red-10">
                  Passwords do not match
                </Text>
              </Flex>
            )}
          </Grid>

          <Button
            type="submit"
            disabled={
              !passwordsMatch ||
              !Object.values(passwordStrength).every(Boolean) ||
              mutation.isPending
            }
            loading={mutation.isPending}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </LoginLayout>
  );
}
