import { type CreatePasswordBody } from "@mg/schemas/src/prince/auth";
import { Button, Grid, Heading, Text, Flex, Link } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import {
  useNavigate,
  useSearch,
  // eslint-disable-next-line import/named
  Link as RouterLink,
} from "@tanstack/react-router";
import { type FormEventHandler, useState, useRef, useCallback } from "react";
import zxcvbn from "zxcvbn";

import { resetPasswordRoute } from "./route";

import { LoginLayout } from "../../components/layouts/login";
import { PasswordInput } from "../../components/PasswordInput";
import { PasswordRequirements } from "../../components/PasswordRequirements";
import {
  resetWorkosPassword,
  type UpdatePasswordPayload,
} from "../../services/auth";
import { loginRoute } from "../login/route";

function useResetPasswordMutation() {
  return useMutation({
    mutationKey: ["reset-password"],
    mutationFn: resetWorkosPassword,
  });
}

export function ResetPasswordView() {
  // we won't automatically digest the token to apply the user.
  const { token } = useSearch({ from: resetPasswordRoute.to }) as {
    token: string;
  };
  const navigate = useNavigate();
  const mutation = useResetPasswordMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [passwordStrength, setPasswordStrength] = useState<{
    suggestions: string[];
    score: number;
    password: string;
  }>({
    suggestions: [],
    score: 0,
    password: "",
  });

  const checkValidity = useCallback(() => {
    if (formRef.current == null) {
      return;
    }

    const elements = formRef.current.elements;
    // @ts-expect-error TS7015: this is totally valid
    const password = elements["password"] as HTMLInputElement;
    // @ts-expect-error TS7015: this is totally valid
    const confirmPassword = elements["confirm_password"] as HTMLInputElement;

    if (password.value !== confirmPassword.value) {
      return setPasswordsMatch(false);
    }

    return setPasswordsMatch(true);
  }, []);

  const checkPasswordLength = useCallback(() => {
    if (formRef.current == null) {
      return;
    }

    const elements = formRef.current.elements;
    // @ts-expect-error TS7015: this is totally valid
    const password = elements["password"] as HTMLInputElement;

    return !!password?.value.length;
  }, []);
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);
    formData.set("password", formData.get("password") as string);
    // remove the confirm_password since we've already verified they're the
    // same.
    formData.delete("confirm_password");

    // convert the FormData to a POJO
    const data = Object.fromEntries(formData.entries()) as CreatePasswordBody &
      UpdatePasswordPayload;

    mutation
      .mutateAsync(data)
      .then((_response) => {
        navigate({ to: loginRoute.to });
      })
      .catch((error) => {
        let errorMessage;
        if (error instanceof Error) {
          errorMessage = error.message;
        } else {
          errorMessage = `An Unknown Error ${error} occurred`;
        }
        setErrorMessage(errorMessage);
      });
  };
  return (
    <LoginLayout>
      <div className="w-full max-w-md">
        <Heading
          as="h1"
          weight="medium"
          className="mb-6 break-words text-xl"
          data-testid="reset-password-description"
        >
          Reset your password.
        </Heading>

        <form onSubmit={handleSubmit} ref={formRef} className="grid gap-6">
          <input type="hidden" name="token" value={token} />

          <Grid gap="2">
            <Text weight="medium" size="2">
              New Password
            </Text>
            <PasswordInput
              required
              placeholder="Enter your password"
              name="password"
              id="password"
              minLength={8}
              disabled={mutation.isPending}
              autoComplete="new-password"
              onChange={(e) => {
                const password = e.target.value;
                const evaluation = zxcvbn(password);
                setPasswordStrength({
                  suggestions: evaluation.feedback.suggestions,
                  score: evaluation.score,
                  password: password,
                });
              }}
              error={
                mutation.isError ||
                (checkPasswordLength() &&
                  (!Object.values(passwordStrength).every(Boolean) ||
                    passwordStrength.score < 3))
              }
            />
          </Grid>

          {checkPasswordLength() && (
            <PasswordRequirements strength={passwordStrength} />
          )}

          <Grid gap="2">
            <Text weight="medium" size="2">
              Verify New Password
            </Text>
            <PasswordInput
              required
              placeholder="Enter password again"
              variant="classic"
              onInput={checkValidity}
              name="confirm_password"
              id="confirm_password"
              minLength={8}
              disabled={mutation.isPending}
              autoComplete="new-password"
              error={!passwordsMatch}
            />

            {!passwordsMatch && (
              <Flex align="center" gap="2">
                <Text size="2" className="text-puntt-red-10">
                  Passwords do not match
                </Text>
              </Flex>
            )}
          </Grid>

          {mutation.isError && (
            <Text
              size="2"
              className="text-puntt-red-dark-5"
              data-testid="password-error-message"
            >
              {mutation.error?.message ?? errorMessage}
            </Text>
          )}

          <Button
            type="submit"
            disabled={
              !passwordsMatch ||
              mutation.isPending ||
              passwordStrength?.score < 3
            }
            loading={mutation.isPending}
          >
            Reset Password
          </Button>

          <RouterLink to={loginRoute.to} data-testid="login-existing-link">
            <Link size="2" className="text-puntt-blue-12 underline-offset-4">
              <span className="font-medium underline decoration-puntt-accent-5">
                Log In
              </span>{" "}
              to an existing account
            </Link>
          </RouterLink>
        </form>
      </div>
    </LoginLayout>
  );
}
