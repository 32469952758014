import { z } from "zod";

import { enterpriseSchema } from "./enterprise";

export const notificationMailSchema = z.object({
  _id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  recipient: z.string(),
  recipientId: z.string(),
  ticketId: z.string().optional(),
  enterprise: z.union([z.string(), enterpriseSchema]).optional(),
  replyTo: z.string().optional(),

  templateAlias: z.string().optional(),
  templateModel: z.unknown().optional(),

  sentAt: z.coerce.date().optional(),
});

export type NotificationMail = z.infer<typeof notificationMailSchema>;
