import { type Editor, type TLSerializedStore, type TLStore } from "tldraw";

import {
  getFilteredImageShapes,
  getShapesBounds,
  updateCameraBounds,
} from "./handlers";

/**
 * When we're in doc mode, we really want the canvas to behave with typical
 * scrolling behavior. To achieve this, we get the shapes, constrain the camera
 * to the shapes with some padding, and ensure the camera is centered and
 * placed at the top of the document, rather than partially zoomed in.
 */
export function handleUpdateCameraBounds(
  store: TLStore | TLSerializedStore,
  editor: Editor,
) {
  // @ts-expect-error TS2345: they're literally the same thing, TLDraw...
  const imageShapes = getFilteredImageShapes(store);
  const targetBounds = getShapesBounds(imageShapes);
  updateCameraBounds(targetBounds, editor);
}
