import { Text, Progress, Grid } from "@radix-ui/themes";
import { type FC, useState, useEffect } from "react";

interface PendingProgressProps {
  pendingText?: string;
  isError?: boolean;
  expectedDuration?: number;
}

const PendingProgress: FC<PendingProgressProps> = ({
  pendingText,
  isError = false,
  expectedDuration = 34_000, // update every 500ms by default
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const [showError, setShowError] = useState(isError);

  useEffect(() => {
    // Don't set up timers if there's already an error
    if (isError) return;

    // Update the progress bar
    const timer = setInterval(() => {
      setProgressValue((prev) => {
        if (prev >= 99) {
          clearInterval(timer);
          return prev;
        }
        if (prev >= 90) {
          return prev + 0.5;
        }
        if (prev >= 80) {
          return prev + 1;
        }
        return prev + 2;
      });
      // There are at most 68 steps in the progress bar: 40 at 2%, 10 at 1%, 18 at 0.5%
    }, expectedDuration / 68);

    // Show an error if progress doesn't complete in 5 minutes
    const errorTimer = setTimeout(() => setShowError(true), 300_000);

    return () => {
      clearInterval(timer);
      clearTimeout(errorTimer);
    };
  }, [isError]);

  return (
    <Grid className="place-items-center">
      <Grid gap="2" width="400px">
        <Text size="7" className="text-wrap text-center">
          {showError
            ? "Your file was uploaded, but an error occurred while processing. Please refresh, try uploading it again, or contact Meaningful Gigs for assistance."
            : (pendingText ?? "Processing your file for viewing...")}
        </Text>
        {!showError && <Progress size="3" value={progressValue} />}
      </Grid>
    </Grid>
  );
};

export default PendingProgress;
