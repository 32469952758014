import { type BaseTicketComment } from "@mg/schemas/src/christo/catalyst";
import { Robot } from "@phosphor-icons/react";
import cx from "classnames";
import { useMemo } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";

export function CommentPin({
  comment,
  duration,
  onClick,
  trackWidth,
}: {
  comment: BaseTicketComment;
  duration: number;
  onClick: (videoStart: number) => void;
  trackWidth?: number;
}) {
  const left = ((comment?.videoStart ?? 0) / duration) * (trackWidth ?? 0);
  const halfIconWidth = 20 / 2;
  const leftAdjusted = Math.min(
    Math.max(halfIconWidth, left),
    (trackWidth ?? Infinity) - halfIconWidth,
  );
  const { highlightedCommentId, activeCommentId } = useAppSelector(
    (state) => state.ticket,
  );

  const iconComponent = useMemo(() => {
    if (comment.isAI) {
      return (
        <Robot
          className="rounded-full bg-amber-amber10 text-base-black"
          size={16}
        />
      );
    } else {
      return (
        <AvatarWithInitials
          // @ts-expect-error TS2339: schemas type is wrong
          avatar={assetForUser(comment?.createdBy?.avatar)}
          // @ts-expect-error TS2339: schemas type is wrong
          name={comment?.createdBy?.name}
          size={4}
        />
      );
    }
  }, [comment]);

  return (
    <button
      tabIndex={0}
      onClick={() => comment.videoStart != null && onClick(comment.videoStart)}
      onKeyDown={(e) => {
        if (
          (e.key === "Enter" || e.key === " ") &&
          comment.videoStart != null
        ) {
          onClick(comment.videoStart);
        }
      }}
      className={cx(
        "duration-250 absolute flex size-5 -translate-x-1/2 transform items-center justify-center rounded-full transition-transform ease-in-out hover:scale-125",
        {
          "scale-125":
            highlightedCommentId === comment._id ||
            activeCommentId === comment._id,
        },
      )}
      style={{
        left: `${leftAdjusted}px`,
        top: 6,
        backgroundColor: comment?.isRequired
          ? "rgb(var(--puntt-red-9))"
          : "rgb(var(--puntt-cool-gray-8))",
        border: "0.3px solid white",
        transformOrigin: "50% 100%",
      }}
    >
      {iconComponent}
    </button>
  );
}
