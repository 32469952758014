import {
  downloadFolderResponseSchema,
  getBreadcrumbsResponseSchema,
  getFolderTreeResponseSchema,
  ShareFolderBody,
  ShareFolderParams,
  shareFolderResponseSchema,
} from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";
import { parseResponse } from "../utils/parseResponse";

export async function getFolderTree() {
  const json = await smartFetch(`${API_URL}/private/catalyst/folders/tree`, {
    method: "GET",
  });

  const parsedData = parseResponse(getFolderTreeResponseSchema, json) ?? [];
  return parsedData;
}

export async function getFolderBreadcrumbs(folderId?: string) {
  if (folderId == null) {
    return [];
  }
  const token = sessionStorage.getItem(`${folderId}|token`);

  const json = await smartFetch(
    `${API_URL}/private/catalyst/folders/${folderId}/breadcrumbs`,
    {
      method: "GET",
    },
    false,
    { token },
  );

  const parsedData = parseResponse(getBreadcrumbsResponseSchema, json) ?? [];
  return parsedData;
}

export async function getShareFolderLink(folderId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/folders/${folderId}/share`,
    {
      method: "POST",
    },
  );

  const parsedData = parseResponse(shareFolderResponseSchema, json);
  return parsedData;
}

export async function shareFolder(
  payload: ShareFolderBody & ShareFolderParams,
) {
  const { folderId, emails } = payload;

  const json = await smartFetch(
    `${API_URL}/private/catalyst/folders/${folderId}/share`,
    {
      method: "POST",
      body: JSON.stringify({ emails }),
    },
  );

  const parsedData = parseResponse(shareFolderResponseSchema, json);
  return parsedData;
}

export async function getFolderDownloadUrl(folderId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/folders/${folderId}/download`,
    {
      method: "GET",
    },
  );

  const parsedData = parseResponse(downloadFolderResponseSchema, json);
  return parsedData;
}

// @ts-expect-error TS2339: `window` is strictly typed
window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
  folderApis: {
    getFolderTree,
    getFolderBreadcrumbs,
    shareFolder,
    getFolderDownloadUrl,
  },
});
