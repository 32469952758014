import {
  // eslint-disable-next-line import/named
  createRootRouteWithContext,
  Navigate,
  Outlet,
  useSearch,
} from "@tanstack/react-router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { CustomErrorComponent } from "./customErrorComponent";
import { NotFoundComponent } from "./notFoundComponent";

import { AnalyticsProvider } from "../../utils/analytics";
import { useAppSelector } from "../../utils/hooks";
import { searchParser } from "../../utils/parsers/search";
import { useEnterprises } from "../../utils/queries/enterprises";
import { type queryClient } from "../../utils/queryClient";
import { derive } from "../../utils/slices/auth";
import { store } from "../../utils/store";
import { generateUUID } from "../../utils/uuid";

export const rootRoute = createRootRouteWithContext<{
  queryClient: typeof queryClient;
}>()({
  validateSearch(search: Record<string, unknown>) {
    return searchParser.parse(search);
  },
  beforeLoad() {
    store.dispatch(derive());
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const posthog = usePostHog();
    const search = useSearch({ strict: false });
    const { data: enterprises } = useEnterprises();

    const currentEnterprise = enterprises?.find(
      (e) => e._id === user?.enterpriseId,
    );

    useEffect(() => {
      if (user != null) {
        posthog?.identify(user.userID, {
          email: user.email,
          name: user.name,
          role: user.role,
          enterpriseId: currentEnterprise?._id ?? user?.enterpriseId,
          enterpriseName:
            currentEnterprise?.name ?? user.enterpriseName ?? user.company,
          visitId: generateUUID(),
        });

        posthog?.group("enterprise", currentEnterprise?._id as string);
      }
    }, [posthog, user]);

    // remove the token from the URL if it is a shared ticket token
    if (search.t != null && (!user?.ticketId || !user?.folderId)) {
      const { t: _t, ...rest } = search;

      return <Navigate search={rest} />;
    }

    return (
      <AnalyticsProvider>
        <Outlet />
      </AnalyticsProvider>
    );
  },
  errorComponent: CustomErrorComponent,
  notFoundComponent: NotFoundComponent,
});
