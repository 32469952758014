import {
  getUsersResponseSchema,
  updateUserRoleResponseSchema,
} from "@mg/schemas/src/christo/catalyst";
import { type User, userSchema } from "@mg/schemas/src/commons/user";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getUsers(roles?: string[]) {
  const queryParams = new URLSearchParams();

  if (roles) {
    roles?.forEach((role) => queryParams.append("roles", role));
  }

  const requestUrl = `${API_URL}/private/catalyst/users?${queryParams.toString()}`;
  const json = await smartFetch(requestUrl, {
    method: "GET",
  });

  const parsedData = getUsersResponseSchema.parse(json);

  return parsedData;
}

export async function inviteUser(data: {
  enterpriseId: string;
  name: string;
  email: string;
  role: string;
}) {
  const { enterpriseId, ...body } = data;
  const requestUrl = `${API_URL}/private/catalyst/internal/enterprises/${enterpriseId}/invite`;
  const json = (await smartFetch(requestUrl, {
    method: "POST",
    body: JSON.stringify(body),
  })) as User;

  json.createdAt = new Date(json.createdAt as unknown as string);
  json.updatedAt = new Date(json.updatedAt as unknown as string);

  const parsedData = userSchema.parse(json);

  return parsedData;
}

export async function updateCurrentUser(updatedAttributes: Partial<User>) {
  const requestUrl = `${API_URL}/private/users`;
  const json = await smartFetch(requestUrl, {
    method: "PUT",
    body: JSON.stringify(updatedAttributes),
  });

  const parsedData = userSchema.parse(json);

  return parsedData;
}

export async function archiveUser(userId: string) {
  const requestUrl = `${API_URL}/private/users/${userId}`;
  // Returns nothing
  await smartFetch(requestUrl, {
    method: "DELETE",
  });
}

export async function updateUserRole({
  userId,
  role,
}: {
  userId: string;
  role: string;
}) {
  const requestUrl = `${API_URL}/private/catalyst/users/${userId}/role/${role}`;
  const json = await smartFetch(requestUrl, {
    method: "PUT",
  });

  const parsedData = updateUserRoleResponseSchema.parse(json);

  return parsedData;
}

// @ts-expect-error TS2339: `window` is strictly typed
window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
  userApis: {
    getUsers,
    inviteUser,
    updateCurrentUser,
    archiveUser,
    updateUserRole,
  },
});
