import { type GetTicketsResponse } from "@mg/schemas/src/christo/catalyst";
import {
  Clock,
  Robot,
  Users,
  File,
  FilePdf,
  Folders,
  FolderPlus,
  StackPlus,
  FolderOpen,
  Play,
} from "@phosphor-icons/react";
import {
  AspectRatio,
  Avatar,
  Badge,
  Card,
  Flex,
  Grid,
  Heading,
  HoverCard,
  IconButton,
  Text,
  Tooltip,
} from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { Link, useLoaderData } from "@tanstack/react-router";
import cx from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { validate } from "uuid";

import { TicketActionsDropdown } from "./TicketActionsDropdown";

import { ReactComponent as Spinner } from "../../../images/puntt_loader.svg";
import { ReactComponent as BreatheLoader } from "../../../images/puntt_processing_loader.svg";
import { getFoldersAndTickets, getProject } from "../../../services/projects";
import { useAnalytics } from "../../../utils/analytics";
import { isPunttGuest } from "../../../utils/auth";
import {
  getNameInitials,
  SUPPORTED_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from "../../../utils/constants";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { useRevisionMutation } from "../../../utils/queries/projects";
import { authLayoutRoute } from "../../auth-layout/route";
import { ticketRoute } from "../routes/ticket/route";

type TicketGridProps = {
  onDeleteTicket(ticketId: string): void;
  onTicketCardClick(ticketId: string): void;
  onFolderCardClick(folderId: string): void;
  revisionMutation: ReturnType<typeof useRevisionMutation>;
  onDeleteFolder(ticketId: string): void;
  onRenameFolder(folderId: string): void;
  onShareTicket(ticketId: string): void;
  onShareFolder(folderId: string): void;
  onDownloadFolder(folderId: string): void;
  onDownloadTicket(ticketId: string): void;
  onDropCards(selectedIds: string[], targetId: string): Promise<void>;
  onManageTicketVersions(ticketId: string): void;
};

interface SelectionRect {
  startX: number;
  startY: number;
  currentX: number;
  currentY: number;
}

interface CardRect {
  id: string;
  rect: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
}

export function TicketGrid(props: TicketGridProps) {
  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);

  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const {
    onDeleteTicket,
    onTicketCardClick,
    onFolderCardClick,
    revisionMutation,
    onDeleteFolder,
    onRenameFolder,
    onShareTicket,
    onShareFolder,
    onDownloadFolder,
    onDownloadTicket,
    onDropCards,
    onManageTicketVersions,
  } = props;

  const posthog = useAnalytics();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lastSelectedId, setLastSelectedId] = useState<string>();
  const [selectionRect, setSelectionRect] = useState<SelectionRect>();
  const [isDraggingCards, setIsDraggingCards] = useState(false);
  const gridRef = useRef<HTMLDivElement>(null);
  const [dropTargetId, setDropTargetId] = useState<string>();
  const selectionBoxRef = useRef<HTMLDivElement>(null);
  const [cardRects, setCardRects] = useState<CardRect[]>([]);
  const [isInProgress, setIsInProgress] = useState(new Set<string>());
  const [isRemoving, setIsRemoving] = useState(new Set<string>());
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isModifierKeyPressed, setIsModifierKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        setIsModifierKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        setIsModifierKeyPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // If no element is focused or focus is in the grid, clear the selection when Escape is pressed
      if (
        document.activeElement === document.body ||
        document.activeElement?.closest("#tickets-grid")
      ) {
        if (e.key === "Escape") {
          posthog.capture("cleared_ticket_selection", {
            num_selected: selectedIds.length,
            was_dragging: isDraggingCards,
            method: "escape",
          });
          setSelectedIds([]);
          setIsDraggingCards(false);
          setDropTargetId(undefined);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Update card positions when grid size changes or tickets change
  useEffect(() => {
    if (!gridRef.current) return;

    const updateCardRects = () => {
      if (!gridRef.current) return;
      const gridRect = gridRef.current?.getBoundingClientRect();
      if (!gridRect) return;

      const newRects = Array.from(
        gridRef.current.querySelectorAll<HTMLElement>("[data-card-id]"),
      ).map((card) => {
        const rect = card.getBoundingClientRect();
        return {
          id: card.getAttribute("data-card-id") || "",
          rect: {
            left: rect.left - gridRect.left,
            top: rect.top - gridRect.top,
            right: rect.right - gridRect.left,
            bottom: rect.bottom - gridRect.top,
          },
        };
      });
      setCardRects(newRects);
    };

    // Initial calculation
    updateCardRects();

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateCardRects);
    resizeObserver.observe(gridRef.current);

    return () => resizeObserver.disconnect();
  }, [mergedFoldersAndTickets]); // Recalculate when tickets change

  // Update mouse position handler
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDraggingCards && selectionRect) {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, [isDraggingCards, selectionRect]);

  const selectCardsInDragArea = useCallback(
    (newSelectionRect: SelectionRect, addToSelection = false) => {
      // Calculate selection bounds
      const left = Math.min(newSelectionRect.startX, newSelectionRect.currentX);
      const right = Math.max(
        newSelectionRect.startX,
        newSelectionRect.currentX,
      );
      const top = Math.min(newSelectionRect.startY, newSelectionRect.currentY);
      const bottom = Math.max(
        newSelectionRect.startY,
        newSelectionRect.currentY,
      );

      // Find intersecting cards
      const intersectingIds = cardRects
        .filter(({ rect }) => {
          return !(
            rect.right < left ||
            rect.left > right ||
            rect.bottom < top ||
            rect.top > bottom
          );
        })
        .map(({ id }) => id)
        .filter((id) => !isInProgress.has(id) && !isRemoving.has(id));

      // Update selection - if Ctrl/Cmd is held, add to existing selection
      if (addToSelection) {
        setSelectedIds((prev) =>
          Array.from(new Set([...prev, ...intersectingIds])),
        );
      } else {
        setSelectedIds(intersectingIds);
      }
      if (intersectingIds.length) {
        setLastSelectedId(intersectingIds[0]);
      }
    },
    [cardRects, isInProgress, isRemoving],
  );

  // Modify scroll handler to use tracked mouse position
  useEffect(() => {
    if (!selectionRect) return;

    const handleScroll = () => {
      if (!gridRef.current) return;
      const gridRect = gridRef.current?.getBoundingClientRect();
      if (!gridRect) return;

      const newSelectionRect = {
        ...(selectionRect as SelectionRect),
        currentX: mousePosition.x - gridRect.left,
        currentY: mousePosition.y - gridRect.top,
      };
      setSelectionRect(newSelectionRect);

      selectCardsInDragArea(newSelectionRect, isModifierKeyPressed);
    };

    document.addEventListener("scroll", handleScroll, true);
    return () => document.removeEventListener("scroll", handleScroll, true);
  }, [selectionRect, mousePosition, isModifierKeyPressed]);

  const handleMouseDown = (e: React.MouseEvent) => {
    // Ignore if clicking on a card
    if ((e.target as HTMLElement).closest("[data-card-id]")) return;

    const rect = gridRef.current?.getBoundingClientRect();
    if (!rect) return;

    // Clear selection when clicking empty space, unless Ctrl/Cmd is held
    if (!e.metaKey && !e.ctrlKey) {
      setSelectedIds([]);

      posthog.capture("cleared_ticket_selection", {
        num_selected: selectedIds.length,
        was_dragging: isDraggingCards,
        method: "click",
      });
    }

    setSelectionRect({
      startX: e.clientX - rect.left,
      startY: e.clientY - rect.top,
      currentX: e.clientX - rect.left,
      currentY: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingCards && selectionRect) {
      const rect = gridRef.current?.getBoundingClientRect();
      if (!rect) return;

      const newSelectionRect = {
        ...(selectionRect ?? {
          startX: e.clientX - rect.left,
          startY: e.clientY - rect.top,
        }),
        currentX: e.clientX - rect.left,
        currentY: e.clientY - rect.top,
      };
      setSelectionRect(newSelectionRect);

      selectCardsInDragArea(newSelectionRect, e.ctrlKey || e.metaKey);
    }
  };

  const handleMouseUp = () => {
    if (selectionRect) {
      posthog.capture("finish_drag_selection", {
        total_selected: selectedIds.length,
        drag_area: Math.abs(
          (selectionRect.currentX - selectionRect.startX) *
            (selectionRect.currentY - selectionRect.startY),
        ),
      });

      setSelectionRect(undefined);
    }
  };

  const handleCardClick = (id: string, e: React.MouseEvent) => {
    // Double click
    if (e.detail === 2) {
      // When the id is a UUID, that means we do not have the resource created
      // in the database yet, or the API has not responded with the real ID of
      // the resource. This means we cannot technically navigate to it, so we
      // just return early.
      if (validate(id)) return;

      const ticket = mergedFoldersAndTickets.find((t) => t._id === id);
      if (ticket?.isFolder) {
        onFolderCardClick(id);
      } else {
        onTicketCardClick(id);
      }
      posthog.capture("double_clicked_tickets_card", {
        id: id,
        is_folder: ticket?.isFolder,
        name: ticket && ("name" in ticket ? ticket.name : ticket.title),
      });
      return;
    }

    const initialSelectionCount = selectedIds.length;
    let finalSelectionCount = initialSelectionCount;
    if (e.metaKey || e.ctrlKey) {
      if (!isInProgress.has(id) && !isRemoving.has(id)) {
        // Add/remove from selection without clearing other selections
        setSelectedIds((prev) =>
          prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id],
        );
        setLastSelectedId(id);
        finalSelectionCount++;
      }
    } else if (e.shiftKey && lastSelectedId) {
      // Range selection
      const allIds = mergedFoldersAndTickets.map((t) => t._id);
      const start = allIds.indexOf(lastSelectedId);
      const end = allIds.indexOf(id);
      const range = allIds
        .slice(Math.min(start, end), Math.max(start, end) + 1)
        .filter((v) => !isInProgress.has(v) && !isRemoving.has(v));
      setSelectedIds((prev) => Array.from(new Set([...prev, ...range])));
      setLastSelectedId(id);
      finalSelectionCount += range.length;
    } else if (!isInProgress.has(id) && !isRemoving.has(id)) {
      // Single selection - clear others
      setSelectedIds([id]);
      setLastSelectedId(id);
      finalSelectionCount = 1;
    }
    posthog.capture("clicked_tickets_card", {
      id: id,
      is_selectable: !isInProgress.has(id) && !isRemoving.has(id),
      add_to_selection: e.metaKey || e.ctrlKey,
      range_selection: lastSelectedId && e.shiftKey,
      initial_selection_count: initialSelectionCount,
      final_selection_count: finalSelectionCount,
    });
  };

  const handleDragStart = (e: React.DragEvent, id: string) => {
    if (
      !selectedIds.includes(id) &&
      !isInProgress.has(id) &&
      !isRemoving.has(id)
    ) {
      setSelectedIds([id]);
    }
    e.dataTransfer.effectAllowed = "move";
    setIsDraggingCards(true);
  };

  const handleDragEnter = (e: React.DragEvent, id: string) => {
    e.preventDefault();
    // Set drop target to the entered card if it's not already selected, being
    // moved or combined (isRemoving), or a folder with tickets being moved
    // into it (isInProgress). We check isInProgress because if we dropped
    // cards into a folder that already is in progress with cards being moved
    // into it, we would stop showing the folder's spinner when the first move
    // completed, rather than when they all do. This could be improved in the
    // future to track all moves.
    if (
      !selectedIds.includes(id) &&
      !isInProgress.has(id) &&
      !isRemoving.has(id) &&
      isDraggingCards
    ) {
      const ticket = mergedFoldersAndTickets.find((t) => t._id === id);
      // Allow dropping any number of cards into a folder, or a single ticket into another ticket
      if (
        ticket?.isFolder ||
        (selectedIds.length === 1 &&
          !mergedFoldersAndTickets.find((t) => t._id === selectedIds[0])
            ?.isFolder)
      ) {
        setDropTargetId(id);
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent, id: string) => {
    e.preventDefault();
    // Only clear the drop target if we're actually leaving the card element
    if (
      dropTargetId === id &&
      !e.currentTarget.contains(e.relatedTarget as Node)
    ) {
      setDropTargetId(undefined);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e: React.DragEvent, id: string) => {
    e.preventDefault();
    if (
      isDraggingCards &&
      selectedIds.length > 0 &&
      !selectedIds.includes(id) &&
      !isInProgress.has(id) && // See handleDragEnter for explanation
      !isRemoving.has(id)
    ) {
      setSelectedIds([]);
      setIsInProgress((prev) => new Set(Array.from(prev).concat(id)));
      setIsRemoving((prev) => new Set(Array.from(prev).concat(selectedIds)));
      onDropCards(selectedIds, id).finally(() => {
        setIsInProgress((prev) => {
          prev.delete(id);
          return new Set(prev);
        });
        setIsRemoving((prev) => {
          selectedIds.forEach((v) => prev.delete(v));
          return new Set(prev);
        });
      });
    }
    setIsDraggingCards(false);
    setDropTargetId(undefined);
  };

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      ref={gridRef}
      className="relative min-h-[calc(100vh-155px)] px-10"
      role="grid"
      id="tickets-grid"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <Flex gap="5" wrap="wrap">
        {mergedFoldersAndTickets.map((t, i) => {
          if (validate(t._id)) {
            if (t.isFolder && "name" in t) {
              return <SkeletonFolder key={t.name} directoryName={t.name} />;
            }
            const title = "title" in t ? t.title : t.name;

            return (
              <SkeletonTicket
                key={`.${i}.${title}`}
                title={title}
                documentImportType={title
                  .slice(title.lastIndexOf(".") + 1)
                  .toLocaleUpperCase()}
              />
            );
          }

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={t._id}
              data-card-id={t._id}
              className={cx("relative transition-all duration-150", {
                "ring-2 ring-puntt-blue-9": selectedIds.includes(t._id),
                "opacity-50": isDraggingCards && selectedIds.includes(t._id),
              })}
              style={{
                borderRadius: "var(--radius-4)",
              }}
              draggable={true}
              onDragStart={(e) => handleDragStart(e, t._id)}
              onDragEnter={(e) => handleDragEnter(e, t._id)}
              onDragLeave={(e) => handleDragLeave(e, t._id)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, t._id)}
              onClick={(e) => handleCardClick(t._id, e)}
            >
              <TicketCard
                key={t._id}
                {...t}
                createdAt={t.createdAt?.toString() ?? ""}
                updatedAt={t.updatedAt?.toString() ?? ""}
                title={"name" in t ? t.name : t.title}
                isPending={false}
                isInProgress={isInProgress.has(t._id)}
                isRemoving={isRemoving.has(t._id)}
                isDropTarget={dropTargetId === t._id}
                totalRevisions={"totalRevisions" in t ? t.totalRevisions : 0}
                onDropCards={onDropCards}
                onDeleteTicket={onDeleteTicket}
                revisionMutation={revisionMutation}
                onDeleteFolder={onDeleteFolder}
                onRenameFolder={onRenameFolder}
                onShareTicket={onShareTicket}
                onShareFolder={onShareFolder}
                onDownloadFolder={onDownloadFolder}
                onDownloadTicket={onDownloadTicket}
                onManageTicketVersions={onManageTicketVersions}
              />
            </div>
          );
        })}
      </Flex>

      {/* Selection rectangle */}
      {selectionRect && (
        <div
          ref={selectionBoxRef}
          className="pointer-events-none absolute border-2 border-puntt-blue-9 bg-egyptian-blue-500/20"
          style={{
            left: Math.min(selectionRect.startX, selectionRect.currentX),
            top: Math.min(selectionRect.startY, selectionRect.currentY),
            width: Math.abs(selectionRect.currentX - selectionRect.startX),
            height: Math.abs(selectionRect.currentY - selectionRect.startY),
          }}
        />
      )}
    </div>
  );
}

function loadingAction(
  isUploading: boolean,
  isLoading: boolean,
  isInProgress: boolean,
  isRemoving: boolean,
) {
  if (isUploading) {
    return "Uploading…";
  }
  if (isLoading) {
    return "Preparing…";
  }
  if (isInProgress) {
    return "Updating…";
  }
  if (isRemoving) {
    return "Relocating…";
  }
  return null;
}

type SkeletonTicketProps = {
  title: string;
  documentImportType: string;
};

function SkeletonTicket(props: SkeletonTicketProps) {
  const { title, documentImportType } = props;

  return (
    <Card className="select-none">
      <Grid width="284px" className="fit-content" gap="4">
        <AspectRatio ratio={16 / 9} className="grid place-content-center">
          <Grid className="box-border size-full place-content-center place-items-center gap-3 rounded-lg pt-4">
            <Spinner />
            <Text size="1" className="text-puntt-cool-gray-11">
              {loadingAction(true, false, false, false)}
            </Text>
          </Grid>
        </AspectRatio>

        <Grid gap="4">
          <Heading size="3" className="truncate">
            {title}
          </Heading>
          <Text size="2">{documentImportType}</Text>
        </Grid>
      </Grid>
    </Card>
  );
}

function SkeletonFolder(props: { directoryName: string }) {
  const { directoryName } = props;

  return (
    <Card className="select-none">
      <Grid width="284px" className="fit-content" gap="4">
        {/* Folder tab dip */}
        <div className="absolute right-0 top-0 z-[100] h-6 w-[10.5rem] cursor-default rounded-bl-full rounded-tr-lg border border-b-puntt-neutral-gray-6 border-l-puntt-neutral-gray-6 bg-puntt-neutral-gray-3 before:absolute before:left-[calc(-1px-1.75rem)] before:top-0 before:border-l-[3rem] before:border-t-[calc(1.5rem-1px)] before:border-l-transparent before:border-t-puntt-neutral-gray-6 after:absolute after:-left-7 after:-top-px after:border-l-[3rem] after:border-t-[1.5rem] after:border-l-transparent after:border-t-puntt-neutral-gray-3" />

        <AspectRatio ratio={16 / 9} className="grid place-content-center">
          <Grid className="box-border size-full place-content-center place-items-center gap-3 rounded-lg pt-4">
            <Spinner />
            <Text size="1" className="text-puntt-cool-gray-11">
              {loadingAction(true, false, false, false)}
            </Text>
          </Grid>
        </AspectRatio>

        <Grid gap="4">
          <Heading size="3" className="truncate">
            {directoryName}
          </Heading>
        </Grid>
      </Grid>
    </Card>
  );
}

// Global state to track thumbnail loading
const pendingThumbnails: string[] = [];
const pendingFolderThumbnails: string[] = [];
const thumbnailCache = new Map<string, string[]>();
let isLoadingBatch = false;

async function loadThumbnailBatch() {
  if (isLoadingBatch) return;
  isLoadingBatch = true;

  try {
    // Take up to 5 pending thumbnails
    const batchIds = Array.from(pendingThumbnails).slice(0, 5);
    if (batchIds.length === 0) return;

    // Load thumbnails in parallel
    const results = await Promise.all(
      batchIds.map(async (id) => {
        try {
          const ticket = pendingFolderThumbnails.includes(id)
            ? await getFoldersAndTickets({ folderId: id }, id)
            : await getProject(id);

          if (!ticket) return;
          if (ticket.thumbnails?.length) {
            thumbnailCache.set(id, ticket.thumbnails);
            pendingThumbnails.splice(pendingThumbnails.indexOf(id), 1);
          }
          return { id, success: true };
        } catch (error) {
          console.error("Error fetching ticket:", error);
          pendingThumbnails.splice(pendingThumbnails.indexOf(id), 1);
          return { id, success: false };
        }
      }),
    );

    // Force re-render of components waiting for these thumbnails
    document.dispatchEvent(
      new CustomEvent("thumbnailsUpdated", {
        detail: { ids: results.map((r) => r?.id) },
      }),
    );
  } finally {
    isLoadingBatch = false;
  }
}

// Start periodic loading of thumbnail batches
setInterval(loadThumbnailBatch, 5000);

function ThumbnailLoading(props: { ticketId: string; isFolder: boolean }) {
  const [thumbnails, setThumbnails] = useState<string[] | null>(null);
  const [isError, setIsError] = useState(false);
  const [, setTries] = useState(0);

  useEffect(() => {
    // Check cache first
    const cached = thumbnailCache.get(props.ticketId);
    if (cached) {
      setThumbnails(cached);
      return;
    }

    // Add to pending set if not already loading
    if (!pendingThumbnails.includes(props.ticketId)) {
      pendingThumbnails.push(props.ticketId);
      if (props.isFolder) {
        pendingFolderThumbnails.push(props.ticketId);
      }
    }

    // Listen for updates
    const handleUpdate = (e: CustomEvent) => {
      if (e.detail.ids.includes(props.ticketId)) {
        const newThumbnails = thumbnailCache.get(props.ticketId);
        if (newThumbnails) {
          setThumbnails(newThumbnails);
        } else {
          setTries((t) => {
            if (t >= 60) {
              // 5 minutes (5s * 60)
              setIsError(true);
              pendingThumbnails.splice(
                pendingThumbnails.indexOf(props.ticketId),
                1,
              );
              console.error(
                "Timed out fetching thumbnail for ticket",
                props.ticketId,
              );
              return t;
            }
            return t + 1;
          });
        }
      }
    };

    document.addEventListener(
      "thumbnailsUpdated",
      handleUpdate as EventListener,
    );
    return () => {
      document.removeEventListener(
        "thumbnailsUpdated",
        handleUpdate as EventListener,
      );
      pendingThumbnails.splice(pendingThumbnails.indexOf(props.ticketId), 1);
    };
  }, [props.ticketId]);

  if (isError) {
    return <File className="text-puntt-cool-gray-11" size={46} />;
  }
  if (thumbnails?.length) {
    if (!props.isFolder) {
      return (
        <img
          src={thumbnails[0]}
          alt="Ticket thumbnail"
          className="pointer-events-none size-full select-none object-cover"
        />
      );
    }
    return (
      <Flex gap="3" className="size-full rounded-xl bg-puntt-cool-gray-5 p-2">
        <Grid className="max-w-[11.25rem] place-content-center">
          <img
            alt="Thumbnail 1 in folder"
            src={thumbnails[0]}
            className="block size-full rounded-xl border border-puntt-neutral-gray-6 object-cover"
          />
        </Grid>
        <Flex gap="3" direction="column" className="h-full w-[8.5rem]">
          {thumbnails[1] ? (
            <AspectRatio ratio={4 / 3}>
              <img
                alt="Thumbnail 2 in folder"
                src={thumbnails[1]}
                className="size-full rounded-xl border border-puntt-neutral-gray-6 object-cover"
              />
            </AspectRatio>
          ) : null}
          <div className="flex h-full items-center justify-center rounded-xl bg-puntt-cool-gray-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="rounded-xl text-puntt-cool-gray-1"
            >
              <circle cx="5" cy="12" r="2" fill="currentColor" />
              <circle cx="12" cy="12" r="2" fill="currentColor" />
              <circle cx="19" cy="12" r="2" fill="currentColor" />
            </svg>
          </div>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {/* For some reason, CI and local Prettier disagree on this class order. */}
      {/* prettier-ignore */}
      <div className="origin-center animate-shrink-grow">
        <BreatheLoader />
      </div>
      <Text size="1" className="text-puntt-cool-gray-11">
        Generating thumbnail&hellip;
      </Text>
    </>
  );
}

export function TicketCard(
  props: GetTicketsResponse[number] &
    Omit<
      TicketGridProps,
      "tickets" | "onFolderCardClick" | "onTicketCardClick"
    > & {
      isFolder?: boolean;
      isInProgress: boolean;
      isRemoving: boolean;
      isDropTarget: boolean;
    },
) {
  const {
    _id,
    documentImportType,
    participants,
    thumbnails = [],
    title,
    totalFiles,
    totalRevisions,
    createdAt,
    updatedAt,
    onDeleteTicket,
    revisionMutation,
    isFolder,
    onDeleteFolder,
    onRenameFolder,
    onShareFolder,
    onShareTicket,
    onDownloadFolder,
    onDownloadTicket,
    isInProgress,
    isRemoving,
    isDropTarget,
    onManageTicketVersions,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  // It'd be nice if the backend gave us info from nested folders, but it
  // doesn't because it's slow. Since we already have the folder tree for the
  // AppDrawer, we just use that to check if this folder has any child folders.
  // This is used to decide what icon to show in the folder card.
  const { folderTree } = useLoaderData({ from: authLayoutRoute.id });
  function findFolder(
    folder: (typeof folderTree)[number],
  ): (typeof folderTree)[number] | undefined {
    if (folder._id === _id) {
      return folder;
    }
    for (const childFolder of folder.folders ?? []) {
      const found = findFolder(childFolder);
      if (found) return found;
    }
  }
  const hasChildFolders = Boolean(
    findFolder({ _id: "", name: "", folders: folderTree })?.folders?.length,
  );

  useEffect(() => {
    if (revisionMutation.isSuccess) {
      setIsLoading(false);
    }
  }, [revisionMutation.isSuccess]);

  function renderRevision() {
    if (totalRevisions < 2) {
      return null;
    }

    return (
      <Badge size="1" color="gray" variant="solid">
        V{totalRevisions}
      </Badge>
    );
  }

  function renderDocType() {
    if (totalFiles > 1) {
      return <Text size="2">{totalFiles} Files</Text>;
    }

    if (totalFiles === 1) {
      return <Text size="2">{documentImportType}</Text>;
    }

    if (documentImportType === "" && totalFiles === 0) {
      return (
        <Text size="2">
          {title.includes(".")
            ? title.slice(title.lastIndexOf(".") + 1).toLocaleUpperCase()
            : "EMPTY"}
        </Text>
      );
    }

    return null;
  }

  const folderCard = () => (
    <Card
      className={cx(
        "relative cursor-pointer select-none overflow-hidden transition-colors hover:bg-puntt-blue-3 active:bg-puntt-blue-4",
        {
          "opacity-30": isRemoving,
        },
      )}
    >
      {/* Folder tab dip */}
      <div className="absolute right-0 top-0 z-[100] h-6 w-[10.5rem] cursor-default rounded-bl-full rounded-tr-lg border border-b-puntt-neutral-gray-6 border-l-puntt-neutral-gray-6 bg-puntt-neutral-gray-3 before:absolute before:left-[calc(-1px-1.75rem)] before:top-0 before:border-l-[3rem] before:border-t-[calc(1.5rem-1px)] before:border-l-transparent before:border-t-puntt-neutral-gray-6 after:absolute after:-left-7 after:-top-px after:border-l-[3rem] after:border-t-[1.5rem] after:border-l-transparent after:border-t-puntt-neutral-gray-3" />

      <Grid
        width="284px"
        height="164px"
        maxHeight="164px"
        gap="4"
        className="mb-3 overflow-hidden pt-6"
      >
        <AspectRatio
          ratio={81 / 40}
          className={cx("overflow-hidden", {
            "grid place-content-center":
              isLoading || isInProgress || isRemoving,
          })}
          style={
            isRemoving
              ? {
                  ["--loader-spinner-color" as string]:
                    "rgb(var(--puntt-red-9))",
                }
              : undefined
          }
        >
          {isLoading || isInProgress || isRemoving ? (
            <Grid className="size-full place-content-center place-items-center gap-3 rounded-lg">
              <Spinner />
              <Text size="1" className="text-puntt-cool-gray-11">
                {loadingAction(false, isLoading, isInProgress, isRemoving)}
              </Text>
            </Grid>
          ) : isDropTarget ? (
            <Grid className="size-full place-content-center place-items-center gap-3 rounded-lg bg-puntt-blue-9">
              <FolderPlus className="text-base-white" size={46} />
              <Text size="2" className="text-base-white">
                Add to folder
              </Text>
            </Grid>
          ) : thumbnails[0]?.endsWith("pdf") || !thumbnails[0]?.length ? (
            <Grid className="size-full place-content-center place-items-center rounded-lg bg-puntt-cool-gray-5">
              {thumbnails[0]?.endsWith("pdf") ? (
                <FilePdf className="text-puntt-cool-gray-11" size={46} />
              ) : totalFiles === 0 ? (
                <Grid className="size-full place-content-center place-items-center gap-3 rounded-lg">
                  {hasChildFolders ? (
                    <>
                      <Folders className="text-puntt-cool-gray-11" size={46} />
                    </>
                  ) : (
                    <>
                      <FolderOpen
                        className="text-puntt-cool-gray-11"
                        size={46}
                      />
                      <Text size="1" className="text-puntt-cool-gray-11">
                        No files yet
                      </Text>
                    </>
                  )}
                </Grid>
              ) : new Date(createdAt).getTime() <
                Date.now() - 1000 * 60 * 10 ? (
                <File className="text-puntt-cool-gray-11" size={46} />
              ) : (
                <ThumbnailLoading ticketId={_id} isFolder={true} />
              )}
            </Grid>
          ) : (
            <Flex
              gap="3"
              className="size-full rounded-xl bg-puntt-cool-gray-5 p-2"
            >
              <Grid className="max-w-[11.25rem] place-content-center">
                <img
                  alt="Thumbnail 1 in folder"
                  src={thumbnails[0]}
                  className="block size-full rounded-xl border border-puntt-neutral-gray-6 object-cover"
                />
              </Grid>
              <Flex gap="3" direction="column" className="h-full w-[8.5rem]">
                {thumbnails[1] ? (
                  <AspectRatio ratio={4 / 3}>
                    <img
                      alt="Thumbnail 2 in folder"
                      src={thumbnails[1]}
                      className="size-full rounded-xl border border-puntt-neutral-gray-6 object-cover"
                    />
                  </AspectRatio>
                ) : null}
                <div className="flex h-full items-center justify-center rounded-xl bg-puntt-cool-gray-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="rounded-xl text-puntt-cool-gray-1"
                  >
                    <circle cx="5" cy="12" r="2" fill="currentColor" />
                    <circle cx="12" cy="12" r="2" fill="currentColor" />
                    <circle cx="19" cy="12" r="2" fill="currentColor" />
                  </svg>
                </div>
              </Flex>
            </Flex>
          )}
        </AspectRatio>
      </Grid>

      <Grid gap="4">
        <Flex gap="4" justify="between" align="center">
          <Heading as="h3" size="3" className="max-w-44 truncate">
            <Link
              search={{
                folderId: _id,
              }}
            >
              {/* @ts-expect-error TS:2399 its going off of ticket props */}
              {props.name}
            </Link>
          </Heading>
          <TicketActionsDropdown
            className={cx({ hidden: isPunttGuest() })}
            onDeleteTicket={() => onDeleteFolder(_id)}
            onRename={() => onRenameFolder(_id)}
            onShare={() => (isFolder ? onShareFolder(_id) : onShareTicket(_id))}
            onDownload={() => onDownloadFolder(_id)}
          />
        </Flex>

        <Flex justify="between" align="center" minHeight="1.25rem">
          <span />
          <Flex gap="4">
            <Tooltip
              content={`Last updated: ${
                updatedAt !== ""
                  ? formatDistanceToNow(updatedAt, {
                      addSuffix: true,
                    })
                  : "--"
              }`}
            >
              <IconButton variant="ghost" color="gray">
                <Clock />
              </IconButton>
            </Tooltip>
            {(participants ?? []).length ? (
              <HoverCard.Root>
                <HoverCard.Trigger>
                  <IconButton variant="ghost" color="gray">
                    <Users />
                  </IconButton>
                </HoverCard.Trigger>
                <HoverCard.Content className="dark" side="top">
                  <Grid gap="2">
                    {/* @ts-expect-error TS2339: schemas package types are wrong */}
                    {participants?.map(({ _id, avatar, name }) => (
                      <Flex gap="3" align="center" key={_id}>
                        <Avatar
                          size="1"
                          variant={name === "AI Reviewer" ? "solid" : "soft"}
                          color={name === "AI Reviewer" ? "amber" : "red"}
                          src={assetForUser(avatar)}
                          fallback={
                            name === "AI Reviewer" ? (
                              <Robot />
                            ) : (
                              getNameInitials(name)
                            )
                          }
                        />
                        <Text size="1">{name}</Text>
                      </Flex>
                    ))}
                  </Grid>
                </HoverCard.Content>
              </HoverCard.Root>
            ) : null}
          </Flex>
        </Flex>
      </Grid>
    </Card>
  );

  return (
    <>
      {isFolder ? (
        folderCard()
      ) : (
        <Card
          className={cx(
            "cursor-pointer select-none transition-colors hover:bg-puntt-blue-3 active:bg-puntt-blue-4",
            {
              "opacity-30": isRemoving,
            },
          )}
        >
          <Grid width="284px" height="fit-content" gap="4">
            <AspectRatio
              ratio={16 / 9}
              className={cx("overflow-hidden", {
                "grid place-content-center":
                  isLoading || isInProgress || isRemoving,
              })}
              style={
                isRemoving
                  ? {
                      ["--loader-spinner-color" as string]:
                        "rgb(var(--puntt-red-9))",
                    }
                  : undefined
              }
            >
              {isLoading || isInProgress || isRemoving ? (
                <Grid className="size-full place-content-center place-items-center gap-3 rounded-lg">
                  <Spinner />
                  <Text size="1" className="text-puntt-cool-gray-11">
                    {loadingAction(false, isLoading, isInProgress, isRemoving)}
                  </Text>
                </Grid>
              ) : isDropTarget ? (
                <Grid className="size-full place-content-center place-items-center gap-3 rounded-lg bg-puntt-blue-9">
                  <StackPlus className="text-base-white" size={46} />
                  <Text size="2" className="text-base-white">
                    Add as V{totalRevisions + 1}
                  </Text>
                </Grid>
              ) : thumbnails[0]?.endsWith(".pdf") || !thumbnails[0]?.length ? (
                <Grid
                  className="size-full place-content-center place-items-center rounded-lg bg-puntt-cool-gray-5"
                  gap="3"
                >
                  {thumbnails[0]?.endsWith(".pdf") ? (
                    <FilePdf className="text-puntt-cool-gray-11" size={46} />
                  ) : (totalFiles === 0 && totalRevisions > 0) ||
                    (totalFiles <= 1 &&
                      (new Date(createdAt).getTime() <
                        Date.now() - 1000 * 60 * 10 ||
                        !SUPPORTED_EXTENSIONS.includes(
                          documentImportType ?? "",
                        ))) ? (
                    <>
                      <File className="text-puntt-cool-gray-11" size={46} />
                      <Text className="font-bold text-puntt-cool-gray-11">
                        {renderDocType()}
                      </Text>
                    </>
                  ) : (
                    <ThumbnailLoading ticketId={_id} isFolder={false} />
                  )}
                </Grid>
              ) : (
                <>
                  <img
                    alt={`Thumbnail for ${title}`}
                    src={thumbnails[0]}
                    className="pointer-events-none size-full select-none object-cover"
                  />
                  <div
                    className={cx(
                      "absolute left-1/2 top-1/2 size-fit -translate-x-1/2 -translate-y-1/2 rounded-full bg-puntt-neutral-gray-10/25 p-2",
                      {
                        hidden: !VIDEO_EXTENSIONS.includes(
                          documentImportType?.toLowerCase() ?? "",
                        ),
                      },
                    )}
                  >
                    <Play className="text-base-white opacity-75" size={32} />
                  </div>
                </>
              )}
            </AspectRatio>

            <Grid gap="4">
              <Flex gap="4" justify="between" align="center">
                <Heading as="h3" size="3" className="max-w-44 truncate">
                  <Link
                    to={ticketRoute.to}
                    params={{ ticketId: _id }}
                    search={{ tab: 0 }}
                  >
                    {title}
                  </Link>
                </Heading>

                <TicketActionsDropdown
                  className={cx({ hidden: isPunttGuest() })}
                  onDeleteTicket={() => onDeleteTicket(_id)}
                  onShare={() =>
                    isFolder ? onShareFolder(_id) : onShareTicket(_id)
                  }
                  onDownload={() => onDownloadTicket(_id)}
                  onManageVersions={() => onManageTicketVersions(_id)}
                  onRename={() => onRenameFolder(_id)}
                />
              </Flex>

              <Flex justify="between" align="center">
                <Flex gap="1">
                  {renderRevision()} {renderDocType()}
                </Flex>

                <Flex gap="4">
                  <Tooltip
                    content={`Last updated: ${formatDistanceToNow(updatedAt, { addSuffix: true })}`}
                  >
                    <IconButton variant="ghost" color="gray">
                      <Clock />
                    </IconButton>
                  </Tooltip>

                  <HoverCard.Root>
                    <HoverCard.Trigger>
                      <IconButton variant="ghost" color="gray">
                        <Users />
                      </IconButton>
                    </HoverCard.Trigger>

                    <HoverCard.Content className="dark" side="top">
                      <Grid gap="2">
                        {/* @ts-expect-error TS2339: schemas package types are wrong */}
                        {participants.map(({ _id, avatar, name }) => (
                          <Flex gap="3" align="center" key={_id}>
                            <Avatar
                              size="1"
                              variant={
                                name === "AI Reviewer" ? "solid" : "soft"
                              }
                              color={name === "AI Reviewer" ? "amber" : "red"}
                              src={assetForUser(avatar)}
                              fallback={
                                name === "AI Reviewer" ? (
                                  <Robot />
                                ) : (
                                  getNameInitials(name)
                                )
                              }
                            />

                            <Text size="1">{name}</Text>
                          </Flex>
                        ))}
                      </Grid>
                    </HoverCard.Content>
                  </HoverCard.Root>
                </Flex>
              </Flex>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
