import { z } from "zod";

import { assetSchema } from "./asset";
import { enterpriseSchema } from "./enterprise";
import { folderSchema } from "./folder";
import { teamSchema } from "./team";
import { userSchema } from "./user";
import { workflowSchema } from "./workflow";

export const fileCoordSchema = z.object({
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  width: z.coerce.number(),
  height: z.coerce.number(),
  filename: z.string().optional(),
  source: z.string(),
});

export type FileCoord = z.infer<typeof fileCoordSchema>;

export enum TicketWorkflow {
  EVENT_DESIGN = "event_design",
  SOCIAL_MEDIA_POST = "social_media_post",
  PRODUCTION_FLOW = "production_design_flow",
}

export enum TicketCommentBoard {
  BRIEF = "briefBoard",
  MOOD_BOARD = "moodBoard",
  REVISION = "revisionBoard",
}

export enum TicketStatus {
  WIP = "wip",
  NEEDS_REVIEW = "needs_review",
  APPROVED = "approved",
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
}

export enum TicketEmailTemplateAlias {
  TICKET_REVIEW = "ticket-generic",
  TICKET_APPROVED = "ticket-generic",
  TICKET_ADD_REVIEWER = "ticket-generic",
  TICKET_NEW_MESSAGE = "ticket-generic",
  TICKET_REPLY_MESSAGE = "ticket-generic",
  TICKET_ADD_REVISION_BOARD = "ticket-generic",
  TICKET_ADD_CREATIVES = "ticket-generic",
  TICKET_ASSIGN_CREATIVES = "ticket-generic",
  TICKET_MENTION = "ticket-mention",
}

export const briefHistorySchema = z.object({
  user: z.union([z.string(), userSchema.partial()]),
  message: z.string(),
  createdAt: z.coerce.date().optional(),
  description: z.string().optional(),
});

export type BriefHistory = z.infer<typeof briefHistorySchema>;

export enum TicketCommentDisposition {
  DEFAULT = "default",
  RESOLVED = "resolved",
  DISMISSED = "dismissed",
}

export enum TicketMessageRating {
  THUMBS_UP = "u",
  THUMBS_DOWN = "d",
}

export const ticketMessageVoteSchema = z.object({
  user: z.union([z.string(), userSchema.partial()]),
  vote: z.nativeEnum(TicketMessageRating),
  createdAt: z.coerce.date().optional(),
});

export const fileCitationSchema = z.object({
  url: z.string().url(), // URL of the file
  pageNumber: z.coerce.number().optional(), // Page number for document types
});

export type FileCitation = z.infer<typeof fileCitationSchema>;

export const webpageCitationSchema = z.object({
  url: z.string().url(), // Webpage URL
  text: z.string().optional(), // Text of the nearest header (only used to stick it in the URL, could be omitted if we do that in Rembrandt/Christo instead of Carrie)
});

export type WebpageCitation = z.infer<typeof webpageCitationSchema>;

export const textCitationSchema = z.object({
  text: z.string(), // Text of the paragraph
});

export type TextCitation = z.infer<typeof textCitationSchema>;

export const ticketMessageCitationSchema = z.union([
  fileCitationSchema,
  webpageCitationSchema,
  textCitationSchema,
]);

export const ticketMessageSchema = z.object({
  _id: z.string().optional(),
  createdBy: z.union([z.string(), userSchema.partial()]).optional(),
  aiPersona: z
    .object({
      name: z.string(),
      avatar: z.string(),
    })
    .optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  publishedAt: z.coerce.date().optional(),
  isDeleted: z.boolean().default(false).optional(),
  isPending: z.boolean().default(false).optional(),
  isReview: z.boolean().default(false).optional(),
  votes: z.array(ticketMessageVoteSchema).optional(),
  isAI: z.boolean().default(false),
  reviewId: z.string().optional(),
  description: z.string(),
  mentions: z
    .array(z.union([z.string(), userSchema.partial()]))
    .default([])
    .optional(),
  citations: z.array(ticketMessageCitationSchema).default([]).optional(),
});

export type TicketMessage = z.infer<typeof ticketMessageSchema>;

export const ticketCommentSchema = ticketMessageSchema.extend({
  messages: z.array(ticketMessageSchema),
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  videoStart: z.coerce.number().optional(),
  videoEnd: z.coerce.number().optional(),
  isRequired: z.boolean().default(false),
  rule: z.string().optional(),
  boardType: z.nativeEnum(TicketCommentBoard).optional(),
  boardId: z.string().optional(),
  shapeId: z.string().optional(),
  disposition: z
    .nativeEnum(TicketCommentDisposition)
    .default(TicketCommentDisposition.DEFAULT),
  liked: z.boolean().default(false).optional(),
});

export type TicketComment = z.infer<typeof ticketCommentSchema>;

export const ticketReviewSchema = z.object({
  reviewer: z.union([z.string(), userSchema.partial()]),
  description: z.string().optional(),
  approval: z.boolean().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
});

export type TicketReview = z.infer<typeof ticketReviewSchema>;

export const boardSchema = z.object({
  _id: z.string().optional(),
  shapes: z.any(), //z.custom<TLStoreSnapshot>(),
  version: z.number().optional(), // for conflict resolution
  screenshotUrl: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional(),
});

export type Board = z.infer<typeof boardSchema>;

const reviewFilesSchema = fileCoordSchema.extend({
  height: z.coerce.number().optional(),
  width: z.coerce.number().optional(),
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  missingFonts: z.array(z.string()).optional(),
});

export type ReviewFiles = z.infer<typeof reviewFilesSchema>;

export const revisionBoardSchema = boardSchema.extend({
  createdBy: z.union([z.string(), userSchema.partial()]),
  isAIReviewed: z.boolean().optional(),
  isAIReady: z.boolean().default(false).optional(),
  isPending: z.boolean().default(false).optional(),
  revision: z.coerce.number().default(0).optional(),
  reviews: z.array(ticketReviewSchema).optional(),
  viewedBy: z.array(z.union([z.string(), userSchema.partial()])).optional(),
  name: z.string().optional(),
  workingFiles: z.array(assetSchema).optional(), // @deprecated
  reviewFiles: z.array(reviewFilesSchema).optional(),
  downloadFile: assetSchema.optional(),
  approval: z.boolean().optional().nullable(),
  documentImportType: z.string().default(""),
  thumbnails: z.array(z.string()).default([]),
  allowList: z.array(z.union([z.string(), userSchema.partial()])).optional(),
  shadow: z
    .object({
      version: z.number().optional(),
      boardId: z.string().optional(),
    })
    .optional(),
  context: z
    .object({
      thread: z
        .object({
          openai: z
            .object({
              id: z.string().optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
  originalRevisionBoardId: z.string().optional(),
});

export type RevisionBoard = z.infer<typeof revisionBoardSchema>;

export const briefFileSchema = z.object({
  ticketName: z.string(),
  images: z.array(z.string()).default([]),
});

export type BriefFile = z.infer<typeof briefFileSchema>;

export const ticketSchema = z.object({
  _id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  updatedIndex: z.string().optional(),
  folder: z.union([z.string(), folderSchema]).optional(),
  status: z.nativeEnum(TicketStatus).default(TicketStatus.WIP).optional(),
  createdBy: z.union([z.string(), userSchema.partial()]),
  enterprise: z.union([z.string(), enterpriseSchema]),
  title: z.string(),
  description: z.string().optional(),
  files: z.array(assetSchema).optional(),
  workflow: workflowSchema,
  workflowStep: z.number().default(0),
  briefHistory: z.array(briefHistorySchema).optional(),
  briefBoard: boardSchema.optional(),
  briefFiles: z.array(briefFileSchema).optional(),
  moodBoard: boardSchema.optional(),
  revisionBoards: z.array(revisionBoardSchema).optional(),
  reviewers: z.array(z.union([z.string(), userSchema.partial()])).optional(),
  comments: z.array(ticketCommentSchema).optional(),
  participants: z
    .array(z.union([z.string(), userSchema.partial()]))
    .default([]),
  allowList: z.array(z.union([z.string(), userSchema.partial()])).optional(),
  creatives: z
    .array(z.union([z.string(), userSchema.partial()]))
    .default([])
    .optional(),
  archivedAt: z.coerce.date().optional(),
  team: z.union([z.string(), teamSchema]).optional(),
});

export type Ticket = z.infer<typeof ticketSchema>;

export const ticketTemplateSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  workflow: z.nativeEnum(TicketWorkflow).optional(),
});

export type TicketTemplate = z.infer<typeof ticketTemplateSchema>;

export const ticketParticipantsSchema = z
  .array(
    z.object({
      _id: z.string(),
      name: z.string(),
      avatar: z.string().optional(),
    }),
  )
  .default([]);

export type TicketParticipants = z.infer<typeof ticketParticipantsSchema>;
