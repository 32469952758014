import { DialogActions, DialogButton, Select } from "@mg/dali/src";
import { type Store } from "@reduxjs/toolkit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";

import { setLists } from "./slices/lists";
import { type RootState, type AppDispatch, store } from "./store";

import { useUI } from "../contexts/ui";
import { addCreativesToList, createList, getLists } from "../services/lists";

export type CreativeSelection = {
  _id: string;
  name?: string;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Allows routes to wait for state to become available before loading the render
// component
export function waitForState(
  store: Store,
  predicate: (state: RootState) => boolean,
  timeout = 500,
) {
  if (predicate(store.getState())) {
    return Promise.resolve(true);
  }

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      unsubscribe();
      reject(new Error("Timeout waiting for state update"));
    }, timeout);

    const unsubscribe = store.subscribe(() => {
      if (predicate(store.getState())) {
        clearTimeout(timeoutId);
        unsubscribe();
        resolve(true);
      }
    });
  });
}

export const useAddToListFlow = () => {
  const { notify, confirm, prompt } = useUI();
  const { lists } = useAppSelector((state) => state.lists);
  const queryClient = useQueryClient();

  const createListMutation = useMutation({
    mutationFn: createList,
  });

  const addCreativesToListMutation = useMutation({
    mutationFn: addCreativesToList,
  });

  const handleCreateList = async (
    title: string,
    creatives: CreativeSelection[],
  ) => {
    return createListMutation.mutate(
      { title, creatives },
      {
        async onSuccess() {
          notify({
            title: "Creative List Saved!",
            message: "",
          });
          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });
          store.dispatch(setLists(lists));
        },
      },
    );
  };

  const handleCreateListFlow = async (creatives: CreativeSelection[]) => {
    let title: string | null = "";
    while (title?.trim() === "") {
      title = await prompt({
        title: "Create New List",
        acceptLabel: "Create List & Save",
        inputProps: {
          label: "List Name",
        },
      });
    }

    if (title) {
      return handleCreateList(title, creatives);
    }
  };

  const handleAddCreativesToList = (
    creativeListId: string,
    creatives: CreativeSelection[],
  ) => {
    return addCreativesToListMutation.mutate(
      { listId: creativeListId, payload: creatives.map((c) => c._id) },
      {
        async onSuccess(data) {
          if (creatives.length > 1 || !creatives[0]?.name) {
            notify({
              title: `Your creatives have been added to your ${data.title} list!`,
              message: "",
            });
          } else {
            notify({
              title: `${creatives[0].name} has been added to your ${data.title} list!`,
              message: "",
            });
          }
          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });
          store.dispatch(setLists(lists));
        },
      },
    );
  };

  const handleAddCreativeToListFlow = async (
    creativesPayload?: CreativeSelection[] | string[],
  ) => {
    const creatives = creativesPayload?.map((c) => {
      if (typeof c === "string") {
        return { _id: c, name: "" };
      }
      return c;
    });

    if (!creatives) {
      return;
    }

    if (!lists) {
      return notify({
        message: "",
        title: "Still loading your creative lists. Please wait.",
      });
    }

    if (lists.length === 0) {
      return handleCreateListFlow(creatives);
    }

    let creativeListId: string | null = null;
    let shouldCreateList = false;

    const ok = await confirm({
      title: "Save to List",
      className: "overflow-visible",
      body: (
        <>
          <div className="mt-6">
            <div className="grid gap-6">
              <Select<false>
                label="Select List"
                options={(lists ?? []).map((l) => ({
                  value: l._id,
                  label: l.title,
                }))}
                onChange={(values) => {
                  creativeListId = values.value as string;
                }}
              />
            </div>
          </div>

          <DialogActions>
            <DialogButton theme="primary" action="accept">
              Add to List
            </DialogButton>
            <DialogButton
              theme="primary"
              variant="outlined"
              action="create-list"
              onClick={() => (shouldCreateList = true)}
            >
              Create New List
            </DialogButton>
            <DialogButton theme="primary" variant="outlined" action="cancel">
              Cancel
            </DialogButton>
          </DialogActions>
        </>
      ),
      acceptLabel: null,
      cancelLabel: null,
    });

    let list;

    if (ok && creativeListId != null) {
      const myLists = lists ?? [];
      list = myLists.find((l) => l._id === creativeListId);

      if (list != null) {
        return handleAddCreativesToList(list._id, creatives);
      }
    }

    // the user clicked the "Create New List"
    if (shouldCreateList) {
      handleCreateListFlow(creatives);
    }
  };
  return { handleAddCreativeToListFlow, handleCreateListFlow };
};
