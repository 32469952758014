import {
  GetTicketCommentsResponse,
  GetTicketResponse,
  type ShareTicketBody,
} from "@mg/schemas/src/christo/catalyst";
import { TicketCommentDisposition } from "@mg/schemas/src/commons/ticket";
import { ShowcaseToken } from "@mg/schemas/src/prince/auth";
import { XCircle } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  Flex,
  Grid,
  IconButton,
  Link,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import cx from "classnames";
import { useRef, useState } from "react";
import { isEmail } from "validator";

import { useUI } from "../../../../contexts/ui";
import { useAnalytics } from "../../../../utils/analytics";
import { isNil } from "../../../../utils/fp";
import { useAppSelector } from "../../../../utils/hooks";

/**
 * An action-less Dialog used to share tickets. Action-less means you must
 * provide the button that toggles an `open` state and passes it into this
 * component. This is to allow various styles of buttons to open the same
 * dialog. This is different from the ShareTicketDialog.tsx that exists on
 * the ticket/$ticketId/view page, but otherwise has identical functionality.
 *
 * The way you determine whether you're sharing a ticket or a folder is with
 * `item.type`. This is uninformative to the dialog itself, but it used in the
 * upper scope to determine how sharing is handled.
 */

export type ShareDialogProps = {
  item?: { type: "folder" | "ticket"; id: string };
  // A 1-indexed version. Unavailable when sharing a folder.
  latestVersionNumber?: number;
  shareLink?: string;
  shareLinkPending: boolean;
  isPending: boolean;
  onVersionChange(shareVersion: ShareTicketBody["version"]): void;
  onCancel(): void;
  onShare(emails: string[], shareVerion: ShareTicketBody["version"]): void;
};

export function ShareDialog(props: ShareDialogProps) {
  const {
    isPending,
    item,
    latestVersionNumber,
    shareLink,
    shareLinkPending,
    onCancel,
    onShare,
    onVersionChange,
  } = props;

  const { notify } = useUI();
  const posthog = useAnalytics("ShareTicketDialog");

  const { value: ticket, comments } = useAppSelector((state) => state.ticket);
  const user = useAppSelector((state) => state.auth.value);

  const renderTime = useRef(0);
  const numShares = useRef(0);
  const [shareVersion, setShareVersion] =
    useState<ShareTicketBody["version"]>("all");
  const [emails, setEmails] = useState("");
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  function handleCopyLink() {
    navigator.clipboard.writeText(shareLink as string).then(() => {
      notify({
        title: "Share link copied to clipboard",
      });

      if (isNil(ticket)) return;

      numShares.current += 1;
      posthog.capture("copied_share_link", {
        ...analyticsPayload(ticket, comments, renderTime.current, user),
        num_shares: numShares.current,
      });
    });
  }

  return (
    <Dialog.Root open={item != null}>
      <Dialog.Content maxWidth={showMoreDetails ? "800px" : "450px"}>
        <Dialog.Title>Share {item?.type}</Dialog.Title>
        <Dialog.Description>
          Copy or email a link to this {item?.type}
        </Dialog.Description>
        <Flex className="gap-4">
          <Grid className="w-full">
            <Grid gap="3" mt="4">
              <Grid
                gap="2"
                className={cx({ hidden: isNil(latestVersionNumber) })}
              >
                <Text size="2" weight="medium">
                  Versions to Share
                </Text>

                <Select.Root
                  value={String(shareVersion)}
                  onValueChange={(value) => {
                    setShareVersion(
                      value === "all" || value === "new"
                        ? value
                        : Number(value),
                    );
                    onVersionChange(value as unknown as typeof shareVersion);
                  }}
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Item value="all">All versions</Select.Item>
                    <Select.Item value={String(latestVersionNumber)}>
                      Latest version only
                    </Select.Item>
                    <Select.Item value="new">Share as new version</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Grid>
              <Flex gap="4" align="center">
                <Text size="1" className="text-puntt-neutral-gray-11">
                  {item?.type === "ticket"
                    ? "Privileged logged-in users can still see all versions."
                    : "This will share all versions of any contained assets."}
                </Text>
                <Link
                  size="1"
                  className={cx("cursor-pointer text-puntt-accent-11", {
                    hidden: item?.type == "folder",
                  })}
                  onClick={() => setShowMoreDetails((det) => !det)}
                >
                  {showMoreDetails ? "Less Details" : "More Details"}
                </Link>
              </Flex>

              <Grid gap="2">
                <Text size="2" weight="medium">
                  Link
                </Text>
                <TextField.Root value={shareLink || ""} readOnly>
                  <TextField.Slot side="right">
                    <Button
                      variant="soft"
                      size="1"
                      disabled={shareLink == null || shareLinkPending}
                      loading={shareLinkPending}
                      onClick={handleCopyLink}
                    >
                      Copy
                    </Button>
                  </TextField.Slot>
                </TextField.Root>
              </Grid>

              <Grid gap="2">
                <Text size="2" weight="medium">
                  Email(s)
                </Text>
                <TextField.Root
                  value={emails}
                  onChange={({ target }) => setEmails(target.value)}
                />
                <Text size="1">Separate multiple emails with a comma</Text>
              </Grid>
            </Grid>

            <Flex gap="3" mt="4" justify="end">
              <Button size="1" onClick={onCancel} variant="soft" color="gray">
                Cancel
              </Button>
              <Button
                size="1"
                variant="soft"
                disabled={
                  emails.split(",").some((email) => !isEmail(email.trim())) ||
                  isPending
                }
                loading={isPending}
                onClick={() => {
                  onShare(
                    emails.split(",").map((email) => email.trim()),
                    shareVersion,
                  );
                  setEmails("");
                  setShareVersion("all");

                  if (isNil(ticket)) return;

                  numShares.current += 1;
                  posthog.capture("emailed_share_link", {
                    ...analyticsPayload(
                      ticket,
                      comments,
                      renderTime.current,
                      user,
                    ),
                    emails,
                    shareVersion,
                    num_shares: numShares.current,
                  });
                }}
              >
                Share
              </Button>
            </Flex>
          </Grid>
          <Grid
            className={cx("w-full gap-2 rounded-md border-2 p-4", {
              hidden: !showMoreDetails,
            })}
          >
            <Flex justify="between" align="center">
              <Text size="3" weight="medium">
                Access to Shared Files and Folders
              </Text>
              <IconButton
                onClick={() => setShowMoreDetails(false)}
                size="1"
                variant="ghost"
              >
                <XCircle className="text-puntt-neutral-gray-11" />
              </IconButton>
            </Flex>
            <Grid className="gap-1">
              <Text size="2" weight="medium">
                All Versions
              </Text>
              <Text size="2">
                Provides full access to all versions and comments, including any
                versions added in the future.
              </Text>
            </Grid>
            <Grid className="gap-1">
              <Text size="2" weight="medium">
                Latest Version{" "}
              </Text>
              <Text size="2">
                Provides access only to the most recent version at the time of
                sharing (including its comments) as well as all future versions
                added.
              </Text>
            </Grid>
            <Grid className="gap-1">
              <Text size="2" weight="medium">
                New Version
              </Text>
              <Text size="2">
                Creates a new, “clean” version that is the same as the latest
                version but without any comments. Provides access only to that
                new version and any versions and comments that are added later.
              </Text>
            </Grid>
          </Grid>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

/////
// Analytics
/////

function analyticsPayload(
  ticket: GetTicketResponse,
  comments: GetTicketCommentsResponse | undefined,
  renderTime: number,
  user: ShowcaseToken | null,
) {
  const latestRevision = ticket.revisionBoards?.at(-1);
  const latestRevisionComments = comments?.filter(
    (c) => c.boardId === latestRevision?._id,
  );
  return {
    created_hours_ago:
      (Date.now() - new Date(ticket.createdAt).getTime()) / (1000 * 60 * 60),
    num_revisions: ticket.revisionBoards?.length,
    num_comments: comments?.length,
    num_new_comments:
      comments?.filter((c) => new Date(c.createdAt).getTime() > renderTime)
        .length || 0,
    num_latest_revision_comments: latestRevisionComments?.length || 0,
    num_latest_revision_ai_comments:
      latestRevisionComments?.filter(
        (c) => c.isAI && c.disposition === TicketCommentDisposition.DEFAULT,
      ).length || 0,
    num_latest_revision_resolved_comments:
      latestRevisionComments?.filter(
        (c) => c.disposition === TicketCommentDisposition.RESOLVED,
      ).length || 0,
    num_latest_revision_dismissed_comments:
      latestRevisionComments?.filter(
        (c) => c.disposition === TicketCommentDisposition.DISMISSED,
      ).length || 0,
    num_new_revisions:
      ticket.revisionBoards?.filter(
        (r) => new Date(r.createdAt).getTime() > renderTime,
      ).length || 0,
    latest_revision_name: latestRevision?.name,
    latest_revision_id: latestRevision?._id,
    latest_revision_number: latestRevision?.revision,
    latest_revision_file_types: latestRevision?.reviewFiles?.map((f) =>
      f.source.slice(-3),
    ),
    latest_minutes_since_revision_created:
      (Date.now() -
        new Date(latestRevision?.createdAt ?? Date.now()).getTime()) /
      (1000 * 60),
    latest_revision_created_by_current_user:
      (typeof latestRevision?.createdBy === "string"
        ? latestRevision?.createdBy
        : latestRevision?.createdBy._id) === user?.userID &&
      Boolean(user?.userID),
    isRevisionAIReviewed: latestRevisionComments?.some((c) => c.isAI),
    minutes_since_render: (Date.now() - renderTime) / (1000 * 60),
  };
}
