import { Popover } from "@headlessui/react";
import { Icon, Logo, Typography } from "@mg/dali/src";
import * as Avatar from "@radix-ui/react-avatar";
import { Button } from "@radix-ui/themes";
import {
  // eslint-disable-next-line import/named
  Link,
  matchPathname,
  Outlet,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import cx from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useFeatureFlagVariantKey, usePostHog } from "posthog-js/react";
import { useEffect, useRef } from "react";
import ConfettiExplosion from "react-confetti-explosion";

import { BASEPATH } from "../../config/env";
import { discoverRoute } from "../../routes/discover/route";
import { listsRoute } from "../../routes/lists/route";
import { myNetworkRoute } from "../../routes/network/route";
import { CompleteOnboardingDialog } from "../../routes/onboarding/components/CompleteOnboardingDialog";
import { knowledgeSettingRoute } from "../../routes/settings/routes/knowledgeSettingRoute";
import { usersRoute } from "../../routes/settings/routes/usersRoute";
import { ticketsRoute } from "../../routes/tickets/route";
import { userProfileRoute } from "../../routes/userProfile/route";
import { workedWithRoute } from "../../routes/worked-with/route";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canManageUsers,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { getEnterpriseLogo } from "../../utils/imageHandler";
import { useCurrentUserProfile } from "../../utils/queries/userProfile";
import { logout } from "../../utils/slices/auth";
import { setShowConfetti } from "../../utils/slices/onboarding";
import { setDrawerOpen, setSearchHeight } from "../../utils/slices/ui";
import { AppDrawer } from "../AppDrawer";
import { AuthenticationDialog } from "../AuthenticationDialog";
import { AuthTooltip } from "../AuthTooltip";
import { AvatarWithInitials } from "../AvatarWithInitials";
import { CreateEnterpriseDialog } from "../CreateEnterpriseDialog";
import { HighlightModal } from "../HighlightModal";
import { PortfolioDrawer } from "../PortfolioDrawer";
import { SearchAndFilterBar } from "../SearchAndFilterBar";

const NotificationTypeMap = {
  "new-creative-list": "New Creative List",
  "new-gig-interview": "New Gig Interview",
  "new-gig-active": "New Gig Active",
  "new-gig-pending": "New Gig Pending",

  // Catalyst
  "ticket-add-reviewer": "Ticket Added Reviewer",
  "ticket-review": "Ticket In Review",
  "ticket-approved": "Ticket Approved",
  "ticket-new-message": "New Comment",
  "ticket-new-reply-message": "New Reply",
  "ticket-add-revision-board": "New Revision Board Added",
  "ticket-add-creatives": "New Creatives Added",
  "ticket-assign-creatives": "Assign Creatives",
  "ticket-brief-invalid": "Brief Invalid",
  "ticket-mention": "Mention",
};

export function CreativeCatalystLayout() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.value);
  const { showConfetti } = useAppSelector((state) => state.onboarding);
  const { drawerOpen } = useAppSelector((state) => state.ui);
  const router = useRouter();
  const search = useSearch({
    strict: false,
  });

  const posthog = usePostHog();
  const profile = useCurrentUserProfile();
  const manageBrandGuidelines = useFeatureFlagVariantKey(
    "manage-brand-guidelines",
  );

  const completeOnboardingDialogRef = useRef<HTMLDialogElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const enterpriseLogo = getEnterpriseLogo(
    `enterprises/${user?.enterpriseId}/logo_full.png`,
  );
  const enterpriseLogoAlt = user?.enterpriseName;
  const linkClasses =
    "px-3 py-1.5 hover:bg-carbon-50 transition-colors rounded text-left";

  useEffect(() => {
    if (showConfetti) {
      completeOnboardingDialogRef.current?.showModal();
    }
  }, [showConfetti]);
  useEffect(() => {
    if (headerRef.current) {
      dispatch(setSearchHeight(headerRef.current.offsetHeight));
    }
  }, [dispatch, search]);

  const networkMatch = matchPathname(
    BASEPATH,
    location.pathname,
    myNetworkRoute,
  );

  const workedWithMatch = matchPathname(
    BASEPATH,
    location.pathname,
    workedWithRoute,
  );
  const discoverMatch = matchPathname(
    BASEPATH,
    location.pathname,
    discoverRoute,
  );

  const showSearchBar = networkMatch || workedWithMatch || discoverMatch;

  return (
    <main className="relative min-h-screen">
      <AuthenticationDialog />
      <AppDrawer
        open={drawerOpen}
        onClose={() => dispatch(setDrawerOpen(false))}
      />

      <div className="sticky top-0 z-[3]" ref={headerRef}>
        <HighlightModal tooltipPosition={{ top: 100, left: 100 }} order={0}>
          <header className="sticky top-0 z-[1] flex items-center justify-between border-b border-b-carbon-300 bg-base-white shadow-3">
            <AuthTooltip classNames="cursor-pointer">
              <button
                data-auth-trigger="nav-menu"
                onClick={() => dispatch(setDrawerOpen(true))}
                className="border-r border-r-carbon-300 bg-base-white p-4"
              >
                <Icon.List size={24} className="text-carbon-900" />
              </button>
            </AuthTooltip>
            <Link
              to={ticketsRoute.to}
              disabled={user?.referredBy != null}
              data-auth-trigger="logo"
            >
              <Avatar.Root className="">
                <Avatar.Image
                  className="h-10"
                  src={enterpriseLogo}
                  alt={enterpriseLogoAlt}
                />

                <Avatar.Fallback delayMs={600}>
                  <Logo variant="puntt" className="h-8" data-testid="logo" />
                </Avatar.Fallback>
              </Avatar.Root>
            </Link>

            <div
              className="mr-4 flex items-center gap-4"
              data-auth-trigger="settings"
            >
              {user?.referredBy != null ? (
                <Button variant="outline">Log In or Sign Up</Button>
              ) : (
                <>
                  <Popover
                    className={cx("relative", {
                      // Since Brand Guidelines is the only option in the settings menu for now,
                      // hide the settings menu if it's not available. If we add back other links,
                      // this will need to be updated.
                      hidden:
                        manageBrandGuidelines !== "enabled" ||
                        !knowledgeSettingRoute,
                    })}
                  >
                    <Popover.Button
                      className="rounded border border-carbon-200 p-2"
                      disabled={
                        manageBrandGuidelines !== "enabled" ||
                        !knowledgeSettingRoute
                      }
                    >
                      <Icon.Gear className="text-base-black" />
                    </Popover.Button>

                    <Popover.Panel className="absolute right-0 top-full z-[1] mt-1 grid w-max rounded-md border border-carbon-50 bg-base-white p-2 shadow-lg">
                      <Link
                        to={knowledgeSettingRoute.to}
                        className={linkClasses}
                      >
                        <Typography size="sm">Knowledge Settings</Typography>
                      </Link>
                    </Popover.Panel>
                  </Popover>

                  <Popover className="relative">
                    <Popover.Button className="rounded border border-carbon-200 p-2">
                      <Icon.Bell className="text-base-black" />
                      {profile.data?.notifications?.some(
                        (notification) => !notification.isRead,
                      ) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          className="-translate-y-1/5 absolute -right-1/2 -top-1 -translate-x-3.5"
                        >
                          <circle cx="4" cy="4" r="4" fill="#FF3C00" />
                        </svg>
                      )}
                    </Popover.Button>

                    <Popover.Panel className="absolute right-0 top-full z-[1] mt-1 grid max-h-80 w-max overflow-auto rounded-md border border-carbon-50 bg-base-white p-2 shadow-lg">
                      {profile.data?.notifications?.length ? (
                        profile.data?.notifications.map(
                          (notification, index) => (
                            <button
                              onClick={() => {
                                if (!notification.ctaLink) return;
                                // remove link domain, use relative path
                                const url = notification.ctaLink.replace(
                                  /^https?:\/\/[^/]+/,
                                  "",
                                );
                                router.navigate({
                                  to: url,
                                });
                              }}
                              key={index}
                              className="w-72 rounded px-5 py-4 text-left transition-colors hover:bg-carbon-50"
                            >
                              <div className="relative flex items-center gap-x-2">
                                {!notification.isRead && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    className="absolute -left-3 top-1/2 -translate-y-1/2"
                                  >
                                    <circle
                                      cx="4"
                                      cy="4"
                                      r="4"
                                      fill="#FF3C00"
                                    />
                                  </svg>
                                )}
                                <Typography weight="medium">
                                  {NotificationTypeMap[notification.type]}
                                </Typography>
                                <Typography
                                  size="sm"
                                  className="text-carbon-400"
                                >
                                  {formatDistanceToNow(notification.createdAt, {
                                    addSuffix: true,
                                  }).replace(/about\s/, "")}
                                </Typography>
                              </div>
                              <Typography size="sm" className="text-base-black">
                                {notification.messageArray
                                  ? notification.messageArray.map(
                                      (message, index) => {
                                        switch (message.key) {
                                          case "user":
                                            return (
                                              <span
                                                key={index}
                                                className="font-bold"
                                              >
                                                @{message.value} {}
                                              </span>
                                            );
                                          case "ticket":
                                            return (
                                              <span
                                                key={index}
                                                className="italic"
                                              >
                                                {message.value} {}
                                              </span>
                                            );
                                          default:
                                            return message.value + " ";
                                        }
                                      },
                                    )
                                  : notification.message}
                              </Typography>
                            </button>
                          ),
                        )
                      ) : (
                        <Typography className="text-carbon-400">
                          No notifications
                        </Typography>
                      )}
                    </Popover.Panel>
                  </Popover>

                  <Popover className="relative h-8">
                    <Popover.Button>
                      <AvatarWithInitials
                        avatar={user?.avatar}
                        name={user?.name}
                        size={8}
                        square
                      />
                    </Popover.Button>

                    <Popover.Panel className="absolute right-0 top-full z-50 mt-1 grid w-max rounded-md border border-carbon-50 bg-base-white p-2 shadow-lg">
                      <Link
                        to={myNetworkRoute.to}
                        search={createDefaultNetworkRouteSearch()}
                        className={cx(linkClasses, {
                          hidden: !canAccessCreativeConnect(),
                        })}
                      >
                        <Typography size="sm">Network</Typography>
                      </Link>
                      <Link
                        to={listsRoute.to}
                        className={cx(linkClasses, {
                          hidden: !canAccessLists(),
                        })}
                      >
                        <Typography size="sm">Lists</Typography>
                      </Link>

                      <hr
                        className={cx("my-2", {
                          hidden:
                            !canAccessLists() && !canAccessCreativeConnect(),
                        })}
                      />

                      <Link
                        to={userProfileRoute.to}
                        className={cx(linkClasses, {
                          hidden: !canAccessEditOwnProfile(),
                        })}
                      >
                        <Typography size="sm">Edit Profile</Typography>
                      </Link>
                      <Link
                        to={usersRoute.to}
                        className={cx(linkClasses, {
                          hidden: !canManageUsers(),
                        })}
                      >
                        <Typography size="sm">Manage Users</Typography>
                      </Link>
                      <CreateEnterpriseDialog />

                      <button
                        onClick={() => {
                          dispatch(logout());
                          posthog.reset();
                        }}
                        className={cx(linkClasses, "bg-transparent")}
                      >
                        <Typography size="sm">Logout</Typography>
                      </button>
                    </Popover.Panel>
                  </Popover>
                </>
              )}
            </div>
          </header>
        </HighlightModal>
        <HighlightModal order={1} tooltipPosition={{ top: 100, left: 100 }}>
          {!!showSearchBar && <SearchAndFilterBar />}
        </HighlightModal>
      </div>

      {showConfetti && (
        <div className="fixed left-1/2 top-0">
          <ConfettiExplosion
            force={0.8}
            duration={3000}
            particleCount={250}
            width={window.innerWidth}
            onComplete={() => dispatch(setShowConfetti(false))}
          />
        </div>
      )}
      <CompleteOnboardingDialog
        ref={completeOnboardingDialogRef}
        onClose={() => {
          if (completeOnboardingDialogRef.current != null) {
            completeOnboardingDialogRef.current.close();
          }
        }}
      />
      <PortfolioDrawer />

      <Outlet />
    </main>
  );
}
