import { EyeClosed } from "@phosphor-icons/react";
import { Button, Grid, Heading, Text } from "@radix-ui/themes";

type EmptyTicketsViewProps = {
  onFilesUpload(): void;
};

export function EmptyTicketsView(props: EmptyTicketsViewProps) {
  const { onFilesUpload } = props;

  return (
    <Grid
      gap="4"
      className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center"
    >
      <EyeClosed
        size={48}
        color="rgb(var(--puntt-blue-9))"
        className="mx-auto"
      />

      <Heading size="6" color="gray">
        Nothing to see here. Yet.
      </Heading>

      <Text size="3" color="gray">
        Drop files and folders here to start
      </Text>

      <Button
        size="2"
        variant="outline"
        mx="auto"
        color="gray"
        className="max-w-max"
        onClick={onFilesUpload}
        data-auth-trigger="upload-files"
      >
        Upload File(s)
      </Button>
    </Grid>
  );
}
