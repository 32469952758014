import { EnterpriseProfileType } from "@mg/schemas/src/commons";
import {
  computed,
  createPresenceStateDerivation,
  createTLStore,
  defaultShapeUtils,
  defaultUserPreferences,
  getUserPreferences,
  InstancePresenceRecordType,
  react,
  type SerializedSchema,
  setUserPreferences,
  type TLInstancePresence,
  type TLRecord,
  type TLStoreWithStatus,
  loadSnapshot,
  type TLShape,
} from "@tldraw/tldraw";
import { useEffect, useMemo, useState } from "react";
import { YKeyValue } from "y-utility/y-keyvalue";
import { WebsocketProvider } from "y-websocket";
import * as Y from "yjs";

import { YJS_HOST } from "../../../config/env";
import { IGNORED_CANVAS_UPDATE_KEYS } from "../../../utils/constants";
import { isNil } from "../../../utils/fp";
import { useAppSelector } from "../../../utils/hooks";
import { stripCommentsFromUpdate } from "../../../utils/tldraw/comments";
import { customShapeUtils } from "../../../utils/tldraw/shapeUtils";

type Changes = Map<
  string,
  | { action: "delete"; oldValue: TLRecord }
  | { action: "update"; oldValue: TLRecord; newValue: TLRecord }
  | { action: "add"; newValue: TLRecord }
>;

type ConnectionStatus = "connected" | "disconnected" | "connecting";

type YjsStoreProps = {
  roomId?: string;
};

export function useYjsStore(props: YjsStoreProps) {
  const { roomId } = props;
  const authUser = useAppSelector((state) => state.auth.value);

  const store = useMemo(() => {
    const newStore = createTLStore({
      shapeUtils: [...defaultShapeUtils, ...customShapeUtils],
      id: roomId,
    });

    return newStore;

    // Force the store to be recreated whenever the roomId changes. This
    // circumvents a number of issues related to store synchronization,
    // especially when creating new versions and switching between versions.
  }, [roomId]);

  const [storeWithStatus, setStoreWithStatus] = useState<TLStoreWithStatus>({
    status: "loading",
  });
  const { yDoc, yStore, meta, room } = useMemo(() => {
    const yDoc = new Y.Doc({ gc: true });
    const yArr = yDoc.getArray<{ key: string; val: TLRecord }>(roomId);
    const yStore = new YKeyValue(yArr);
    const meta = yDoc.getMap<SerializedSchema>("meta");

    return {
      yDoc,
      yStore,
      meta,
      room: isNil(roomId)
        ? null
        : new WebsocketProvider(YJS_HOST, roomId, yDoc, {
            connect: true,
          }),
    };
  }, [roomId]);

  useEffect(() => {
    if (isNil(room) || isNil(store)) {
      return;
    }

    setStoreWithStatus({ status: "loading" });
    const unsubs: (() => void)[] = [];

    function handleSync() {
      // 1.
      // Connect store to yjs store and vis versa, for both the document and awareness
      /* -------------------- Document -------------------- */
      // Sync store changes to the yjs doc
      unsubs.push(
        store.listen(
          function syncStoreChangesToYjsDoc({ changes }) {
            const filteredChanges = stripCommentsFromUpdate(changes);

            yDoc.transact(() => {
              Object.values(filteredChanges.added).forEach((record) => {
                yStore.set(record.id, record);
              });
              Object.values(filteredChanges.updated).forEach(([, record]) => {
                yStore.set(record.id, record);
              });
              Object.values(changes.removed).forEach((record) => {
                yStore.delete(record.id);
              });
            });
          },
          { source: "user", scope: "document" },
        ),
      );

      // Sync the yjs doc changes to the store
      function handleChange(changes: Changes, transaction: Y.Transaction) {
        if (transaction.local) {
          return;
        }

        const toRemove: TLRecord["id"][] = [];
        const toPut: TLRecord[] = [];
        const ignoredUpdateIds = IGNORED_CANVAS_UPDATE_KEYS;

        changes.forEach((change, id) => {
          switch (change.action) {
            case "add": {
              const record = yStore.get(id);

              if (!isNil(record) && !ignoredUpdateIds.includes(record.id)) {
                toPut.push(record);
              }

              break;
            }
            case "update": {
              const record = yStore.get(id);
              // prevent presence updates from hanging the camera

              if (
                change.oldValue.typeName === "shape" &&
                (change.oldValue.type === "comment" ||
                  change.oldValue.type === "comment-avatar")
              ) {
                break;
              }

              if (record != null && !ignoredUpdateIds.includes(record.id)) {
                toPut.push(record);
              }
              break;
            }
            case "delete": {
              if (
                change.oldValue.typeName === "shape" &&
                (change.oldValue.type === "comment" ||
                  change.oldValue.type === "comment-avatar")
              ) {
                break;
              }
              toRemove.push(id as TLRecord["id"]);
              break;
            }
          }
        });

        // put / remove the records in the store
        store.mergeRemoteChanges(() => {
          if (toRemove.length) {
            store.remove(toRemove);
          }
          if (toPut.length) {
            store.put(
              toPut.filter(
                (record) =>
                  (record as TLShape).type != "comment-avatar" &&
                  (record as TLShape).type != "comment",
              ),
            );
          }
        });
      }

      yStore.on("change", handleChange);
      unsubs.push(() => yStore.off("change", handleChange));

      /* -------------------- Awareness ------------------- */
      const yClientId = room!.awareness.clientID.toString();

      setUserPreferences({
        id: authUser?.userID ?? yClientId,
        name: authUser?.name,
      });

      const userPreferences = computed("userPreferences", () => {
        const user = getUserPreferences();

        return {
          id: user.id,
          color: user.color ?? defaultUserPreferences.color,
          name: user.name ?? defaultUserPreferences.name,
        };
      });

      // Create the instance presence derivation
      const presenceId = InstancePresenceRecordType.createId(yClientId);
      const presenceDerivation = createPresenceStateDerivation(
        userPreferences,
        presenceId,
      )(store);

      if (authUser?.role !== EnterpriseProfileType.CATALYST_AI) {
        // Set our initial presence from the derivation's current value
        room!.awareness.setLocalStateField(
          "presence",
          presenceDerivation.get(),
        );

        // When the derivation change, sync presence to to yjs awareness
        unsubs.push(
          react("when presence changes", () => {
            const presence = presenceDerivation.get();

            requestAnimationFrame(() => {
              room!.awareness.setLocalStateField("presence", presence);
            });
          }),
        );
      }

      // Sync yjs awareness changes to the store
      function handleUpdate(update: {
        added: number[];
        updated: number[];
        removed: number[];
      }) {
        const states = room!.awareness.getStates() as Map<
          number,
          { presence: TLInstancePresence }
        >;
        const toRemove: TLInstancePresence["id"][] = [];
        const toPut: TLInstancePresence[] = [];

        // Connect records to put / remove
        for (const clientId of update.added) {
          const state = states.get(clientId);

          if (authUser?.role !== EnterpriseProfileType.CATALYST_AI) {
            if (state?.presence && state.presence.id !== presenceId) {
              toPut.push(state.presence);
            }
          }
        }

        for (const clientId of update.updated) {
          const state = states.get(clientId);

          if (authUser?.role !== EnterpriseProfileType.CATALYST_AI) {
            if (state?.presence && state.presence.id !== presenceId) {
              toPut.push(state.presence);
            }
          }
        }

        for (const clientId of update.removed) {
          toRemove.push(
            InstancePresenceRecordType.createId(clientId.toString()),
          );
        }

        // put / remove the records in the store
        store.mergeRemoteChanges(() => {
          if (toRemove.length) {
            store.remove(toRemove);
          }
          if (toPut.length) {
            store.put(toPut);
          }
        });
      }

      function handleMetaUpdate() {
        const theirSchema = meta.get("schema");

        if (!theirSchema) {
          throw new Error("No schema found in the yjs doc");
        }
        // If the shared schema is newer than our schema, the user must refresh
        const schemaMigrations = store.schema.getMigrationsSince(theirSchema);

        if (schemaMigrations.ok && schemaMigrations.value.length > 0) {
          window.alert("The schema has been updated. Please refresh the page.");
          yDoc.destroy();
        }
      }

      meta.observe(handleMetaUpdate);
      unsubs.push(() => meta.unobserve(handleMetaUpdate));
      room!.awareness.on("update", handleUpdate);
      unsubs.push(() => room!.awareness.off("update", handleUpdate));

      // 2.
      // Initialize the store with the yjs doc records—or, if the yjs doc
      // is empty, initialize the yjs doc with the default store records.
      if (yStore.yarray.length) {
        // Replace the store records with the yjs doc records
        const ourSchema = store.schema.serialize();
        const theirSchema = meta.get("schema");

        if (!theirSchema) {
          throw new Error("No schema found in the yjs doc");
        }

        const records = yStore.yarray.toJSON().map(({ val }) => val);
        const migrationResult = store.schema.migrateStoreSnapshot({
          schema: theirSchema,
          store: Object.fromEntries(
            records.map((record) => [record.id, record]),
          ),
        });

        if (migrationResult.type === "error") {
          // if the schema is newer than ours, the user must refresh
          window.alert("The schema has been updated. Please refresh the page.");

          return;
        }

        yDoc.transact(() => {
          // delete any deleted records from the yjs doc
          for (const r of records) {
            if (!migrationResult.value[r.id]) {
              yStore.delete(r.id);
            }
          }
          for (const r of Object.values(migrationResult.value) as TLRecord[]) {
            yStore.set(r.id, r);
          }

          meta.set("schema", ourSchema);
        });
        loadSnapshot(store, {
          store: migrationResult.value,
          schema: ourSchema,
        });
      } else {
        // Create the initial store records
        // Sync the store records to the yjs doc
        yDoc.transact(() => {
          for (const record of store.allRecords()) {
            yStore.set(record.id, record);
          }

          meta.set("schema", store.schema.serialize());
        });
      }

      setStoreWithStatus({
        store,
        status: "synced-remote",
        connectionStatus: "online",
      });
    }

    let hasConnectedBefore = false;
    let connectingCount = 0;

    function handleStatusChange({ status }: { status: ConnectionStatus }) {
      if (status === "connecting") {
        connectingCount += 1;

        if (connectingCount >= 3) {
          return setStoreWithStatus({
            store,
            status: "synced-local",
          });
        }
      }

      // If we're disconnected, set the store status to 'synced-remote' and the connection status to 'offline'
      if (status === "disconnected") {
        return setStoreWithStatus({
          store,
          status: "synced-remote",
          connectionStatus: "offline",
        });
      }

      room!.off("synced", handleSync);

      if (status === "connected") {
        if (hasConnectedBefore) {
          return;
        }

        hasConnectedBefore = true;
        room!.on("synced", handleSync);
        unsubs.push(() => room!.off("synced", handleSync));
      }
    }

    room.on("status", handleStatusChange);
    unsubs.push(() => room.off("status", handleStatusChange));

    return () => {
      unsubs.forEach((fn) => fn());
      unsubs.length = 0;
    };
  }, [
    roomId,
    room,
    yDoc,
    store,
    yStore,
    meta,
    authUser?.userID,
    authUser?.name,
  ]);

  return storeWithStatus;
}
